import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
// import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class StoryPersonaAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/storypersona"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getLastUsedPersonaOrDefault() {
        return this.axios.get(`/get_last_used_persona_or_default/`, {
            headers: {Authorization: `Bearer ${readCookie("token")}`},
        });
    }

    createNewUserPersona(personaName, description, file) {
        const data = new FormData();
        data.append('avatar_url', file);
        data.append('personaName', personaName);
        data.append('description', description);
    
        return this.axios.post(`/create_new_persona`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    updateUserPersona(personaId, personaName, description, file) {
        const data = new FormData();
        data.append('avatar_url', file);
        data.append('personaName', personaName);
        data.append('description', description);
    
        return this.axios.put(`/update_persona/${personaId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${readCookie("token")}`
            }
        });
    }

    getUserPersonas() {
        return this.axios.get(`/get_all_my_personas`, {
            headers: {Authorization: `Bearer ${readCookie("token")}`},
        });
    }
}

export const StoryPersonaApiInstance = new StoryPersonaAPI();

export default StoryPersonaAPI;