import React, { useEffect, useState, useCallback } from "react";
import ReactStars from "react-stars";
import { AIApiInstance } from "../../../apis/AIAPI";
import { useSelector } from "react-redux";
import ReviewCard from "./ReviewCard";
import ReviewModal from "./ReviewModal";
import { selectCurrentUser } from "../../../Redux/selectors/auth";

const Reviews = ({ characterId }) => {
    const [reviews, setReviews] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newReview, setNewReview] = useState({ rating: 0, description: "" });
    const [editingReview, setEditingReview] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const user = useSelector(selectCurrentUser);

    // Fetch reviews on mount or characterId change
    useEffect(() => {
        fetchReviews();
    }, [characterId]);

    // Fetch reviews from API
    const fetchReviews = useCallback(async () => {
        try {
            const { data } = await AIApiInstance.getCharacterReviews(characterId);
            const fetchedReviews = data?.data?.ratings || [];
            const latestReviews = {};
            fetchedReviews.forEach((review) => {
                const userId = review.userId;
                if (
                    !latestReviews[userId] ||
                    review.createdAt > latestReviews[userId].createdAt
                ) {
                    latestReviews[userId] = review;
                }
            });
            console.log("Reviews Array:", reviews);
            setReviews(Object.values(latestReviews));
        } catch (err) {
            console.error("Error fetching reviews:", err);
        }
    }, [characterId]);

    // Save a new review
    const handleSaveReview = async () => {
        try {
            await AIApiInstance.saveReviewCharacter(
                newReview.rating,
                newReview.description,
                characterId
            );
            fetchReviews();
            resetReviewState();
        } catch (err) {
            console.error("Error saving review:", err);
        }
    };

    // Update an existing review
    const handleEditReview = async () => {
        if (!editingReview) return;
        try {
            await AIApiInstance.updateReviewCharacter(
                editingReview.rating,
                editingReview.description,
                editingReview._id
            );
            fetchReviews();
            resetReviewState();
        } catch (err) {
            console.error("Error updating review:", err);
        }
    };

    // Open modal for editing
    const handleEditReviewClick = useCallback((review) => {
        setEditingReview(review);
        setShowModal(true);
    }, []);

    // Open modal for writing a new review
    const handleWriteReviewClick = useCallback(() => {
        setEditingReview(null);
        setShowModal(true);
    }, []);

    // Reset review state and close modal
    const resetReviewState = () => {
        setEditingReview(null);
        setNewReview({ rating: 0, description: "" });
        setShowModal(false);
    };

    // Toggle expanded state
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const currentUserReview = reviews.find((review) => {
        console.log("Checking Review:", review);
        console.log("Review UserId:", review.userId, "Current UserId:", user?.userId);
        return review.userId === user?.userId;
    });
    console.log("Current User Review:", currentUserReview);

    return (
        <div className="reviews-section mt-2">
            <Header
                isExpanded={isExpanded}
                onToggleExpand={handleToggleExpand}
            />
            <div className="d-flex gap-2 flex-wrap">
                {reviews.slice(0, isExpanded ? reviews.length : 3).map((review) => (
                    <ReviewCard
                        key={review._id}
                        review={review}
                        onEdit={() => handleEditReviewClick(review)}
                    />
                ))}
                {user && (
                    <WriteReviewButton
                        onClick={
                            currentUserReview
                                ? () => handleEditReviewClick(currentUserReview)
                                : handleWriteReviewClick
                        }
                        isEditing={!!currentUserReview}
                    />
                )}
            </div>
            {showModal && (
                <ReviewModal
                    isEditing={!!editingReview}
                    review={editingReview || newReview}
                    onChange={(updatedReview) =>
                        editingReview
                            ? setEditingReview(updatedReview)
                            : setNewReview(updatedReview)
                    }
                    onClose={resetReviewState}
                    onSubmit={editingReview ? handleEditReview : handleSaveReview}
                />
            )}
        </div>
    );
};

const Header = ({ isExpanded, onToggleExpand }) => (
    <div className="d-flex justify-content-between align-items-center mb-2">
        <h3 className="category-title text-white">Reviews</h3>
        <div>
            <button
                className="btn btn-link text-white-50 small me-2"
                onClick={onToggleExpand}
            >
                {isExpanded ? "See Less" : "See More"}
            </button>
        </div>
    </div>
);

const WriteReviewButton = ({ onClick, isEditing }) => (
    <div className="write-review-card d-flex align-items-center justify-content-center">
        <button
            className="btn btn-gradient text-white text-center fw-bold"
            onClick={onClick}
        >
            <i className="fa fa-plus"></i> {isEditing ? "Edit Review" : "Write a Review"}
        </button>
    </div>
);

export default Reviews;
