import React from 'react';
import { getAiCharacterImageUrlV2 } from '../../../utils/images';
import DefaultImage from '../../../assets/images/splash.png';

const CharacterCardExplore = ({ character, handleNavigateToAiCharacter }) => {
    return (
        <div className='character-card-explore-container' onClick={handleNavigateToAiCharacter}>
            <div className='character-card-explore-image-container'>
                <img src={getAiCharacterImageUrlV2(character)} alt={character?.characterName} className='character-card-explore-image' onError={e => {
                    e.target.src = DefaultImage
                }}/>
            </div>
            <span>{character?.characterName?.length > 15 ? character?.characterName?.substring(0, 13) + "..." : character?.characterName}</span>
            <span className='hashtags-container'>
                {character?.hashtags?.slice(0, 2).map((hashTag, index) => (
                    <span key={index} className='text-center ml-1 hashtag-text'>{hashTag.includes("#") ? hashTag : `#${hashTag}`}</span>
                ))}
            </span>
        </div>
    )
}

export default CharacterCardExplore;
