import React, { useState, useEffect } from 'react';
import { StoryChatApiInstance } from '../../apis/StoryChatAPI';
import { Spinner } from 'react-bootstrap';
import { getStorychatImageUrl } from '../../utils/images';
import defaultStorychatImage from '../../assets/images/splash.png';

export function ViewAllStorychats(props) {
    const {
        setShowViewStorychats, 
        setShowCreateStorychat, 
        userId, 
        setCurrentStorychat,
        setShowEditStorychat,
        setEditStorychat,
        currentStorychat,
        selectedId
    } = props;
    const [storychats, setStorychats] = useState([]);
    const [defaultStorychat, setDefaultStorychat] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        StoryChatApiInstance.getStorychats(selectedId).then((res) => {
            setStorychats(res.data.data);
            setLoading(false);
        });
    }, [selectedId]);

    const handleCreateStorychat = () => {
        setShowCreateStorychat(true);
        setShowViewStorychats(false);
    }

    const handleEditStorychat = (storychat) => {
        setEditStorychat(storychat);
        setShowEditStorychat(true);
        setShowViewStorychats(false);
    }

    const handleSelectStorychat = async (storychat) => {
        console.log('selected storychat', storychat._id);
        try{
            setCurrentStorychat(storychat._id);
            setDefaultStorychat(storychat._id);
        } catch(err){
            console.error('error setting current storychat', err);
        }
    }
    
    return(
        <div className='storychat-modal'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <span>View All Storychats</span>
                <button onClick={handleCreateStorychat} className='create-storychat-button'>
                    <i className="fa-solid fa-plus"></i>
                </button>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                {loading ? <Spinner animation="border" variant="light" size="sm" className='my-4'/> : <></>}
                {storychats.map((storychat, index) => (
                    <div className='storychat-item-card' key={`${storychat._id}-${index}`}>
                        <div>
                            <img src={storychat?.thumbnailUrl ? getStorychatImageUrl(storychat?.thumbnailUrl) : defaultStorychatImage} className='storychat-item-card-image' onError={(e) => {
                                    e.target.src = defaultStorychatImage;
                                }
                            } />
                        </div>
                        <div className='d-flex flex-column storychat-item-card-text'>
                            <span className='font-weight-bold'>{storychat.storyName}</span>
                            <span className='storychat-item-card-message'>{storychat.messages.length > 0 ? storychat.messages[0].messageContent : 'No messages yet.'}</span>
                        </div>
                        <div className='d-flex flex-row justify-content-end align-items-center gap-3'>
                            {currentStorychat === storychat._id ? 
                                <i className="fa fa-check-circle storychat-selected-icon"></i>
                            :
                                <i className='fa fa-circle-o persona-unselected-icon' onClick={() => {handleSelectStorychat(storychat)}}></i>
                            }
                            <i className='fa fa-pencil' onClick={() => {handleEditStorychat(storychat)}}></i>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}