import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { AIApiInstance } from "../../apis/AIAPI";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { getAiCharacterImageUrl } from "../../utils/images";
import "./aicharacter.css";
import { useSearchParams } from "react-router-dom";
import { AnalyticsAPIInstance } from "../../apis/AnalyticsAPI";
import Header from "./components/Header.jsx";
import CharacterInfo from "./components/CharacterInfo.jsx";
import { authAPIInstance } from "../../apis/AuthAPI.js";
import Reviews from "./components/ReviewsSection.jsx";
import RelatedCharacters from "./components/RelatedCharacters.jsx";

export default function AICharacterV2() {
    const { id } = useParams();  // Extract character ID from URL
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser);

    const [character, setCharacter] = useState(() => state?.character ?? null);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [views, setViews] = useState(0);
    const [isFavorite, setIsFavorite] = useState(false);
    const [subscription, setSubscription] = useState({ price: 0, subscription_status: false });
    const [reviews, setReviews] = useState([]);
    const [creator, setCreator] = useState({});
    const [favorites, setFavorites] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const selectedChatUserId = searchParams.get('userId');

    useEffect(() => {
        console.log("character rerender");
        if (!character && id) {
            setLoading(true);
            console.log(`Fetching character data for ID: ${id}`); // Debug log
            AIApiInstance.getCharacterInfo(id)
                .then((res) => {
                    console.log("Fetched character data:", res.data); // Debug log
                    setCharacter(res.data.data.data || res.data.data.character);
                })
                .catch((err) => console.error("Error fetching character:", err))
                .finally(() => setLoading(false));
        }

        handleGetCreatorName();

        handleGetCharacterInfo();

        if(user?.userId === character?.createdBy){
            setShowEdit(true);
        }

    }, [id, character]);

    const handleGetCharacterInfo = async () => {
        AnalyticsAPIInstance.getCharacterProfileViews(id)
            .then((res) => setViews(res.data?.data?.views ?? 0))
            .catch(console.error);
            
        AIApiInstance.getCharacterFavoriteStatus(id)
            .then((res) => {
                setIsFavorite(res.data?.data?.isFavorite);
                console.log('isFavorite', res.data?.data?.isFavorite);
                if(res.data?.data?.isFavorite) {
                    setFavorites(prev => prev + 1);
                }
            })
            .catch(console.error);

        AIApiInstance.getCharacterSubscriptionStatus(id)
            .then((res) => setSubscription(res.data.data))
            .catch(console.error);
    }

    const handleGetCreatorName = async () => {
        if (!character?.createdBy) return;
        try {
            const userProfileResponse = await authAPIInstance.getUserPublicProfile(character.createdBy, '');
            setCreator(userProfileResponse?.data?.data || {});
        } catch (error) {
            console.error('Error fetching creator data:', error);
        }
    };

    const handleMessageClick = () => {
        if (!user) {
            navigate(`/try_ai_chat/${character?._id}`);
            return;
        }
        if (subscription?.price > 0 && !subscription?.subscription_status) {
            const confirm = window.confirm("Do you want to subscribe to this character?");
            if (confirm) {
                if (character?.currency !== 'GEMS') {
                    alert('This currency is not supported now.');
                    return;
                }
                if (user?.gemsCount >= subscription?.price) {
                    setShowSubscribeModal(true);
                } else {
                    alert("GEMS balance is not enough, please get more gems to subscribe.");
                }
            }
        } else {
            navigate(`/chat?type=ai&id=${id}`);
        }
    };
    
    const handleFavoriteClick = () => {
        AIApiInstance.saveCharacterToFavorites(character?._id, !isFavorite)
            .then(() => {
                setIsFavorite(!isFavorite);
                if(!isFavorite) {
                    setFavorites(prev => prev + 1);
                } else {
                    setFavorites(prev => prev - 1);
                }
            })
            .catch(console.error);
    };

    const handleEditClick = () => {
        navigate("/character/update", { state: character });
    };

    if (loading) return <p>Loading character...</p>;
    if (!character) return <p>Character not found.</p>;

    const handleAvatarClick = () => {
        navigate(`/@${creator?.username}`);
    };

    return (
        <div className="ai-character-page">
            <Header creator={creator} character={character} views={views} favorites={favorites} isFavorite={isFavorite} onFavoriteClick={handleFavoriteClick} onAvatarClick={handleAvatarClick} showEdit={showEdit} onEditClick={handleEditClick} />
            <CharacterInfo character={character} handleMessageClick={handleMessageClick} loading={loading} subscription={subscription} />
            <Reviews characterId={character?._id} reviews={reviews} />
            <RelatedCharacters category={character?.category} />
        </div>
    );
}
