import { useState, useEffect } from "react";
import { getAiCharacterImageUrlV2, getUserImage } from "../../utils/images";
import backgroundImage from "../../assets/images/purpleGradientBg.png";
import "./index.css";
import SliderChatBubble from "./SliderChatBubble";
import Avatar from "../Comman/Avatar";
import { useNavigate } from "react-router-dom";
import More from "../../assets/icons/feed/more.png";
import Comment from "../../assets/icons/feed/comment.png";
import Share from "../../assets/icons/feed/share.png";
import { Modal } from "../../Component/Modal/Modal";
import ShareCharacterModal from "./ShareCharacterModal";
import { AIApiInstance } from "../../apis/AIAPI";

export default function Slider({data, user}) {
    const [avatarUrl, setAvatarUrl] = useState('');
    const [aiCharacter, setAiCharacter] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [messages, setMessages] = useState([]);
    const [userAvatar, setUserAvatar] = useState('');
    const [showShareModal, setShowShareModal] = useState(false);
    const [favChar, setFavChar] = useState(false);
    const [notLoggedIn, setNotLoggedIn] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        setAiCharacter(data?.character_info);
        setMessages(data?.topAiChats || []);
        
        const imageurl = getAiCharacterImageUrlV2(data?.character_info);
        setAvatarUrl(imageurl);

        if(data?.user_info){
            setUserInfo(data?.user_info);
        }

        if(user === ''){
            setNotLoggedIn(true);
        }

        console.log('data', data);
    }, [data]);

    useEffect(() => {
        if (aiCharacter?._id && !notLoggedIn) {
            AIApiInstance.getCharacterFavoriteStatus(aiCharacter._id)
                .then((res) => {
                    setFavChar(res.data?.data?.isFavorite);
                })
                .catch(console.error);
        }
    }, [aiCharacter?._id]);

    const subscribeModalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: "80vh",
            maxWidth: "40vw"
        },
    };

    const handleNavigateToCharacter = (e) => {
        if(notLoggedIn){
            navigate('/sign-in');
            return;
        }
        e.preventDefault();
        navigate(`/character/${aiCharacter?._id}`, { state: { character: aiCharacter } });
    };

    const handleChatWithCharacter = (e) => {
        if(notLoggedIn){
            navigate('/sign-in');
            return;
        }
        e.preventDefault();
        navigate(`/chat?type=ai&id=${aiCharacter?._id}#!`);
    };

    const handleNavigateToUserProfile = (e) => {
        if(notLoggedIn){
            navigate('/sign-in');
            return;
        }
        e.preventDefault();
        navigate(`/@${userInfo?.username}`);
    };

    const handleShareCharacter = () => {
        setShowShareModal(true);
    }

    const handleFavoriteCharacter = () => {
        if(notLoggedIn){
            navigate('/sign-in');
            return;
        }else{
            setFavChar(prev => !prev);
        AIApiInstance.saveCharacterToFavorites(aiCharacter?._id, !favChar);
        console.log('fav char', favChar);
        }
    }

    return (
        <div>
            <div className="slider-container position-relative">
                <img className="slider-background-img" src={avatarUrl || backgroundImage} alt="feed-bg" onError={(e) => {e.target.src = backgroundImage;}}/>
                <div className="messages-container-wrapper">
                    <div className="messages-container">
                        {messages.map((message, index) => (
                            <SliderChatBubble key={index} message={message} imageUrl={message?.sentByUser ? userAvatar : avatarUrl} />
                        ))}
                    </div>
                </div>
                <div className="d-flex flex-column position-absolute bottom-0 slider-bottom-container w-100 gap-2">
                    <div className="d-flex align-items-center gap-2" onClick={handleNavigateToCharacter}>
                        <Avatar avatarUrl={avatarUrl} size="small" className="br-50" />
                        <span className="character-name">{aiCharacter?.characterName}</span>
                    </div>
                    <span className="slider-hashtag">
                    {aiCharacter?.hashtags?.length
                        ? `${aiCharacter?.hashtags[0] ? `#${aiCharacter?.hashtags[0]}` : ""} ${aiCharacter?.hashtags[1] ? `#${aiCharacter?.hashtags[1]}` : ""}`
                        : "\u00A0"}
                    </span>
                    <button className="chat-with-character-btn" onClick={handleChatWithCharacter}>
                        <span className="chat-with-character-btn-content">Chat with this CHARACTER</span>
                    </button>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center more-options">
                    {/* <button className="feed-options">
                        <img src={More} alt="more" style={{width: '20px', height: '20px'}}/>
                    </button> */}
                    <button className="feed-options" onClick={handleFavoriteCharacter}>
                        <i className={`icofont-heart fs-5 ${favChar ? 'text-danger' : ''}`}></i>
                    </button>
                    {/* <button className="feed-options">
                        <img src={Comment} alt="comment" style={{width: '15px', height: '15px'}}/>
                    </button> */}
                    <button className="feed-options" onClick={handleShareCharacter}>
                        <img src={Share} alt="share" style={{width: '15px', height: '15px'}}/>
                    </button>
                    <button className="user-profile" onClick={handleNavigateToUserProfile}>
                        <Avatar avatarUrl={getUserImage(userInfo?.avatar_url)} size="extra-small" className="br-50" />
                    </button>
                </div>
            </div>
            <Modal
                modalIsOpen={showShareModal}
                setIsOpen={setShowShareModal}
                styles={subscribeModalStyles}
                body={
                    <ShareCharacterModal aiCharacterId={aiCharacter?._id}/>
                }
            />
        </div>
    )
}
