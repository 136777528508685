import { config, imagePathConfig } from "./config";
import NO_IMAGE from '../assets/images/NO_IMAGE.png';
import WHITE_IMAGE from '../assets/images/WHITE.jpg';

export const getUserImageUrl = (imageName) => {
  if(imageName?.startsWith("http")) {
    return imageName;
  }
  return `${config[config.environment].host}${imagePathConfig.userImagePath}/${imageName}`;
};

export const getAiCharacterImageUrl = (imageName) => {
  if(imageName?.startsWith("http")) {
    return imageName;
  }
  return `${config[config.environment].host}${imagePathConfig.aiCharacterImagePath}/${imageName}`;
};

export const getAiCharacterImageUrlV2 = (character) => {
  const defaultAvatarUrl = "https://web.storychat.app/static/media/orangeMark.a4d8e2be6a0bc8178124.png";
  
  if (!character) {
    return defaultAvatarUrl;
  }

  if (character.s3AvatarUrls?.length > 0) {
    const url = character.s3AvatarUrls[character.s3AvatarUrls.length - 1];
    if (url && typeof url === 'string') {
      return url;
    }
  }

  if (character.avatarUrls?.length > 0) {
    const url = character.avatarUrls[character.avatarUrls.length - 1];
    if (url && typeof url === 'string') {
      return getAiCharacterImageUrl(url);
    }
  }

  if (character.s3CharacterAvatar && typeof character.s3CharacterAvatar === 'string') {
    return character.s3CharacterAvatar;
  }

  if (character.avatarUrl && typeof character.avatarUrl === 'string') {
    if (character.avatarUrl.startsWith("http")) {
      return character.avatarUrl;
    }
    return getAiCharacterImageUrl(character.avatarUrl);
  }

  return defaultAvatarUrl;
};

export const getAiCharacterImageUrlV3 = (aiCharacter, imageType) => {
  if(imageType === 'cover'){
    if(aiCharacter?.s3CoverUrl) {
      return aiCharacter?.s3CoverUrl;
    }
    return getAiCharacterImageUrl(aiCharacter?.coverUrl);
  }
  else if(imageType === 'avatar'){
    if(aiCharacter?.s3AvatarUrls) {
      return aiCharacter?.s3AvatarUrls[aiCharacter?.s3AvatarUrls?.length - 1];
    }else if(aiCharacter?.s3CharacterAvatar) {
      return aiCharacter?.s3CharacterAvatar;
    }else if(aiCharacter?.avatarUrls) {
      return getAiCharacterImageUrl(aiCharacter?.avatarUrls[aiCharacter?.avatarUrls?.length - 1]);
    }else{
      return getAiCharacterImageUrl(aiCharacter?.avatarUrl);
    }
  }
};

export const getMerchantImageUrl = (imageName) => {
    return `${config[config.environment].host}/${
      imagePathConfig.merchantStoreImagePath
    }/${imageName}`;
  };

  export const getCouponQRImageUrl = (_id) => {
    return `${config[config.environment].host}/scan_qr_code?qrType=coupon&couponUUID=${_id}`;
  }; 
  
  export const getCouponImageUrl = (imageName) => {
    return `${config[config.environment].host}/${
      imagePathConfig.couponImagePath
    }/${imageName}`;
  };

  export const getMessageAudioUrl = (audioName) => {
    return `${config[config.environment].host}${
      imagePathConfig.messageAudioPath
    }/${audioName}`;
  };

  export const getSchedueledImageUrl = (imageName) => {
    if(imageName?.startsWith("http")) {
      return imageName;
    }

    return `${config[config.environment].host}/public${
      imagePathConfig.schedueledImagePath
    }/${imageName}`;
  };

  export const getSeriesImageUrl = (imageName) => {
    return `${config[config.environment].host}${
      imagePathConfig.seriesImagePath
    }/${imageName}`;
  };

export const getStoreDisplayableImage = (merchant) => {
    if (merchant?.storePhoto) {
      return getMerchantImageUrl(merchant.storePhoto);
    } else if (merchant?.storePhotos?.[0]) {
      return getMerchantImageUrl(merchant.storePhotos[0]);
    } else {
      return null;
    }
  };

export const getUserImage = (user) => {
  if (user?.s3_avatar_url) {
    return user?.s3_avatar_url;
  }
  
  if(user?.recipient_avatar) {
    if (user?.recipient_avatar.startsWith("http")) {
      return user?.recipient_avatar;
    }
    return getUserImageUrl(user?.recipient_avatar);
  }
  
  return getUserImageUrl(user?.avatar_url);
};

export const getStorychatImageUrl = (imageName) => {
  if(imageName?.startsWith("http")) {
    return imageName;
  }

  return `${config[config.environment].host}${
    imagePathConfig.storychatImagePath
  }/${imageName}`;
};

export const getPersonaImageUrl = (persona) => {
  if (persona?.s3_avatar_url && persona?.s3_avatar_url !== '') {
    return persona?.s3_avatar_url;
  }

  return `${config[config.environment].host}${imagePathConfig.userImagePath}/${persona?.avatar_url}`;
};

export { NO_IMAGE, WHITE_IMAGE };