import React from "react";
import ReactStars from "react-stars";

const ReviewModal = ({ isEditing, review, onChange, onClose, onSubmit }) => {
    const handleOverlayClick = (e) => {
        // Close the modal if the click is on the overlay, not on the modal content
        if (e.target.classList.contains("modal-overlay")) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                {/* Close button */}
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>

                <h6>Reviews</h6>
                <hr className="separator mt-1" />
                <h4>{isEditing ? "Edit Review" : "Leave a Review"}</h4>
                <div className="review-container row">
                    <span className="input-label mt-2">Review</span>
                    <textarea
                        className="form-control mt-1 mb-1"
                        placeholder={review.ratingDescription}
                        value={review.ratingDescription}
                        onChange={(e) => onChange({ ...review, description: e.target.value })}
                    />
                    <div className="rate-section mt-1 mb-2">
                        <span className="rate-label">Rate:</span>
                        <ReactStars
                            count={5}
                            value={review.rating}
                            onChange={(newRating) => onChange({ ...review, rating: newRating })}
                            size={24}
                            color2={"#ffd700"}
                        />
                    </div>
                </div>
                <div className="button-container mt-4">
                    <button className="cancel-button" onClick={onClose}>
                        Delete
                    </button>
                    <button className="submit-button" onClick={onSubmit}>
                        {isEditing ? "Update" : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReviewModal;
