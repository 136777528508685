import React from "react";
import { Carousel } from "react-bootstrap";
import { getAiCharacterImageUrlV2 } from "../../../utils/images";
import DefaultImage from "../../../assets/images/splash.png";

export default function FeaturedCharacters({ featuredCharacters, handleNavigateToAiCharacter }) {
    return (
        <div className="w-100">
            <h4 className="glorify-font">Featured Characters</h4>
            <Carousel className="w-100 mt-4">
                {featuredCharacters.map((character, index) => (
                    <Carousel.Item key={index} className="featured-carousel" onClick={() => handleNavigateToAiCharacter(character._id)}>
                        <img src={getAiCharacterImageUrlV2(character)} alt={character.characterName} className="featured-card-explore-image" onError={(e) => {
                            e.target.src = DefaultImage;
                        }} />
                        <div className="featured-character-info">
                            <h5 className="glorify-font">{character.characterName}</h5>
                            <div className="featured-character-description">
                                <span>{character.description}</span>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}