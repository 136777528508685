import React from 'react';
import './index.css';
import AddImageIcon from '../../assets/icons/addImage.png';
import { useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { getPersonaImageUrl, getUserImageUrl } from '../../utils/images';
import { StoryPersonaApiInstance } from '../../apis/StoryPersonaAPI';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPersona } from '../../Redux/Reducers/PersonaReducer';

export function EditPersonaModal({setShowEditPersonaModal}) {
    const dispatch = useDispatch();
    const lastUsedPersona = useSelector(state => state.persona.currentPersona);
    const [newImage, setNewImage] = useState(null);
    const fileInputRef = useRef(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [personaName, setPersonaName] = useState(lastUsedPersona.personaName);
    const [personaDescription, setPersonaDescription] = useState(lastUsedPersona.description);
    const [loading, setLoading] = useState(false);

    const handleAddProfilePhoto = () => {
        setUploadingImage(true);
        fileInputRef.current.click();
    }

    const handleUpdatePersona = async () => {
        console.log('updating persona');
        setLoading(true);

        const response = await StoryPersonaApiInstance.updateUserPersona(lastUsedPersona._id, personaName, personaDescription, newImage);
        if (response.status === 200) {
            dispatch(
                setCurrentPersona({
                    data: response.data.data.updatedPersona
                })
            );
            setLoading(false);
            setShowEditPersonaModal(false);
            alert('Persona updated successfully');
        }
    }

    return (
        <div className="persona-container-modal">
            <span className="persona-title">Edit User Persona</span>
            <div className="persona-container">
                <div className="add-profile-photo-container">
                    <span className="text-left">Preview Image</span>
                    <div className="current-persona-image-container">
                        <div className="current-persona-image">
                            <img className="add-profile-photo-icon current-persona-avatar" src={newImage ? URL.createObjectURL(newImage) : getPersonaImageUrl(lastUsedPersona)} />
                        </div>
                    </div>
                    <button className="add-profile-photo-button" onClick={handleAddProfilePhoto}>
                        <img src={AddImageIcon} className='add-profile-photo-icon' alt="Add profile" />
                        <input 
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    setNewImage(e.target.files[0]);
                                }
                                setUploadingImage(false);
                            }}
                        />
                    </button>
                    <span className="text-center">
                        Add Profile Photo 
                        {uploadingImage && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                    </span>
                </div>
                <div className="persona-name-container">
                    <span className="text-left">Name</span>
                    <input 
                        type="text" 
                        className="persona-name-input" 
                        placeholder="Name" 
                        maxLength={50}
                        onChange={(e) => setPersonaName(e.target.value)}
                        value={personaName}
                        required
                    />
                </div>
                <div className="persona-description-container">
                    <textarea 
                        className="persona-description-textarea" 
                        placeholder="Your Persona Description" 
                        rows={3} 
                        maxLength={1000}
                        onChange={(e) => setPersonaDescription(e.target.value)}
                        value={personaDescription}
                    />
                    <div className="character-count">
                        {personaDescription.length}/1000
                    </div>
                </div>
                <div className="persona-button-container">
                    <button className="back-button flex-grow-1" onClick={() => setShowEditPersonaModal(false)} disabled={loading}>
                        <div className="back-button-content">
                            <span>Cancel</span>
                        </div>
                    </button>
                    <button className="next-button violet-gradient-btn flex-grow-1" onClick={handleUpdatePersona} disabled={loading}>
                        <div className="next-button-content">
                            <span>{loading ? 'Updating...' : 'Update'}</span>
                            {loading && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}
