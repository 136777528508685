import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Form, Spinner } from 'react-bootstrap';
import { StoryChatApiInstance } from '../../apis/StoryChatAPI';

export function CreateStorychatModal({setShowCreateStorychat, currentCharacter, setCurrentStorychat}) {
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [storyName, setStoryName] = useState('');
    const [description, setDescription] = useState('');
    const [isNSFW, setIsNSFW] = useState(false);

    const handleAddThumbnail = () => {
        fileInputRef.current.click();
    }

    const handleSubmitStorychat = async () => {
        setLoading(true);
        const res = await StoryChatApiInstance.createEmptyStoryChat(currentCharacter, storyName, description, isNSFW, image);
        if(res.status === 200) {
            setLoading(false);
            alert('Storychat created successfully.');
            setCurrentStorychat(res.data.data._id);
            setShowCreateStorychat(false);
        }else{
            console.log('error', res);
        }
    }

    return (
        <div className='storychat-modal poppins-font'>
            <span className='font-bold'>Create Story Chats</span>
            <div>
                <div>
                    <span>Replace Image</span>
                    <div className='create-image-container'>
                        {image ? <img src={URL.createObjectURL(image)} alt='storychat-image' className='create-storychat-modal-thumbnail' /> : <></>}
                        <button className='replace-image-button' onClick={handleAddThumbnail}>
                            <span><i className='fa-solid fa-plus me-2'></i>Replace Image</span>
                            <input 
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        setImage(e.target.files[0]);
                                    }
                                }}
                            />
                        </button>
                    </div>
                </div>
                <div className='create-storychat-modal-input-container'>
                    <span>Title</span>
                    <input 
                        type='text' 
                        placeholder='Add Title'
                        onChange={(e) => setStoryName(e.target.value)}
                        value={storyName} 
                    />
                </div>
                <div className='create-storychat-modal-input-container'>
                    <span>Description</span>
                    <textarea 
                        type='text' 
                        placeholder='Add Description'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </div>
                <div className='create-storychat-modal-switch-option'>
                    <span>Enable NSFW Content</span>
                    <Form.Switch
                        className='create-storychat-modal-switch-option-switch'
                        defaultChecked={ isNSFW }
                        onChange={(e) => setIsNSFW(e.target.checked)}
                    />
                </div>
                <div className='create-storychat-modal-button-container'>
                    <button className="back-button flex-grow-1" onClick={() => setShowCreateStorychat(false)} disabled={loading}>
                        <div className="back-button-content">
                            <span>Cancel</span>
                        </div>
                    </button>
                    <button className="next-button violet-gradient-btn flex-grow-1" onClick={handleSubmitStorychat} disabled={loading}>
                        <div className="next-button-content">
                            <span>{loading ? 'Creating...' : 'Create'}</span>
                            {loading && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}
