import React, { useState } from "react";
import { getAiCharacterImageUrl } from "../../../utils/images";

const PhotoModal = ({ showModal, setShowModal, photos }) => {
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State to store the clicked photo

    const handleOverlayClick = (e) => {
        // Close the modal if the click is on the overlay
        if (e.target.classList.contains("modal-overlay")) {
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleImageClick = (photo) => {
        // Set the clicked photo to show in a larger view
        setSelectedPhoto(photo);
    };

    const handleClosePopup = () => {
        // Close the large image popup
        setSelectedPhoto(null);
    };

    return (
        showModal && (
            <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="modal-content">
                    {/* Modal Header with Close Button */}
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="modal-title mb-0">All Character Images</h6>
                        <button className="close-button" onClick={handleClose}>
                            &times;
                        </button>
                    </div>

                    <hr className="separator mt-1" />
                    
                    {/* Modal Body with Thumbnails */}
                    <div className="photo-modal-body">
                        {photos.map((photo, index) => (
                            <div
                                key={index}
                                className="photo-thumbnail"
                                onClick={() => handleImageClick(photo)}
                                style={{ cursor: "pointer" }}
                            >
                                <img
                                    src={getAiCharacterImageUrl(photo)}
                                    alt={`Photo ${index + 1}`}
                                    className="photo-modal-img"
                                />
                                <div className="photo-name">{photo.name}</div> {/* Display the name under the photo */}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Popup Modal for Large Image */}
                {selectedPhoto && (
                    <div className="large-photo-popup-overlay" onClick={handleClosePopup}>
                        <div className="large-photo-popup-content">
                            <button className="close-button" onClick={handleClosePopup}>
                                &times;
                            </button>
                            <img
                                src={getAiCharacterImageUrl(selectedPhoto)}
                                alt="Large View"
                                className="large-photo"
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default PhotoModal;
