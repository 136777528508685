import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Redux/Reducers/AuthReducer";
import Mainreducer from "./Redux/Reducers/Mainreducer";
import ChatReducer from "./Redux/Reducers/ChatReducer";
import MerchantReducer from "./Redux/Reducers/StoreReducer";
import PersonaReducer from "./Redux/Reducers/PersonaReducer";
import ProxyReducer from "./Redux/Reducers/ProxyReducer";

const store = configureStore({
    reducer: {
        AuthReducer,
        Mainreducer,
        chat: ChatReducer.reducer,
        merchant: MerchantReducer.reducer,
        persona: PersonaReducer.reducer,
        proxy: ProxyReducer.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;
