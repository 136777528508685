import React from 'react'
import { NO_IMAGE, getAiCharacterImageUrl, getAiCharacterImageUrlV2 } from '../../../../utils/images';
import Avatar from '../../../../Component/Comman/Avatar';
import { useNavigate } from 'react-router';
import CharacterCardV2 from '../../../../Component/Explore/CharacterCard';

export default function CharactersTab({characters = []}) {

    const navigate = useNavigate();

    const handleCharacterClick = (_id) => {
        navigate(`/character/${_id}`);
    };

    return (
        <>
            {characters.length == 0 ? (
                <div style={{ height: "50vh" }} className="d-flex flex-column align-items-center justify-content-center">
                    <h6>There are no characters to show</h6>
                </div>
            ) : (
                <div className="d-flex flex-row text-start flex-wrap w-100 characters-content-container">
                    {characters.map((ch) => (
                        <CharacterCardV2
                        key={ch?._id}
                        onClick={handleCharacterClick}
                        id={ch?._id}
                        title={ch?.characterName}
                        creatorName={ch?.createdBy?.username}
                        description={ch?.description}
                        itemUrl={getAiCharacterImageUrlV2(ch)}
                        />
                    ))}
                </div>
            )}
        </>
    );
}
