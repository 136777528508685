import React from "react";
import PageTitle from "../../Component/Comman/PageTitle";
import AdvanceSecurity from "../../Component/SecurityPage/AdvanceSecurity";
import Authentication from "../../Component/SecurityPage/Authentication";
import SettingsMenu from "../../Component/SecurityPage/SettingsMenu";
import DeleteAccount from "../../Component/SecurityPage/DeleteAccount";

function SecurityPage({ logout }) {
    return (
        <>
            <div className="px-lg-4 px-md-4">
                <PageTitle pagetitle="Settings" sidebutton={true} />
                <div className="container-xxl">
                    <div className="row align-item-center mb-3">
                        <SettingsMenu />
                    </div>
                    <div className="row align-item-center mb-3">
                        <Authentication />
                    </div>
                    <div className="row align-item-center mb-3">
                        <AdvanceSecurity />
                    </div>
                    <div className="row align-item-center mb-3">
                        <DeleteAccount logout={logout} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SecurityPage;
