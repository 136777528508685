import React, { useEffect, useState } from 'react'
import { Tab, Nav } from "react-bootstrap";
//import CouponsList from './CouponsList';
import { PointRewardApiInstance } from '../../apis/PointRewardApi';
import { readCookie } from '../../utils/cookie';
import PointsList from './PointsList';
import Earnings from './Earnings';
import "./couponWallet.css";
import ProfileSidebar from '../CreatorMarketplace/CreatorProfile/components/ProfileSidebar';

export default function CouponWallet({ logout }) {
  const [points, setpoints] = useState([]);
  const token = readCookie('token');  

  useEffect(() => {
    PointRewardApiInstance.getAllMyPoints(token)
    .then(res => {
        setpoints(res?.data?.data?.pointWallets ?? []);
    })
    .catch(console.error);
  }, [token]);

  return (
    <div className="d-flex flex-start w-100 profile-container">
        <div className="d-flex flex-start coupon-wallet-sidebar profile-sidebar position-relative text-white pt-2 z-index-1">
            <ProfileSidebar
                level={5}
                xp={5}
                logout={logout}
            />
        </div>
        <div className="container-xxl coupon-wallet-body poppins-font">
            <div className="g-3 mb-3 w-100 coupon-content-body">
                <div className='d-flex balance-section'>
                    <div className='d-flex balance-figure'>
                        <h5>Estimated Balance in USD</h5>
                        <h3>$10,000</h3>
                    </div>
                    <div className='d-flex action-buttons-container'>
                        <button>Deposit</button>
                        <button>Withdraw</button>
                    </div>
                </div>
                <div className="col-md-12">
                    <Tab.Container defaultActiveKey="points">
                        <div className="card-header pb-3 d-flex justify-content-between bg-transparent align-items-center flex-wrap">
                            <Nav className="tab-navigation nav nav-pills rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                                <Nav.Item className="nav-item">
                                    <Nav.Link
                                        eventKey="points"
                                        className="nav-link "
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-selected="false"
                                    >
                                        <span>Points</span>
                                    </Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item className="nav-item">
                                    <Nav.Link
                                        eventKey="coupons"
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        Coupons
                                    </Nav.Link>
                                </Nav.Item> */}
                                <Nav.Item className="nav-item">
                                    <Nav.Link
                                        eventKey="earnings"
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        role="tab"
                                        aria-selected="true"
                                    >
                                        <span>Earnings</span>
                                    </Nav.Link>
                                </Nav.Item>
                                
                            </Nav>
                        </div>
                        <div className="card-body">
                            <Tab.Content className="tab-content d-flex flex-column">
                                
                                <Tab.Pane className="tab-pane fade" id="points" eventKey="points">
                                    <PointsList points={points}/>
                                </Tab.Pane>
                                {/* <Tab.Pane className="tab-pane fade show" id="coupons" eventKey="coupons">
                                    <CouponsList/>
                                </Tab.Pane> */}
                                <Tab.Pane className="tab-pane fade show" id="earnings" eventKey="earnings">
                                    <Earnings/>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </div>
    </div>
    
  )
}
