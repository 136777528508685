import React, { useState} from "react";
import { selectCurrentUser } from "../../../../Redux/selectors/auth";
import { useSelector } from "react-redux";
import { getUserImage } from "../../../../../src/utils/images";
import { useNavigate } from "react-router-dom";
import Profile from "../../../../assets/images/profile_av.svg";

function validateProfilePhoto(url) {
    try {
        const targetFolder = 'profile_photos/';
        const urlParts = new URL(url);

        // Check if the pathname includes the target folder
        if (urlParts.pathname.includes(targetFolder)) {
            const afterFolder = urlParts.pathname.split(targetFolder)[1];

            if (!afterFolder || afterFolder.trim() === '') {
                return Profile
            } else {
                return url
            }
        } else {
            throw new Error(`The URL does not contain the ${targetFolder} folder.`);
        }
    } catch (error) {
        console.error(error.message);
    }
}

const ProfileSidebar = ({ level, xp, logout }) => {
    const navigate = useNavigate();
    const links = [
        { label: 'Wallet', url: '/coupon-wallet', icon: 'bi bi-wallet' },
        { label: 'Settings', url: '/settings', icon: 'bi bi-gear' },
        { label: 'Sign Out', url: '#', icon: 'bi bi-box-arrow-right' },
    ];
    const user = useSelector(selectCurrentUser);
    const name=user?.username;
    const [userInfo, setUserInfo] = useState({});
    const profilePicture = getUserImage(user);
    const handleLogout = () => {
        logout();
    };
    return (
        <div className=" text-light p-3 d-flex flex-column profile-sidebar-content">
            {/* Profile Section */}
            <div className="d-flex profile-section mb-4 text-center p-2 flex-column w-100" onClick={() => navigate("/my-profile")}>
                <div className="d-flex upper-subsection">
                    <img
                        src={getUserImage(user)}
                        alt="Profile"
                        className="profile-image mb-3"
                        
                    />
                    <div className="d-flex flex-column user-det">
                        <h5 className="mb-0">{name}</h5>
                        <div className="d-block plan-container">
                            <div>
                                <h7>Basic</h7>
                            </div>

                        </div>
                    </div>
                    
                </div>
                
                <div className="d-flex xp-stats justify-content-between px-">
                    <span>Lv.{level}</span>
                    <small>{xp} XP until next level</small>
                    
                </div>
                <hr></hr>
            </div>

            {/* Navigation Links */}
            <ul className="nav flex-column">
                {links.map((link, index) => (
                    <li className="nav-item" 
                        key={index}
                        onClick={link.label === "Sign Out" ? handleLogout : undefined}
                    >
                        <a href={link.url} className="nav-link text-light d-flex align-items-center">
                            <i className={`me-2 ${link.icon}`}></i>
                            {link.label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProfileSidebar;
