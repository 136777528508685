import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { authAPIInstance } from "../../apis/AuthAPI";
import F4 from "../../assets/images/flag/CH.png";
import F9 from "../../assets/images/flag/ES.png";
import { default as F1 } from "../../assets/images/flag/GB.png";
import F7 from "../../assets/images/flag/JP.png";
import F2 from "../../assets/images/flag/KR.png";
import F3 from "../../assets/images/flag/PH.png";
import F5 from "../../assets/images/flag/PK.png";
import F8 from "../../assets/images/flag/RU.png";
import F6 from "../../assets/images/flag/VN.png";
import i18n from "../../i18n";
import { getUserIpInfo } from "../../utils/getUserCountry";
import { USER_LANGUAGE_KEY } from "../../utils/localStorage";

const CountryCodeToLangCode = {
    VN: "vn",
    KR: "ko",
    PH: "ph",
    CN: "ch",
    PK: "ur",
    JP: "jp",
    RU: "ru",
    es: 'es'
};

const LangCodeToFlagImage = {
    en: F1,
    ko: F2,
    ph: F3,
    ch: F4,
    ur: F5,
    vn: F6,
    jp: F7,
    ru: F8,
    es: F9
};

export default function LanguageDropDown({ autoSetLanguage = true }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    // if user logged in save language to backend on user lang change
    // if user not logged in save language to local storage on user lang change

    // if user logged in get lang preference from backend and change lang
    // if user not logged in get lang preference from local storage and change lang

    // if user logged in ask before auto changing lang
    // if user not logged in ask before auto changing lang

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (autoSetLanguage) {
            let user_lang = null;
            const local_user_lang = localStorage.getItem(USER_LANGUAGE_KEY);
            if (user?.userLanguageCode) {
                user_lang = user?.userLanguageCode;
            } else if (local_user_lang) {
                user_lang = local_user_lang;
            }
            setLanguageByCountry(user_lang);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSetLanguage]);

    const setLanguageByCountry = async (lng = null) => {
        try {
            if (!lng) {
                const { country } = await getUserIpInfo();
                if (country) {
                    updateLanguageCountry(country);
                } else {
                    i18n.changeLanguage(lng);
                }
            } else {
                i18n.changeLanguage(lng);
            }
        }
        catch (error) {
            console.log("error: ", error);
        }
    };

    const updateLanguageCountry = (country) => {
        const lang_code = CountryCodeToLangCode[country];
        if (!lang_code) {
            localStorage.setItem(USER_LANGUAGE_KEY, "en");
            i18n.changeLanguage("en");
        } else {
            localStorage.setItem(USER_LANGUAGE_KEY, lang_code);
            i18n.changeLanguage(lang_code);
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    const getFlagImage = () => {
        const currentLanguage = i18n.language;
        const flagImage = LangCodeToFlagImage[currentLanguage];
        if (!flagImage) {
            return F1;
        }
        return flagImage;
    };

    const handleLanguageChange = (e) => {
        const lng = e?.target?.attributes?.lng?.nodeValue;
        console.log("handleLanguageChange lng: ", lng);
        if (user && lng) {
            authAPIInstance.updateLanguage(lng);
            dispatch({
                type: "UPDATE",
                payload: {
                    user: {
                        key: "userLanguageCode",
                        value: lng
                    }
                }
            });
        }
        localStorage.setItem(USER_LANGUAGE_KEY, lng);
        i18n.changeLanguage(lng);
    };

    return (
        <Dropdown className="dropdown mx-2 mx-lg-3 language-button-container">
            <Dropdown.Toggle as="s" className="nav-link dropdown-toggle pulse language-button-icon" role="button" data-bs-toggle="dropdown">
                <img src={getFlagImage()} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu
                ref={dropdownRef}
                className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end m-0"
                show={dropdownOpen}
                onSelect={() => setDropdownOpen(false)}
            >
                <div className="card border-0 ">
                    <ul className="list-unstyled py-2 px-3">
                        <li>
                            <a href="#!" lng="en" onClick={handleLanguageChange}>
                                <img src={F1} alt="" /> English
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="ko" onClick={handleLanguageChange}>
                                <img src={F2} alt="" /> Korean
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="ph" onClick={handleLanguageChange}>
                                <img src={F3} alt="" /> Filipino
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="ch" onClick={handleLanguageChange}>
                                <img src={F4} alt="" /> Chinese
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="ur" onClick={handleLanguageChange}>
                                <img src={F5} alt="" /> Urdu
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="vn" onClick={handleLanguageChange}>
                                <img src={F6} alt="" /> Vietnamese
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="jp" onClick={handleLanguageChange}>
                                <img src={F7} alt="" /> Japanese
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="ru" onClick={handleLanguageChange}>
                                <img src={F8} alt="" /> Russian
                            </a>
                        </li>
                        <li>
                            <a href="#!" lng="es" onClick={handleLanguageChange}>
                                <img src={F9} alt="" /> Spanish
                            </a>
                        </li>
                    </ul>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
