import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import orangeLogo from "../../../assets/images/logo/orangeMark.png";
import redchatLogo from "../../../assets/images/logo/redbit.png";
import { getBrowserCurrentUrl } from "../../../utils/getCurrentUrl";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { useSelector, useDispatch } from "react-redux";
import { getUserImage, getUserImageUrl } from "../../../utils/images";
import Profile from "../../../assets/images/profile_av.svg";
import { useNavigate } from "react-router-dom";
import './sidebar.css';
import tryStorychat from "../../../assets/icons/try_storychat.png"
import { Dropdown } from "react-bootstrap";
import { readCookie } from "../../../utils/cookie";
import LanguageDropDown from "../../Comman/LanguageDropDown";
import { logout } from "../../../Redux/Actions/AuthActions";

function Sidebar(props) {
    const { t } = useTranslation("menu");

    const [isSidebarMini, setIsSidebarMini] = useState(false);
    const [topLogo, setTopLogo] = useState(orangeLogo);
    const baseUrl = process.env.PUBLIC_URL;
    const currentUrl = getBrowserCurrentUrl();
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [urlPathname, setUrlPathname] = useState(location.pathname);
    const [isHidden, setIsHidden] = useState(false);
    const token = readCookie('token');

    useEffect(() => {
        setUrlPathname(location.pathname);
        if(urlPathname === '/coupon-wallet' || urlPathname === '/my-profile') {
            toggleProfileSidebar();
        } else {
            untoggleProfileSidebar();
        }
        if (currentUrl.includes("redchat")) {
            setTopLogo(redchatLogo);
        } else {
            setTopLogo(orangeLogo);
        }

        if (urlPathname.includes("@")) {
            untoggleProfileSidebar();
        }
    }, [currentUrl, urlPathname]);

    const toggleMenu = () => {
        var sidebar = document.getElementById("mainsidemenu");
            if (sidebar) {
                if (sidebar.classList.contains("open")) {
                    sidebar.classList.remove("open");
                } else {
                    sidebar.classList.add("open");
                }
            }
    };

    const toggleProfileSidebar = () => {
        setIsSidebarMini(true); 
        const backBtn = document.querySelector(".sidebar-back-btn");
        setTimeout(() => {
            if(backBtn){
                backBtn.classList.add("d-none"); 
            }
        }, 0);
    }

    const untoggleProfileSidebar = () => {
        const button = document.querySelector(".sidebar-back-btn");
        setTimeout(() => {
            setIsSidebarMini(false); 
            if(!(button.classList.contains("d-none"))){
                button.classList.remove("d-none");
            }
        },0);
    }

    const { activekey, menuData, GotoChangeMenu } = props;

    const getMenuItemClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'feed': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "active" : "";
    };

    const getMenuIconClass = (routerLink) => {
        let _activeKey = activekey === '/' ? 'feed': activekey;
        return ("/" + routerLink).includes(_activeKey) ? "" : "unselected-icon";
    };

    const handleLogout = () => {
        props.dispatch(logout());
    };

    return (
        <div id="mainsidemenu" className={`sidebar overflow-x-visible me-0 border-end poppins-font ${isSidebarMini ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <div className={`p-2 pb-2 m-0 border-bottom align-items-center d-flex flex-row  ${isSidebarMini ? "justify-content-center" : "justify-content-between"}`} style={{}}>
                    <a href={process.env.PUBLIC_URL + "/"} className="mb-0 brand-icon">
                        <span className="logo-text " style={{ color: "var(--bs-orange)" }}>
                        <img className="orangeLogo" src={topLogo} alt="" />
                        </span>
                    </a>
                    <button
                        type="button"
                        className={`sidebar-back-btn ${isSidebarMini ? "sidebar-hidden-back-btn" : ""}`}
                        onClick={() => {setIsSidebarMini(!isSidebarMini);}}
                    >
                        <span><i className={`${isSidebarMini ? "icofont-curved-right" : "icofont-curved-left"}`}></i></span>
                    </button>
                </div>
                <hr className="my-0"></hr>
                <ul className="menu-list flex-grow-1 px-2 py-3 gap-2 d-flex flex-column">
                    {menuData.map((d, i) => {
                        if (d.children.length === 0) {
                            return (
                                <li key={"sidebar-item-" + i} className="collapsed">
                                    <Link
                                        to={"/" + d.routerLink[0]}
                                        onClick={() => {
                                            const isHidden = `/${d.routerLink}` === '/my-profile' || `/${d.routerLink}` === '/coupon-wallet';
                                            if (isHidden) {
                                                toggleProfileSidebar();
                                            } else {
                                                untoggleProfileSidebar();
                                            }
                                            toggleMenu();
                                        }}
                                        className={`m-link sidebar-item-container px-2 ${getMenuItemClass(d.routerLink[0])}`}
                                    >
                                        <div className="sidebar-icon">
                                            <img src={d.iconPath} alt="icon" className={getMenuIconClass(d.routerLink[0])} />
                                        </div>
                                        <div className="menu-item-text">
                                            <span className="mb-0">{t(`menu:${d.name.replace(/\s+/g, "_")}`)}</span>
                                        </div>
                                    </Link>
                                </li>
                            );
                        }
                    })}
                </ul>
                <hr></hr>

                <div className="px-4 pb-4">
                    <button className="sidebar-try-btn" onClick={() => {untoggleProfileSidebar(); navigate("/premium-price")}}>

                        <div className="sidebar-try-btn-content">
                            {!isSidebarMini && <span>Try StoryChat</span>}
                            <img src={tryStorychat} style={{marginLeft: isSidebarMini ? "0px" : "10px"}}/> 
                        </div>
                    </button>
                </div>
                <div className={`d-flex flex-start align-items-center gap-2 pb-4 px-4 ${isSidebarMini ? "justify-content-center" : ""}`} onClick={() => {   
                    toggleProfileSidebar();
                    navigate("/my-profile")}}>
                    <img className="avatar md rounded-circle img-thumbnail" src={getUserImage(user)} alt="profile" />
                    {!isSidebarMini && (
                        <span>{user?.username}</span>
                    )}
                </div>


                <div className="d-flex pb-2 justify-content-center align-self-center">
                    <LanguageDropDown />
                </div>
                
                {/* <div className={`d-flex flex-start align-items-center gap-2 pb-2 px-4 ${isSidebarMini ? "justify-content-center" : ""}`}>
                    <Dropdown>
                        <Dropdown.Toggle
                            as="dd"
                            className="nav-link dropdown-toggle pulse p-0"
                            role="button"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                        >
                            <img className="avatar md rounded-circle img-thumbnail" src={getUserAvatar()} alt="profile" />
                            {!isSidebarMini && (
                                <span className="ms-2">{user?.username}</span>
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 sidebar-dropdown-menu"
                            container="body"
                        >
                            <div className="card border-0 w280">
                                <div className="list-group m-2 ">
                                    <button
                                        onClick={() => navigate("/my-profile")}
                                        className="list-group-item list-group-item-action border-0"
                                    >
                                        My Profile
                                    </button>
                                    <button
                                        onClick={() => navigate("/settings")}
                                        className="list-group-item list-group-item-action border-0"
                                    >
                                        Settings
                                    </button>
                                    <button
                                        onClick={() => navigate("/premium-price")}
                                        className="list-group-item list-group-item-action border-0 "
                                    >
                                        Pricing
                                    </button>
                                    <button
                                        onClick={handleLogout}
                                        className="list-group-item list-group-item-action border-0 "
                                    >
                                        Sign out
                                    </button>
                                </div>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
                
            </div>
            
            
        </div>
    );
}

export default Sidebar;
