import React, { useState } from "react";
import CharactersTab from "./CharactersTab";
import SeriesTab from "./SeriesTab";
import ".././creatorProfile.css";

const tabsEnum = {
    series: "Series",
    characters: "Characters",
};

const tabs = [
   /*  { text: "Series", category: tabsEnum.series, disabled: false }, */
    { text: "Characters", category: tabsEnum.characters, disabled: false },
];

export default function ProfileTabs({characters = [], series = []}) {
    const [selectedtab, setselectedtab] = useState(tabsEnum.characters);

    const tabsContent = {
        [tabsEnum.characters]: <CharactersTab characters={characters}/>,
        /* [tabsEnum.series]: <SeriesTab series={series}/>, */
    };

    const selectTab = (tab) => {
        if(!tab.disabled){
            setselectedtab(tab.category);
        }
    };

    return (
        <div className="profiles-tab-container w-100">
            <div className="profiles-tab-container-tabs p-3">
                {tabs.map((tab, i) => (
                    <div
                        id={`user-profiles-tab-${i}`}
                        key={`user-profiles-tab-${i}`}
                        onClick={() => {
                            selectTab(tab);
                        }}
                        style={tab.disabled ? { pointerEvents: "none", opacity: 0.4 } : {}}
                        className={`profiles-tab ${tab.category === selectedtab ? "profiles-selected-tab" : ""}`}
                    >
                        {tab.text}
                    </div>
                ))}
            </div>
            <div className="profiles-tab-content d-flex text-start w-100 p-3">{tabsContent[selectedtab]}</div>
        </div>
    );
}