import React from "react";
import Avatar from "../../../Component/Comman/Avatar";
import { getUserImageUrl } from "../../../utils/images";
import { formatDate } from "../../../utils/formatter/dateFormatter";
import { Button } from "react-bootstrap";

export default function Referral({earning}) {
    const { username, avatar, totalEarnings, currency, payout_id, action, withdrawn, timeStamp } = earning;
    return (
        <>
            <div className="referral-earning-card table-row d-flex flex-row gap-2">
                
                <div className="table-cell user-column">
                    <div className="d-flex gap-2">
                        <Avatar avatarUrl={getUserImageUrl(avatar)} className="referral-avatar"/>
                        <span>{username}</span>
                    </div>
                </div>
                <div className="table-cell earnings-column">
                    <div className="">
                        <span className="">{totalEarnings} {currency}</span>
                    </div>
                </div>
                <div className="table-cell earned-date-column">
                    <div className="">
                        <span className="">{formatDate(timeStamp)}</span>
                    </div>
                </div>
                
                
                {/* <Button className="withdraw-btn">
                    Withdraw
                </Button> */}
            </div>
        </>
    );
}
