import axios from "axios";
import { buildMessagesOpenAiFormat } from "./cosmosPromptHelper";

export class CosmosApiReverseProxy {
    constructor() {
        const cosmosRPUrl = 'https://api.pawan.krd/cosmosrp-pro-it/v1';

        this.axios = axios.create({
            baseURL: cosmosRPUrl,
        });
    }

    sentProxyMessage(previousMessages, 
        characterDetails, 
        sender_username, 
        userMessageToSend, 
        rolePlay = true) {

        const builtMessages = buildMessagesOpenAiFormat(previousMessages , 
            characterDetails, 
            sender_username, 
            userMessageToSend, 
            rolePlay);
        
        return this.axios.post(
            '/chat/completions',
            {
                model: 'cosmosrp',
                messages: builtMessages,
                //separate with system role and user roles messages: [{ role: 'user', content: userMessage }]
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer pk-TgeiDCUDabCrFIPHrlcEJbVNGiKyVIIonjKDtmhnfCSAMGQf`,
                }
            },
        );
    }
}

export const CosmosAPIReverseProxyInstance = new CosmosApiReverseProxy();

export default CosmosApiReverseProxy;