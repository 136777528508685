import React from 'react';
import './index.css';
import AddImageIcon from '../../assets/icons/addImage.png';
import { useState, useRef } from 'react';
import { StoryPersonaApiInstance } from '../../apis/StoryPersonaAPI';
import { Spinner } from 'react-bootstrap';
import { setCurrentPersona, setAllMyPersonas } from '../../Redux/Reducers/PersonaReducer';
import { useDispatch } from 'react-redux';

export function CreateNewPersona({ setShowCreateNewPersonaModal }) {
    const [newImage, setNewImage] = useState(null);
    const fileInputRef = useRef(null);
    const [personaName, setPersonaName] = useState('');
    const [personaDescription, setPersonaDescription] = useState('');
    const [uploadingImage, setUploadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    
    const handleCreatePersona = async () => {
        setLoading(true);

        try {
            const response = await StoryPersonaApiInstance.createNewUserPersona(personaName, personaDescription, newImage);
            
            if (response.status === 201) {
                setPersonaName('');
                setPersonaDescription('');
                setNewImage(null);
                setLoading(false);
                alert('Persona created successfully');
                setShowCreateNewPersonaModal(false);
            }
        } catch (error) {
            console.error('Error creating persona:', error);
        } finally {
            setLoading(false);
            updateAllMyPersonas();
        }
    }

    const updateAllMyPersonas = async () => {
        const res = await StoryPersonaApiInstance.getUserPersonas();

        const uniquePersonas = res.data.data.allMyPersonas.filter(
            (persona, index, self) => 
                index === self.findIndex(p => p._id === persona._id)
        );

        dispatch(
            setAllMyPersonas({
                data: uniquePersonas,
            })
        );
    }

    const handleAddProfilePhoto = () => {
        setUploadingImage(true);
        fileInputRef.current.click();
    }

    return (
        <div className="persona-container-modal">
            <span className="persona-title">Create User Persona</span>
            <div className="persona-container">
                <div className="add-profile-photo-container">
                    <span className="text-left">Preview Image</span>
                    <button className="add-profile-photo-button" onClick={handleAddProfilePhoto}>
                        <img src={newImage ? URL.createObjectURL(newImage) : AddImageIcon} className='add-profile-photo-icon' alt={newImage ? "Preview of selected profile" : "Add profile"} />
                        <input 
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => {
                                if (e.target.files?.[0]) {
                                    setNewImage(e.target.files[0]);
                                }
                                setUploadingImage(false);
                            }}
                        />
                    </button>
                    <span className="text-center">
                        Add Profile Photo 
                        {uploadingImage && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                    </span>
                </div>
                <div className="persona-name-container">
                    <span className="text-left">Name</span>
                    <input 
                        type="text" 
                        className="persona-name-input" 
                        placeholder="Name" 
                        maxLength={50}
                        onChange={(e) => setPersonaName(e.target.value)}
                        value={personaName}
                        required
                    />
                </div>
                <div className="persona-description-container">
                    <textarea 
                        className="persona-description-textarea" 
                        placeholder="Your Persona Description" 
                        rows={3} 
                        maxLength={1000}
                        onChange={(e) => setPersonaDescription(e.target.value)}
                        value={personaDescription}
                    />
                    <div className="character-count">
                        {personaDescription.length}/1000
                    </div>
                </div>
                <div className="persona-button-container">
                    <button className="back-button flex-grow-1" disabled={loading} onClick={() => setShowCreateNewPersonaModal(false)}>
                        <div className="back-button-content">
                            <span>Cancel</span>
                        </div>
                    </button>
                    <button className="next-button violet-gradient-btn flex-grow-1" onClick={handleCreatePersona} disabled={loading}>
                        <div className="next-button-content">
                            <span>{loading ? 'Creating...' : 'Create'}</span>
                            {loading && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}
