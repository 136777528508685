import axios from "axios";
import { config } from "../../utils/config";
import { readCookie } from "../../utils/cookie";
import { httpTokenInterceptor } from "../common";

class ReverseProxySaveAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/reverse_proxy"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    saveUserSentProxyMessage(userMessage, characterId, storyChatId, persona) {
        return this.axios.post(
            '/save_user_sent_message',
            { userMessage, characterId, storyChatId, persona },
            {
                headers: {
                    Authorization: `Bearer ${readCookie('token')}`,
                },
            },
        );
    }

    saveProxyPromptResult(
        proxyPromptResult,
        aiCharacterID,
        storyChatId,
        persona
    ) {
        return this.axios.post(
            '/save_proxy_prompt_result',
            { proxyPromptResult, aiCharacterID, storyChatId, persona },
            {
                headers: {
                    Authorization: 
                        `Bearer ${readCookie('token')}`,
                },
            },
        );
    }
}

export const ReverseProxySaveApiInstance = new ReverseProxySaveAPI();

export default ReverseProxySaveAPI;