import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { authAPIInstance } from "../../apis/AuthAPI";

function DeleteAccount({ logout }) {
    const [showModal, setShowModal] = useState(false);

    const handleDelete = () => {
        try{
            authAPIInstance.hardDeleteAccount()
            .then(res => {
                console.log(res);
                logout();
            })
            .catch(err => {
                console.log(err);
            })
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div className="col-xl-12">
            <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center ">
                    <h6 className="mb-0 fw-bold">Account</h6>
                </div>
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <span className="fw-bold">Delete your account.</span>
                            <span className="text-muted small">
                                This action is irreversible.
                            </span>
                        </div>
                        <button className="btn btn-danger" onClick={() => setShowModal(true)}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your account?</p>
                    <p>This action is irreversible.</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
                    <button className="btn btn-danger" onClick={handleDelete}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeleteAccount;