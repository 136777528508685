import React, { useEffect, useRef, useState } from "react";
import './index.css';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import TopLeaderboard from "./Components/TopLeaderboard";
import { topDataAPIInstance } from "../../apis/TopDataAPI";
import CharacterCardExplore from "./Components/CharacterCardExplore";
import FeaturedCharacters from "./Components/FeaturedCharacters";

export default function Explore() {
    const [characters, setCharacters] = useState([]);
    const [nsfw, setNsfw] = useState(false);
    const scrollableTopCreator = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const categories = [ 'Romance','Action','Drama','Fantasy','Anime/Webtoon', 'Game','Novel','Comedy','Animal','Horror', 'Others'];
    const [topCharacters, setTopCharacters] = useState([]);
    const [topCreators, setTopCreators] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [featuredCharacters, setFeaturedCharacters] = useState([]);
    const [relatedCharacters, setRelatedCharacters] = useState([]);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        fetchData();
        getCharacterLeaderboard();
        getCreatorsLeaderboard();
        fetchRelatedCharacters("Romance");
    }, []);

    const fetchData = async () => {
        try {
            const result = await topDataAPIInstance.getCharactersForExplore(false);
            //console.log("fetchDataresult : ", result);
            const chars = result?.data?.data?.data?.data;
            //console.log("fetchDatachars : ", chars);
            setCharacters(chars);

            let feat = [];
            chars.forEach(list => {
                feat.push(list.characters[0].aiCharacter);
            });

            setFeaturedCharacters(feat);
        } catch (error) {
            console.error("error", error);
        }
    };

    const getCharacterLeaderboard = async () => {
        try{
            const result = await topDataAPIInstance.getCharacterLeaderboard(nsfw, 1);
            const charLeaderboard = result.data.data.data.data;
            setTopCharacters(charLeaderboard);
        } catch (error) {
            console.error(error);
        }
    }

    const getCreatorsLeaderboard = async () => {
        try {
            const result = await topDataAPIInstance.getCreatorsLeaderboard(nsfw, 1);
            let creatorsLeaderboard = result.data.data.data;
            creatorsLeaderboard = creatorsLeaderboard.filter(item => item.createdBy);
            setTopCreators(creatorsLeaderboard);
        } catch (error) {
            console.error(error);
        }
    }

    const getCharactersByCategory = (category) => {
        if (!Array.isArray(characters)) return [];
        const categoryData = characters.find(item => item?.category === category);
        //console.log("categoryDatacharacters : ", characters);
        return categoryData?.characters ?? [];
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setShowMore(false);
        fetchRelatedCharacters(category);
    }

    const fetchRelatedCharacters = async (category) => {
        try {
            const response = await topDataAPIInstance.getCharactersByCategory(category);
            const characters = response?.data?.data?.data || [];

            setRelatedCharacters(characters);
        } catch (err) {
            console.error("Error fetching related characters:", err);
        }
    };

    const goToLeaderboard = () => {
        navigate("/leaderboard", {state: {characters: topCharacters, creators: topCreators}});
    }

    const handleNavigateToAiCharacter = (aiCharacterId) => {
        navigate(`/character/${aiCharacterId}`);
    }

    const handleNavigateToCreator = (username) => {
        navigate(`/@${username}`);
    }

    return (
        <div className="px-lg-6 py-lg-6 px-md-4 py-md-4 poppins-font overflow-auto w-100">
            <div className="d-flex flex-column flex-sm-row px-2 py-2 justify-content-between">
                <TopLeaderboard characters={topCharacters} creators={topCreators} goToLeaderboard={goToLeaderboard} handleNavigateToAiCharacter={handleNavigateToAiCharacter} handleNavigateToCreator={handleNavigateToCreator}/>
                <FeaturedCharacters featuredCharacters={featuredCharacters} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
            </div>
            <hr className="border-line"/>
            <div className="d-flex flex-row gap-4 px-8 overflow-auto top-creator-container mb-4" style={{overflowX: "scroll"}} ref={scrollableTopCreator}>
                {categories.map((category, index) => (
                    <button className={`explore-category-button ${selectedCategory === category ? "explore-category-button-selected" : ""}`} key={index} onClick={() => handleCategoryClick(category)}>
                        {category}
                    </button>
                ))}
            </div>
            <div className="d-flex flex-column px-2 justify-content-center align-items-center" >
                {!showMore && (
                    <>
                        <div className="d-flex flex-wrap gap-4">
                            {getCharactersByCategory(selectedCategory).map((character, index) => (
                                <CharacterCardExplore key={index} character={character.aiCharacter} handleNavigateToAiCharacter={() => handleNavigateToAiCharacter(character?.aiCharacter?._id)} />
                            ))}
                        </div>
                        <button className="explore-category-button explore-category-button-selected" onClick={() => setShowMore(true)}>
                            See All
                        </button>
                    </>
                )}
                {showMore && (
                    <div className="d-flex flex-wrap gap-4">
                        {relatedCharacters.map((character, index) => (
                            <CharacterCardExplore key={index} character={character?.aiCharacter} handleNavigateToAiCharacter={() => handleNavigateToAiCharacter(character?.aiCharacter?._id)} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}