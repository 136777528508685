export const imagePathConfig = {
    couponImagePath: "/images/coupon_photos",
    schedueledImagePath: "/images/schedueled_photos",
    merchantStoreImagePath: "/images/store_photos",
    userImagePath: "/images/profile_photos",
    kycImagePath: "/images/kyc_photos",
    aiCharacterImagePath: "/images/ai_characters",
    messageAudioPath: '/audios/message_audios',
    seriesImagePath: '/images/series_photos',
    storychatImagePath: '/images/storychat_photos',
};

export const ethLiveNetworks = [
    {
        label: "Mainnet",
        value: "mainnet"
    },
    {
        label: "Polygon Mainnet",
        value: "polygon"
    },
    {
        label: "Binance Smart Chain",
        value: "bsc"
    }
];

export const ethTestNetworks = [
    {
        label: "Goerli Test Network",
        value: "goerli"
    },
    {
        label: "Polygon Mumbai",
        value: "mumbai"
    },
    {
        label: "Binance Smart Chain Testnet",
        value: "bsctestnet"
    }
];

export const btcNetworks = [
    {
        label: "Bitcoin",
        value: "bitcoin"
    },
    {
        label: "Bitcoin Testnet",
        value: "testnet"
    }
];

export const blockchains = [
    {
        completeChainName: "Ethereum",
        value: "ETH"
    },
    {
        completeChainName: "Bitcoin",
        value: "BTC"
    }
];

export const tokenTypes = {
    ERC20: "erc20",
    POINT: "point",
    BTC: "btc",
    NATIVE_ETH: "native_eth"
};

export const config = {
    localhost: {
      wsUrl: 'ws://localhost:8080',
      host: 'http://localhost:8080',
      baseApiUrl:'http://localhost:8080/api/v1',
      stripePublicKey: 'pk_test_51OKsoSGqnL3c6H5BWVsVNP25Uq3o4PRbcRr0tPW8enmAFyeDtKGRZcgOyMLObgyGMs4ChlmRiYan4vLUZXVXUwEA00AaHuAKNH',//'test with info@powerfan'
      // stripePublicKey: 'pk_test_51HIcosD8hSRNHIz91Yx7cITXeWX1hwWo4qTRemPDZfeU1cKxtEAStAlZbxhyvLxLCfS3vboxGhEAQaU3KeYluUaS00KGENyqaF',
      stripeTiers: {
        premiumPriceId: 'price_1OKuOOGqnL3c6H5BQai32jZ4',//'price_1OKWyWSFxJupK2D0iSebcsrT', //info@powerfan
      },
      googleAnalyticsId:  'G-2T1WNQBHWK',//'G-X8ZLBD3H42',//'G-1QNHJG22T8',
      googleAdSense: {
        adClient: "ca-pub-9814721835568486",
        adSlot: "9970139221"
      },
      paddlepay: {
        token: 'test_35c5bf47c6bc5d0e991bc99c8a7',
        premiumPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        gemPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        orangePriceId: 'pri_01hh05kmen71wwezgfkjffnf7g'
      }
    },
    dev: {
      wsUrl: 'wss://chainclip.app',
      host: 'https://chainclip.app',
      baseApiUrl: 'https://chainclip.app/api/v1',
      stripePublicKey: 'pk_test_51Nu92HIlIuKrLjDlDV4fAZTcgwCGsYkUJsLCY0TlSsDNZjHCZAOaq7uCOtYR0vKePfmeIqvrBLZvEGGwr88zpJM000kJdRiC6e',
      stripeTiers: {
        premiumPriceId: 'price_1OKWyWSFxJupK2D0iSebcsrT', //info@powerfan
      },
      googleAnalyticsId: 'G-2T1WNQBHWK',//'G-1QNHJG22T8',
      googleAdSense: {
        adClient: "ca-pub-9814721835568486",
        adSlot: "9970139221"
      },
      paddlepay: {
        token: 'test_35c5bf47c6bc5d0e991bc99c8a7',
        premiumPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        gemPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        orangePriceId: 'pri_01hh05kmen71wwezgfkjffnf7g'
      }
    },
    prod: {
      wsUrl: 'wss://orangepoint.app',
      host: 'https://orangepoint.app',
      baseApiUrl: 'https://orangepoint.app/api/v1',
      stripePublicKey: 'pk_live_51Nu92HIlIuKrLjDl3IRs8vRbmX5vqQSpGb4gv9B63mdg8xtcBRK56wIKUpch7BUE5r6z9xxHBrbmI8uz46vCp9Dy00OO0dEl9T',
      stripeTiers: {
        premiumPriceId: 'price_1OLyHJIlIuKrLjDl3XFbrH9v', //info@powerfan $5 
      },
      googleAnalyticsId: 'G-2T1WNQBHWK',//'UA-291654255-1',//'G-1QNHJG22T8',
      googleAdSense: {
        adClient: "ca-pub-9814721835568486",
        adSlot: "9970139221"
      },
      paddlepay: {
        token: 'test_35c5bf47c6bc5d0e991bc99c8a7',
        premiumPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        gemPriceId: 'pri_01hfg3bkmzg2t18h9v7vjpa6v8',
        orangePriceId: ''
      }
    },
    environment: 'prod', //release march 22 production 
  };

  export const theme = {
    color: '#FF731D'
  }