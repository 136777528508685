import { NO_IMAGE } from "../../utils/images";
import Profile from "../../assets/images/profile_av.svg";

function Avatar({ avatarUrl = null, name = "", className = '', onClick = () => {} }) {

    const handleLoadError = (e) => {
        e.target.src = Profile;
    }

    return avatarUrl && avatarUrl !== '' ? (
        <img onClick={onClick} onError={handleLoadError} className={`avatar rounded ${className}`} src={avatarUrl} alt={name} />
    ) : (
        <div onClick={onClick} className={`avatar rounded no-thumbnail ${className}`}>{name[0] }</div>
    );
}

export default Avatar;
