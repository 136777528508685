import "./index.css";
import Avatar from "../Comman/Avatar";
import { getSchedueledImageUrl } from "../../utils/images";
import DefaultImage from "../../../src/assets/images/splash.png"

export default function SliderChatBubble({message, imageUrl}) {
    const sentByUser = message?.sentByUser;
    const msg = typeof message?.messageContent?.encrypted_text === 'string' ? message.messageContent.encrypted_text : 'Invalid message content';
    const parts = msg.split(/(\*[^*]+\*)/).filter(part => part.trim() !== '');
    const maxLines = 3;
    let displayedParts = [];
    let lineCount = 0;

    for (const part of parts) {
        if (lineCount >= maxLines) break;
        displayedParts.push(part);
        lineCount += part.split(' ').length > 0 ? 1 : 0;
    }
    
    const shouldShowEllipsis = lineCount >= maxLines;
    
    return (
        <div className={`d-flex gap-2 mb-4 ${sentByUser ? 'flex-row-reverse' : 'flex-row'}`}>
            <Avatar avatarUrl={imageUrl} className="sm br-50" />
            <Avatar avatarUrl={DefaultImage} className="sm br-50 defaultImageBg" />
            <div className={`d-flex ${sentByUser ? 'user-message' : 'ai-message'}`}>
                <span className="msg-content">
                    {displayedParts.map((part, index) => {
                        const isActionLine = part.startsWith('*') && part.endsWith('*');
                        return (
                            <span
                                key={index}
                                className={isActionLine ? "msg-content-action" : ""}
                            >
                                {part}
                            </span>
                        );
                    })}
                    {shouldShowEllipsis && <span>...</span>}
                </span>
                {message?.imageUrl && 
                <div style={{ width: "100px", height: "100px", backgroundColor: '#A8A8A84D'}}>
                    <img 
                        style={{ width: "100%", height: "100%" }}
                        src={getSchedueledImageUrl(message?.imageUrl)} 
                        alt="image-prompt" 
                    />
                </div>}
            </div>
        </div>
    )
}
