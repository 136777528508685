
import React, { useEffect, useRef, useState } from "react";
import "../../index.css";
import "./socials.css";

const SocialMediaTab = ({ handleSocialsToggle, buttonContent, onSubmit, handleDeleteCoupon, isSubmitting = false, isDeleting = false, userInfo }) => {

    const handleLinkClick = (link) => {
        window.open(link, '_blank');
    }
    return (
        <div className="profiles-tab-container w-100">
            <div className="social-media-container">
            <h2>Social Media Links</h2>
            <div className="connections social-media-links-section">
                    
                {userInfo.socialLinks && userInfo.socialLinks.length > 0 
                ? 
                (
                    userInfo.socialLinks.map((linkObject, index) => (
                        <div onClick={() => handleLinkClick(linkObject.link)} className="connection-item mt-3" key={linkObject?.label + linkObject?.value}>
                            <div className="connection-info">
                                <div
                                className="d-flex flex-column w-100 justify-content-center align-items-left position-relative"
                                key={index}
                                >
                                    <p className="social-title-label"><strong>{linkObject?.label}</strong></p>
                                    <p className="social-link-link">{linkObject?.link}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                <div>
                    <h6>There are no social links to show</h6>
                </div>
                )}

            </div>
            </div>
        </div>
        

    );
};

export default SocialMediaTab;