import React from 'react';
import './index.css';
import { Spinner } from 'react-bootstrap';

const DeleteStorychatModal = (props) => {
    const {
        setShowDeleteStorychatModal,
        handleDeleteStorychat,
        loading,
    } = props;

    return (
        <div className='delete-storychat-modal poppins-font'>
            <span className='font-weight-bold fs-5'>Delete Storychat</span>
            <span className='text-center'>Deleting storychat will delete all associated chats. Are you sure you want to delete this storychat?</span>
            <div className='d-flex flex-row justify-content-between gap-2 w-100'>
                <button className="back-button flex-grow-1" onClick={() => setShowDeleteStorychatModal(false)} disabled={loading}>
                    <div className="back-button-content">
                        <span>Cancel</span>
                    </div>
                </button>
                <button className="next-button violet-gradient-btn flex-grow-1" onClick={handleDeleteStorychat} disabled={loading}>
                    <div className="next-button-content">
                        <span>{loading ? 'Deleting...' : 'Delete'}</span>
                        {loading && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                    </div>
                </button>
            </div>
        </div>
    );
}

export default DeleteStorychatModal;