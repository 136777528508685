import React, {useState} from "react";
import "./chatSidebarStyling.css";
import { useSelector } from "react-redux";
import { getUserImageUrl, getPersonaImageUrl } from "../../../utils/images";
import AddImageIcon from "../../../assets/icons/chatsidebar/addImage.png";
import MySeriesIcon from "../../../assets/icons/chatsidebar/mySeries.png";
import CreateStorychatIcon from "../../../assets/icons/chatsidebar/createStorychat.png";
import { AllPersonasModal } from "../../Persona/AllPersonasModal";
import AddUserIcon from "../../../assets/icons/chatsidebar/addUser.png";
import EditUserIcon from "../../../assets/icons/chatsidebar/editUser.png";
import defaultImage from '../../../assets/images/splash.png';

export function ChatSidebar(props) {
    const { 
        setShowAllPersonasModal, 
        setShowCreateNewPersonaModal, 
        setShowEditPersonaModal, 
        setImageGeneratorModalPop, 
        setShowMySeries,
        setShowViewStorychats,
        setShowCreateStorychat,
        isGroup 
    } = props;
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const lastUsedPersona = useSelector(state => state.persona.currentPersona);

    const chatSidebarOptions = [
        {
            title: "Create Storychat",
            icon: CreateStorychatIcon,
            onClick: () => {
                setShowCreateStorychat(true);
            },
            show: !isGroup,
        },
        {
            title: "View All Storychats",
            icon: CreateStorychatIcon,
            onClick: () => {
                setShowViewStorychats(true);
            },
            show: !isGroup,
        },
        {
            title: "Create Persona",
            icon: AddUserIcon,
            onClick: () => {
                console.log('create persona');
                setShowCreateNewPersonaModal(true);
            },
            show: !isGroup,
        },
        {
            title: "Edit Persona",
            icon: EditUserIcon,
            onClick: () => {
                setShowEditPersonaModal(true);
            },
            show: !isGroup,
        },
    ]   
    
    return (
        <div className={`chat-sidebar border-end poppins-font ${toggleSidebar ? "chat-sidebar-open" : "chat-sidebar-close"}`}>
            <button
                type="button"
                className={`chat-sidebar-back-btn ${toggleSidebar ? "" : "chat-sidebar-hidden-back-btn"}`}
                onClick={() => {setToggleSidebar(!toggleSidebar)}}
            >
                <i className={`${toggleSidebar ? "icofont-curved-right" : "icofont-curved-left"}`}></i>
            </button>
            <hr className="my-0"></hr>
            <div className="chat-sidebar-options-container">
                <button className="btn violet-gradient-btn py-2 fs-8 w-100 mt-2" onClick={() => setImageGeneratorModalPop(true)}>
                    <img src={AddImageIcon} alt="Generate Image" className="chat-sidebar-options-icon" />
                    <span className="ms-2">Generate Image</span>
                </button>
                {chatSidebarOptions
                .filter(option => option.show)
                .map((option, index) => (
                    <button
                        key={index}
                        className="chat-sidebar-options mt-2"
                        onClick={option.onClick}
                    >
                        <div className="chat-sidebar-options-content">
                            <img src={option.icon} alt={option.title} className="chat-sidebar-options-icon" />
                            <span className="ms-2">
                                {option.title}
                            </span>
                        </div>
                    </button>
                ))}
                <button className="chat-sidebar-options mt-4" onClick={() => setShowAllPersonasModal(true)}>
                    <div className="chat-sidebar-options-content">
                        <img 
                            src={lastUsedPersona ? getPersonaImageUrl(lastUsedPersona) : ""} 
                            alt="current persona" 
                            className="chat-sidebar-options-persona-image" 
                            onError={(e) => {
                                e.target.src = defaultImage;
                            }}
                        />
                        <span className="ms-2">Switch Persona</span>
                    </div>
                </button>
                <button
                    className="chat-sidebar-options mt-2"
                    onClick={() => setShowMySeries(true)}
                >
                    <div className="chat-sidebar-options-content">
                        <img src={MySeriesIcon} alt={"my series"} className="chat-sidebar-options-icon" />
                        <span className="ms-2">
                            My Series
                        </span>
                    </div>
                </button>
            </div>
        </div>
    )
}
