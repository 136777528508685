import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    currentPersona: null,
    allMyPersonas: [],
};

const PersonaReducer = createSlice({
    name: "personaReducer",
    initialState,
    reducers: {
        setCurrentPersona: (state, action) => {
            state.currentPersona = action.payload.data;
        },
        setAllMyPersonas: (state, action) => {
            state.allMyPersonas = action.payload.data;
        },
    },
});

export const { 
    setCurrentPersona,
    setAllMyPersonas,
} = PersonaReducer.actions;

export default PersonaReducer;