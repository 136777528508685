import React, { useState } from "react";
import { useLocation } from "react-router";
import { getAiCharacterImageUrlV2, getUserImage } from "../../utils/images";
import "./leaderboardStyle.css";
import { CharacterCardLeaderboard, CreatorCardLeaderboard } from "./Components/TopLeaderboard";
import platformPic from "../../assets/images/platform.png";
import DefaultImage from "../../assets/images/splash.png";
import trophy from "../../assets/icons/trophy.png";
import diamond from "../../assets/icons/diamond.png";
import { useNavigate } from "react-router-dom";

export default function LeaderboardDisplay(){
    const { state } = useLocation();
    const topCharacters = state?.characters;
    const topCreators = state?.creators;
    const rewardsLabels = ["1st", "2nd", "3rd", "4th ~ 10th", "11th ~ 20th", "21th ~ 30th"];
    const navigate = useNavigate();
    const [displayList, setDisplayList] = useState("Characters");
    const category = ["Characters", "Creators"];
    const topCategory = ["Characters", "Creators"];
    const [displayTopCategory, setDisplayTopCategory] = useState("Characters");

    const handleNavigateToUser = (userId) => {
        navigate(`/@${userId}`);
    }

    const handleNavigateToAiCharacter = (aiCharacterId) => {
        navigate(`/character/${aiCharacterId}`);
    }

    const CharacterItemLeaderboard = ({character, index}) => {
        return (
            <div className="leaderboard-item-container">
                <div className="d-flex flex-row align-items-center justify-content-between" onClick={() => handleNavigateToAiCharacter(character?.aiCharacter?._id)}>
                    <span>{index + 4}</span>
                    <div className="leaderboard-item-avatar-container">
                        <img src={getAiCharacterImageUrlV2(character?.aiCharacter)} alt={character?.aiCharacter?.characterName} className="leaderboard-item-avatar" onError={(e) => {
                            e.target.src = DefaultImage;
                        }}/>
                    </div>
                    <span>{character?.aiCharacter?.characterName}</span>
                </div>
                <span className="leaderboard-item-points">{character?.publishedChatsCount} pts</span>
            </div>
        )
    }

    const CreatorItemLeaderboard = ({creator, index}) => {
        console.log(creator);
        return (
            <div className="leaderboard-item-container">
                <div className="d-flex flex-row align-items-center justify-content-between" onClick={() => handleNavigateToUser(creator?.createdBy?.username)}>
                    <span>{index + 4}</span>
                    <div className="leaderboard-item-avatar-container">
                        <img src={getUserImage(creator?.createdBy)} alt={creator?.createdBy?.username} className="leaderboard-item-avatar" onError={(e) => {
                            e.target.src = DefaultImage;
                        }}/>
                    </div>
                    <span>{creator?.createdBy?.username}</span>
                </div>
                <span className="leaderboard-item-points">{creator?.characterCount + creator?.followerCount || 0} pts</span>
            </div>
        )
    }
    
    console.log('display list', displayList);
    
    return (
        <div className="px-lg-6 py-lg-6 px-md-4 py-md-4 poppins-font overflow-auto w-100">
            <div className="d-flex flex-column flex-md-row mb-4 justify-content-between">
                <div className="leaderboard-display-container w-100">
                    <div className="top-leaderboard-category-button-container">
                        {topCategory.map((item, index) => (
                            <button key={index} className={displayTopCategory === item ? "top-leaderboard-category-button-selected" : "top-leaderboard-category-button"} onClick={() => setDisplayTopCategory(item)}>{item}</button>
                        ))}
                    </div>
                    {displayTopCategory === "Characters" ? (
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex flex-row">
                                <div className="char-card char-second" >
                                    <CharacterCardLeaderboard character={topCharacters[1]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                                </div>
                                <div className="char-card char-first">
                                    <CharacterCardLeaderboard character={topCharacters[0]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                                </div>
                                <div className="char-card char-third">
                                    <CharacterCardLeaderboard character={topCharacters[2]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                                </div>
                            </div>
                            <div className="platform-pic-container">
                                <img src={platformPic} alt="platform" className="platform-pic" />
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex flex-row">
                                <div className="char-card char-second" >
                                    <CreatorCardLeaderboard creator={topCreators[1]} handleNavigateToCreator={handleNavigateToUser}/>
                                </div>
                                <div className="char-card char-first">
                                    <CreatorCardLeaderboard creator={topCreators[0]} handleNavigateToCreator={handleNavigateToUser}/>
                                </div>
                                <div className="char-card char-third">
                                    <CreatorCardLeaderboard creator={topCreators[2]} handleNavigateToCreator={handleNavigateToUser}/>
                                </div>
                            </div>
                            <div className="platform-pic-container">
                                <img src={platformPic} alt="platform" className="platform-pic" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="rewards-container w-100">
                    <h1 className="glorify-font">Rewards</h1>
                    <div className="d-flex flex-column gap-2">
                        {rewardsLabels.map((label, index) => (
                            <RewardsItem key={index} label={label} index={index} />
                        ))}
                    </div>
                </div>
            </div>
            <h1 className="glorify-font">Leaderboards</h1>
            <div>
                {category.map((category, index) => (
                    <button key={index} className={displayList===category ? "leaderboard-category-button-selected" : "leaderboard-category-button"} onClick={() => {setDisplayList(category)}}>
                        <span>{category}</span>
                    </button>
                ))}
            </div>
            <hr className="border-line" />
            {displayList === "Characters" ? (
                <div className="d-flex gap-4 flex-wrap" style={{overflowY: "scroll"}}>
                    {topCharacters.slice(3).map((character, index) => (
                        <CharacterItemLeaderboard key={index} character={character} index={index} />
                    ))}
                </div>
            ) : (
                <div className="d-flex gap-4 flex-wrap" style={{overflowY: "scroll"}}>
                    {topCreators.slice(3).map((creator, index) => (
                        <CreatorItemLeaderboard key={index} creator={creator} index={index} />
                    ))}
                </div>
            )}
        </div>
    )
}

const RewardsItem = ({ label, index }) => {
    return (
        <div className={index > 2 ? "rewards-item-container-two" : "rewards-item-container-one"}>
            <div className="d-flex flex-row align-items-center gap-2">
                {index < 3 ? <img src={trophy} alt="trophy" className="trophy-icon" /> : <span className="period-icon"></span>}
                <span>{label}</span>
            </div>
            <div className="d-flex flex-row align-items-center gap-3">
                <span>+500</span>
                <img src={diamond} alt="diamond" className="diamond-icon" />
            </div>
        </div>
    )
}