import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPersonaImageUrl } from '../../utils/images';
import { setCurrentPersona } from '../../Redux/Reducers/PersonaReducer';
import defaultImage from '../../assets/images/splash.png';

export function AllPersonasModal() {
    const allMyPersonas = useSelector(state => state.persona.allMyPersonas);
    const currentPersona = useSelector(state => state.persona.currentPersona);
    const dispatch = useDispatch();

    const handlePersonaSwitch = (persona) => {
        dispatch(
            setCurrentPersona({data: persona})
        );
    }

    console.log('allMyPersonas', allMyPersonas);

    return (
        <div className='all-persona-modal-container poppins-font'>
            <span className='fw-bold fs-6'>My Personas</span>
            {allMyPersonas.map((persona) => (
                <div key={persona.id} className='persona-card'>
                    <div className='d-flex flex-row'>
                        <img src={getPersonaImageUrl(persona)} alt={persona.personaName} className='persona-card-image' onError={(e) => {
                            e.target.src = defaultImage;
                        }} />
                        <div className='persona-card-details'>
                            <span className='fw-medium fs-7'>{persona.personaName}</span>
                            <span className='persona-card-description'>{persona.description || 'No description available.'}</span>
                        </div>
                    </div>
                    <button className='persona-card-switch-button' onClick={() => handlePersonaSwitch(persona)}>
                        {currentPersona._id === persona._id ? <i className="fa fa-check-circle persona-selected-icon"></i> : <i className='fa fa-circle-o persona-unselected-icon'></i>}
                    </button>    
                </div>
            ))}
        </div>
    );
}