import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    aiProxy: 'defaultReverseProxy',
};

const ProxyReducer = createSlice({
    name: "proxyReducer",
    initialState,
    reducers: {
    }
});

export const {
} = ProxyReducer.actions;

export default ProxyReducer;
