import axios from "axios";
import { config } from "../utils/config";
import { httpTokenInterceptor } from "./common";

class TopDataAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/topdata"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getCharactersByCategory(category, adultContent = false, page = 1){
        return this.axios.get(`/characters_by_category/${category}?adultContent=false&page=${page}`);
    }

    getCharacterLeaderboard(adultContent = false, page = 1) {
        return this.axios.get(`/top_characters/?adultContent=${adultContent}&page=${page}`);
    }

    getCreatorsLeaderboard(adultContent = false, page = 1) {
        return this.axios.get(`/top_creators/?adultContent=${adultContent}&page=${page}`);
    }

    getCharactersForExplore(adultContent = false){
        return this.axios.get(`/characters_for_explore/?adultContent=${adultContent}`);
    }
}

export const topDataAPIInstance = new TopDataAPI();

export default TopDataAPI;
