/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessagesOfChat } from "../../../Redux/Reducers/ChatReducer";
import { MessageComments } from "../../../Screen/AICharacterScreen/components/Comments";
import { AIApiInstance } from "../../../apis/AIAPI";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { ClientCharAPIInstance } from "../../../apis/ClientCharApi";
import { PointRewardApiInstance } from "../../../apis/PointRewardApi";
import { socketConstants } from "../../../apis/SocketApis";
import { NewSocketService } from "../../../apis/SocketApis/NewSocketService";
import { readCookie } from "../../../utils/cookie";
import { removeChatPrefix, removeYouPrefix } from "../../../utils/customCharacter";
import { generateRandomId } from "../../../utils/id";
import { getAiCharacterImageUrl, getAiCharacterImageUrlV2 } from "../../../utils/images";
import Avatar from "../../Comman/Avatar";
import { StatusBadge } from "../../Comman/StatusBadge";
import GoogleAd from "../../GoogleAd/GoogleAd";
import { Modal } from '../../Modal/Modal';
import RewardAdBox from "../../RewardAdBoxes/RewardAdBox";
import { groupcChatSocketHelper } from './Helpers/GroupChatSocketHelper';
import { AutoTranslateModal, ImageGeneratorModalPop, SelectSpicyAiModal } from './Popup/CharChatBoxModals';
import PublishChatBody from "./PublishChatBody";
import "./chatbox.css";
import { GroupChatItem } from "./GroupChatItem";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import GroupCreateModal from "../Chat/GroupCreateModal";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import "./chatbox.css";
import voiceIcon from "../../../assets/icons/voice.svg";
import updateIcon from "../../../assets/icons/update.svg";
import rolePlayIcon from "../../../assets/icons/asterisk.svg";
import chatIcon from "../../../assets/icons/chat.svg";
import aiIcon from "../../../assets/icons/ai.svg";
import translateIcon from "../../../assets/icons/translate.svg";
import deleteIcon from "../../../assets/icons/bin.svg";
import newChapterIcon from "../../../assets/icons/new-box.svg";
import viewIcon from "../../../assets/icons/view-alt.svg";
import { ChatType } from "./types";

let checkLastMessageIsSentInterval = null;

const initialStates = {
    avatar: "",
    chatName: "",
    message: "",
    roleplayMessage: "",
    character: null,
    isChatHistoryFetching: false,
    isCharacterReady: true,
    selectedChatIndex: null
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "50vw",
        height: "70vh",
        maxHeight: "80vh",
        backgroundColor: "#883bd0",
        zIndex: "1",
    }
};

const membersModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: "50vh",
        width: "50vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#57389C"
    },
};


function GroupChatBox({user, chatId, toggleDrawer, status, selectedType, selectedUserId = "", currentGroupChat = {}, setCurrentGroupChat = () => {}, imageGeneratorModalPop, setImageGeneratorModalPop }) {
    
    let { sendMessage, lastMessage, readyState, getWebSocket } = NewSocketService("CharacterChatBox");
    let ws = getWebSocket();
    const [currentChar, setCharData] = useReducer((prev, next) => {
        return { ...prev, ...next };
    }, initialStates);
    const [messages, setMessages] = useState([]);
    const [websocket, setWebSocket] = useState(null);
    const [isChatPublic, setIsChatPublic] = useState(false);
    const [askPublishChat, setAskPublishChat] = useState({
        response: null,
        showModal: false
    });
    const [charReplying, setCharReplying] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    const [showRewardAdModal, setShowRewardAdModal] = useState(false);
    const [autoTranslate, setaAutoTranslate] = useState(true);
    const [translateModal, setTranslateModal] = useState(false);
    const [membersModal, setMembersModal] = useState(false);
    const [selectSpicyAiPopup, setSelectSpicyAiPopup] = useState(false);
    const [spicyAiModels, setSpicyAiModels] = useState([]);
    const [selectedSpicyAiModel, setSelectedSpicyAiModel] = useState(null);
    const [rolePlay , setRolePlay ] = useState(false);
    const [rolePlayModal, setRolePlayModal] = useState(false);
    const [subscription, setSubscription] = useState({ price: 0, subscription_status: false, expired: false });
    const [inProgressImage, setInProgressImage] = useState({percent: 0, current_image: ""});
    const [imgGenButtonDisabled, setImgGenButtonDisabled] = useState(false);
    const [isContainerVisible, setIsContainerVisible] = useState(false);
    const [isRoleplay, setIsRoleplay] = useState(false);
    const autoTranslateDisabled = useMemo(() => {
        return user?.spicyChatEnabled;
    }, [user?.spicyChatEnabled]);
    const chatDisabledSubscription = useMemo(() => {
        if(subscription?.price > 0){
            return subscription.expired || !subscription.subscription_status && selectedType!== 'client';
        } else {
            return false;
        }
    }, [subscription]);
    const currentUser = useSelector(selectCurrentUser)
    const token = readCookie('token');
    const messageTextAreaRef = useRef(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
      } = useSpeechRecognition();

    const attachmentFileRef = useRef(null);

    const dispatch = useDispatch();
    const bottomRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('currentChar', currentChar);
    }, [currentChar]);

    useEffect(() => {
        //check or infinite loop
        console.log('currentGroupChat', currentGroupChat);
        if(currentGroupChat?.name) {
            console.log('currentGroupChat?.aiMembers?.[0]?._id', currentGroupChat?.aiMembers?.[0]?._id);
            setCharData({
                chatName: currentGroupChat?.name,
                avatar: getAiCharacterImageUrlV2(currentGroupChat?.aiMembers?.[0]),
                _id: currentGroupChat?.aiMembers?.[0]?._id
            });
        }
    }, [currentGroupChat]);

    const toggleAskPublicModal = (boolValue) => {
        setAskPublishChat((prev) => {
            return { ...prev, showModal: boolValue };
        });
    };

    const toggleTranslateModal = (boolValue) => {
        console.log("toggleTranslateModal");
        setTranslateModal(boolValue);
    };

    const toggleContainerVisibility = () => {
        setIsContainerVisible(!isContainerVisible);
    };

    const toggleSelectSpicyAiPopup = (boolValue) => {
        console.log("toggleSelectSpicyAiPopup");
        setSelectSpicyAiPopup(boolValue);
    };

    const toggleRolePlayModal = (boolValue) => {
        console.log("RolePlayModal");
        setRolePlayModal(boolValue);
    };

    const handleDeleteChat = () => {
       const confirm =  window.confirm('Are you sure you want to delete all messages?');
       if(confirm && chatId){
        AIApiInstance.deleteCharacterChat(chatId)
        .then(_ => {
            alert('Messages deleted successfully.');
            setMessages([]);
        })
        .catch(console.error)
       }
    };

    const handleUpdateGroupMembers = () => {
        setMembersModal(true);
    };

    const respondAskPublicModal = (boolValue) => {
        setAskPublishChat((prev) => {
            return { ...prev, response: boolValue };
        });
    };

    const resetAskPublicModal = () => {
        setAskPublishChat((prev) => {
            return { ...prev, response: null };
        });
    };

    const getGroupChatHistory = useCallback(async () => {
        if (!chatId || chatId === "undefined") {
            return;
        }
        const { messages } = (await AIApiInstance.getGroupChatMessages(chatId))?.data?.data;
        // const chatHistory = messages.map((message) => {
        //     return { ...message, originalMessage: "" };
        // });
        // let avatarUrl =  '';
        // if(Array.isArray(aiCharacter?.avatarUrls) && aiCharacter?.avatarUrls?.[0]){
        //     avatarUrl = aiCharacter?.avatarUrls[0]
        // } else {
        //     avatarUrl = aiCharacter?.avatarUrl;
        // }
        setMessages([...messages]);
        // dispatch(
        //     setMessagesOfChat({
        //         recipient_id: chatId,
        //         messages: chatHistory
        //     })
        // );
    }, [chatId]);

    // const getClientChatHistory = useCallback(async () => {
    //     if (!chatId || chatId === "undefined") {
    //         return;
    //     }
    //     const { messages, aiCharacter } = (await ClientCharAPIInstance.getClientCharacterChatHistory(selectedUserId, chatId, 1))?.data?.data;
    //     const chatHistory = messages.map((message) => {
    //         return { ...message, originalMessage: "" };
    //     });
    //     let avatarUrl =  '';
    //     if(Array.isArray(aiCharacter?.avatarUrls) && aiCharacter?.avatarUrls?.[0]){
    //         avatarUrl = aiCharacter?.avatarUrls[0]
    //     } else {
    //         avatarUrl = aiCharacter?.avatarUrl;
    //     }
    //     setCharData({
    //         avatar: avatarUrl,
    //         chatName: aiCharacter.characterName,
    //         character: aiCharacter,
    //         isChatHistoryFetching: false
    //     });
    //     setMessages([...chatHistory]);
    //     dispatch(
    //         setMessagesOfChat({
    //             recipient_id: chatId,
    //             messages: chatHistory
    //         })
    //     );
    // }, [chatId, selectedUserId]);

    const buildPrompt = useCallback(
        (chatHistory) => {
            const { character } = currentChar;

            const concatLastTenMessages = (messages) => {
                const temp = [...messages];
                return temp.reduce(
                    (prev, current) =>
                        prev +
                        (current.sentByUser ? "You:" : `${currentChar.character?.characterName}:`) +
                        removeYouPrefix(current.messageContent) +
                        "\n",
                    ""
                );
            };

            const prompt = `[Character: ${character?.characterName}; species: Human; age 24; gender: ${
                character?.gender
            }; physical appearance: ${character?.physicalAppearance}; likes: ${character?.likes}; description: ${
                character?.description
            }]\n[The following is an interesting chat message and roleplay log between You and ${character?.characterName}.]\n\n${
                character?.characterName
            }:${removeChatPrefix(character?.firstMessage)}\n`;

            return prompt + concatLastTenMessages(chatHistory || messages);
        },
        [currentChar.character]
    );

    const renderChats = useCallback(
        () =>
            messages
                .map((message, i) => (
                    //console.log("imageUrl: ",message?.imageUrl),
                    <div key={`chat-item-${i}`}>
                            <GroupChatItem
                                avatar={currentChar.avatar}
                                chatName={currentChar.chatName}
                                message={message}
                                index={i}
                                onSelect={(index) => setCharData({ selectedChatIndex: index })}
                                onUpdateMessage={handleUpdateMessage}
                                onRegenerateMessage={() => handleRegenerateMessage(i, message)}
                                chatId={chatId}
                                currentCharacter={currentChar?.character}
                            />
                    </div>
                )),
        [messages]
    );

    const addStarRolePlayToString = () => {
        if (currentChar.message === "") {
            setCharData({ message: "*" });
        } else {
            setCharData({ message: currentChar.message + "*" });
        }

        messageTextAreaRef.current.focus();
    };

    const handleSendMessage = async () => {
        const { message, character, roleplayMessage } = currentChar;
        const messageId = generateRandomId();
    
        const formattedMessage = isRoleplay
            ? `*${roleplayMessage.replace(/\*/g, '')}*`
            : message;
        
        setMessages((prev) => {
            let temp = [...prev];
            temp.push({
                sentByUser: true,
                _id: messageId,
                createdAt: Math.floor(new Date().getTime() / 1000),
                userId: currentUser?.userId,
                message: formattedMessage,
                originalMessage: message
            });
            return temp;
        });
    

        setCharData({
            message: ""
        });
    
        if (selectedType === "client") {
            sendMessageToUserAsAI(formattedMessage, messageId);
        } else {
            sendMessageToGroup(formattedMessage, messageId, character);
        }
        setIsRoleplay(false);
    };

    const sendMessageToGroup = (message, messageId, character) => {
        setCharReplying(true);
        // if (user?.spicyChatEnabled) {
            // const stringPrompt = buildPrompt(messages);
            // const newPrompt = `${stringPrompt}You: ${message}\n${character?.characterName}:`;
            console.log("user?.userLanguageCode,:", user?.userLanguageCode);
            const messageData = {
                type: socketConstants.CLIENT.GROUP_CHAT.MESSAGE,
                message,
                groupId: chatId,
                messageId: messageId,
                lang: user?.userLanguageCode,
            };
            if(readyState === 1) {
                console.log("ws.readyState: ",readyState);
                ws.send(JSON.stringify(messageData));
            } else {
                console.log("ws.readyState: ", readyState); 
                alert("Check your internet connection.");
            } 
    }

    const sendMessageToUserAsAI = (message, messageId) => {
        const messageData = {
            type: socketConstants.CLIENT.CUSTOM_AI.RESPONSE_FROM_REPLIER,
            targetUserId: messages[messages.length - 1]?.userId,
            response: message,
            customAIModelId: chatId,
            message_id: messageId,
            originalMessageId: ""
        };
        ws.send(JSON.stringify(messageData));
    }

    const handleUpdateSettingsClick = () => {
        navigate("/character-settings", {
            state: {
                aiCharacterId: chatId
            }
        });
    };

    const handleClickPublishChat = () => {
        toggleAskPublicModal(true);
    };

    const handleOnChangeTextArea = (event) => {
        setCharData({ message: event.target.value });
    };

    const handleOnChangeRoleplay = (event) => {
        setCharData({ roleplayMessage: event.target.value });
        setIsRoleplay(true);
    };

    const handleAvatarClick = () => {
        navigate(`/character/${chatId}`, { state: { character: currentChar?.character } });
    };

    const handleUpdateMessage = useCallback(
        (messageId, newMessage) => {
            const index = messages.findIndex((message) => message._id === messageId);
            if (index !== -1) {
                const newMessages = [...messages];
                newMessages[index].message = newMessage;
                setMessages(newMessages);
            }
        },
        [messages]
    );

    const handleRegenerateMessage = useCallback(
        (index, message) => {
            if (messages.length > 0) {
                const stringPrompt = buildPrompt(messages.slice(0, index));
                const newPrompt = `${stringPrompt}${currentChar?.character?.characterName}:`;
                const messageData = {
                    type: socketConstants.CLIENT.CUSTOM_AI.REGENERATE_RESPONSE,
                    prompt: newPrompt,
                    message: message.messageContent,
                    customAIModelId: chatId,
                    device: "mobile",
                    messageId: message._id
                };
                ws.send(JSON.stringify(messageData));
            }
        },
        [messages, currentChar?.character]
    );

    const checkLastMessageIsSent = useCallback(() => {
        try {
            const lastMessage = messages[messages.length - 1];
            const elapsed = new Date().getTime() - lastMessage.timeStamp * 1000;
            const threshold = 5 * 60 * 1000; // 5 minutes;
            if (elapsed > threshold) {
                setCharData({ isCharacterReady: true });
                clearInterval(checkLastMessageIsSentInterval);
            }
        } catch (error) {
            console.error(error);
        }
    }, [currentChar.isCharacterReady, messages]);

    useEffect(() => {
        const _autoTranslate = user?.userLanguageCode !== "en" ? true : false
        setaAutoTranslate(_autoTranslate);
    }, [user]);

    useEffect(() => {
        if (!ws || !currentChar.character) {
            return;
        }
        if (readyState === 1) {
            ws.send(
                JSON.stringify({
                    type: socketConstants.CLIENT.CUSTOM_AI.SUBSCRIBE,
                    customAIModelId: currentChar.character._id
                })
            );
        }  

    }, [chatId, ws, currentChar.character]);
    //Do not use  useEffect : getWebSocket() needs to be outside of useEffect for recconecting after server down.
    if(ws) { 
        ws.onmessage = (event) => {
            console.log("ws.onmessage  event:", event);
            handleIncomingChar(event?.data, chatId);
        };
    }

    const handleIncomingChar = (data, chatId ) => {
        let _data = JSON.parse(data);
            const eventData = _data;
            console.log("eventData: ", eventData.groupMessage);
            //characterSocketRecieverHelper is a helper function to handle all the events from socket.
            groupcChatSocketHelper(eventData, 
                selectedType, 
                setMessages, 
                setCharReplying, 
                user, 
                setCharData, 
                setSpicyAiModels,
                chatId,
                currentGroupChat, setInProgressImage, setImgGenButtonDisabled);
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, currentChar.message, currentChar.isCharacterReady]);

    useEffect(() => {
        //todo check if client-char chats or just user's ai character
        // if (selectedType === "client") {
        //     getClientChatHistory();
        // } else { //normal ai character
            getGroupChatHistory();
        // }
    }, [selectedType, selectedUserId, chatId]);

    useEffect(() => {
        if (askPublishChat.response) {
            AIApiInstance.publishGroupChat(chatId, !isChatPublic)
                .then((_) => {
                    alert(`Group chat is now ${isChatPublic ? "Private" : "Public"}.`);
                    setIsChatPublic((prev) => !prev);
                })
                .catch((e) => {
                    console.error(e);
                    alert(e?.response?.data?.message ?? "Something went wrong.");
                });
        }
        toggleAskPublicModal(false);
        resetAskPublicModal();
    }, [askPublishChat.response]);

    useEffect(() => {
        if(currentGroupChat?.isPublic){
            setIsChatPublic(true);
        }
    }, [currentGroupChat]);

    useEffect(() => {
        if (messages.length === 0) {
            return;
        }
        const lastMessage = messages[messages.length - 1];
        if (lastMessage.sentByUser && !currentChar.isCharacterReady) {
            checkLastMessageIsSentInterval = setInterval(checkLastMessageIsSent, 1000);
        }
        return () => {
            if (checkLastMessageIsSentInterval) {
                clearInterval(checkLastMessageIsSentInterval);
            }
        };
    }, [checkLastMessageIsSent]);

    useEffect(() => {
        if(transcript){
            setCharData({ message: transcript });
        }
    }, [transcript]);

    // useEffect(() => {
    //     if (selectedType === "ai") {
    //         AIApiInstance.getCharacterSubscriptionStatus(chatId)
    //             .then((res) => {
    //                 setSubscription(res.data.data);
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //             })
    //     }
    // }, [selectedType, chatId]);
    const { loading, chatName, avatar, isCharacterReady, selectedChatIndex } = currentChar;

    if (loading) {
        return (
            <div className="d-flex w-100 flex-grow-1 align-items-center justify-content-center">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }
    if (selectedChatIndex !== null) {
        return (
            <MessageCommentContainer
                message={messages[selectedChatIndex]}
                onBackPress={() => setCharData({ selectedChatIndex: null })}
            />
        );
    }

    const handleShowAd = () => {
        setShowAdModal(true);
        setTimeout(() => {
            setShowAdModal(false);
            setShowRewardAdModal(true);
        }, 5000);
    };

    const handlePrizeSelect = (points) => {
        setShowRewardAdModal(false);
        PointRewardApiInstance.redeemAdvertReward(token, points, currentChar?.character?._id)
        .then(_ => {
            alert(`You have won ${points} StoryChat points.`);
            dispatch({
                type: "UPDATE",
                payload: { user: { key: "orangePointBalance", value: user?.orangePointBalance + points } }
            });
        }).catch(e => {
            alert(e?.response?.data?.message ?? "Something went wrong.");
        });
    };

    const handleToggleTranslate = (e) => {
            setaAutoTranslate(e.target.checked);
    };

    const handleSelectedSpicyAi = (e) => {
        console.log("handleSelectedSpicyAi: ",e.target.checked);
    };

    const handleToggleRolePlay = (e) => {
        setRolePlay(e.target.checked);
        console.log("rolePlay: ",rolePlay); 
    };

    const handleSpicyModeChange = () => {
        const newPreference = user?.spicyChatEnabled ? false : true;
        authAPIInstance
            .updateSpicyChatPreference(newPreference)
            .then((_) => {
                dispatch({
                    type: "UPDATE",
                    payload: {
                        user: {
                            key: "spicyChatEnabled",
                            value: newPreference
                        }
                    }
                });
            })
            .catch(console.error);
    };

    const handleAttachImageClick = () => {
        attachmentFileRef.current.click();
    }

    const handleImageChange = (e) => {
        if (selectedUserId && chatId) {
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append("recipient_id", selectedUserId);
            formData.append("characterId", chatId);
            formData.append("file", file);

            ClientCharAPIInstance.sendImageMessageAsCharacter(formData)
                .then((res) => {
                    console.log("sendImageMessageAsCharacter: ", res);
                    setMessages((prev) => {
                        let temp = [...prev];
                        temp.unshift({
                            ...res?.data?.data,
                            messageContent: res?.data?.data?.messageContent?.encrypted_text ?? "",
                            originalMessage: ""
                        });
                        return [...temp];
                    });
                })
                .catch(error => {

                    console.log("sendImageMessageAsCharacter: ", error);
                });
        }
    };

    const hanldeSubscribeToCharacter = () => {
        navigate(`/character/${chatId}`);
    };

    const handleGroupUpdate = (_, userMembers, aiMembers) => {
        AIApiInstance.updateGroupChat(currentGroupChat?._id, userMembers, aiMembers)
        .then(_ => {
            alert('Group chat updated.');
            setCurrentGroupChat(prev => ({...prev, userMembers, aiMembers}));
        }).catch(console.error)
    };

    const speechToText = () => {
        if(!browserSupportsSpeechRecognition){
            alert('Speech to Text not supported by this browser');
            return;
        }
        if(listening){
            SpeechRecognition.stopListening();
            resetTranscript();
        } else {
            SpeechRecognition.startListening({continuous: true});
        }
    }

    return (
        <>
            <div className="chat-header d-flex justify-content-between align-items-center border-bottom pb-lg-3 p-1">
                <div className="d-flex align-items-center px-4 pt-4 pt-lg-3 ">
                    <Avatar
                        className="br-50"
                        onClick={handleAvatarClick}
                        name={chatName}
                        avatarUrl={avatar !== "" && (avatar?.includes("http") ? avatar : getAiCharacterImageUrl(avatar))}
                    />
                    <div className="ms-3">
                        <h6 className="mb-0">{chatName}</h6>
                        <div className="d-flex align-items-center">
                            <StatusBadge status={status} />
                            <small className="text-muted ms-1">{status}</small>
                        </div>
                    </div>
                </div>
                {/* <Form.Switch
                    label={`Translate ${autoTranslate ? "On" : "Off"}`}
                    defaultChecked={autoTranslate}
                    onChange={handleToggleTranslate}
                    disabled={autoTranslateDisabled}
                /> */}
               {chatDisabledSubscription && <Button onClick={hanldeSubscribeToCharacter}>
                    Subscribe To Character
                </Button>}
                <Form.Switch
                    className="chat-toggle-btn"
                    label={`Spicy ${user?.spicyChatEnabled ? "O" : "X"}`}
                    defaultChecked={user?.spicyChatEnabled ? true : false}
                    onChange={handleSpicyModeChange}
                    disabled={true}
                />
                <div style={{ cursor: "pointer", marginLeft: "20px" }} className="d-flex flex-row gap-5 align-items-center">
                <a href={process.env.PUBLIC_URL +"/premium-price"} target="_blank" >{user?.orangePointsCount} OP</a>
                    <Dropdown style={{ width: "fit-content" }}>
                        <Dropdown.Toggle style={{ backgroundColor: "#272727" }} variant="success" id="dropdown-basic" />
                        <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item onClick={toggleSelectSpicyAiPopup}>Select Ai Model</Dropdown.Item>
                            <Dropdown.Item onClick={handleUpdateSettingsClick}>Update Params</Dropdown.Item>
                            <Dropdown.Item onClick={handleClickPublishChat}>
                                {!isChatPublic ? "Publish Chat" : "Make this Chat private."}
                            </Dropdown.Item>

                            <Dropdown.Item onClick={toggleTranslateModal}>
                            Auto Translate
                            </Dropdown.Item>

                            <Dropdown.Item onClick={toggleRolePlayModal}>
                            Role Play Mode
                            </Dropdown.Item>

                            <Dropdown.Item onClick={handleDeleteChat}>
                            Delete Chat
                            </Dropdown.Item>

                            <Dropdown.Item onClick={handleUpdateGroupMembers}>
                            Update Group Members
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <a className="py-2 d-lg-none chatlist-toggle me-3" href="#!">
                <Button
                        variant="transparent"
                        style={{ background: 'rgba(0,0,0,0.5)', borderRadius: '12px'}}
                        className="ms-2 d-flex align-items-center py-1 px"
                        onClick={toggleDrawer}
                >
                <i class="fa-solid fa-chevron-left"></i>
                <p className="mb-0 ms-2">Go back</p>
                </Button>
                {/* <span className="button__badge">1</span> */}
            </a>
            <ul style={currentChar?.character?.coverUrl ? {backgroundSize: "cover", backgroundImage:`url(${getAiCharacterImageUrl(currentChar?.character?.coverUrl)})`}: {}} className="chat-history list-unstyled mb-0 flex-grow-1 px-5 px-lg-4 py-4 py-lg-3  ">
                {renderChats()}
                {charReplying && (
                    <div className="color-defult-white">{currentChar?.character?.characterName || "Bot"} is replying...</div>
                )}
                <div ref={bottomRef} />
            </ul>
                    <AutoTranslateModal 
                    setTranslateModal={setTranslateModal} 
                    translateModal={translateModal} 
                    autoTranslate={autoTranslate} 
                    handleToggleTranslate={handleToggleTranslate} 
                    customStyles={customStyles} />
            
                    <SelectSpicyAiModal 
                    setSelectSpicyAiPopup = {setSelectSpicyAiPopup}
                    selectSpicyAiPopup = {selectSpicyAiPopup}
                    setSpicyAiModels={setSpicyAiModels}
                    spicyAiModels = {spicyAiModels}
                    ws={ws}
                    customStyles={customStyles}
                    selectedSpicyAiModel={selectedSpicyAiModel}
                    setSelectedSpicyAiModel={setSelectedSpicyAiModel}
                    />

                <Modal
                setIsOpen={setRolePlayModal}
                modalIsOpen={rolePlayModal}
                showClose={true}
                styles={customStyles}
                body={
                    <div className="px-2 d-flex flex-column gap-3">
                        <strong>
                            <span>{`Do you want to toggle role play?`}</span>
                        </strong>
                       <Form.Switch
                            label={`Role Play Mode ${rolePlay ? "On" : "Off"}`}
                            defaultChecked={rolePlay}
                            onChange={handleToggleRolePlay} 
                        />
                    </div>
                }
                />
            <Modal
                setIsOpen={setShowAdModal}
                modalIsOpen={showAdModal}
                showClose={true}
                preventEscClose={true}
                styles={customStyles}
                body={<GoogleAd />}
            />
            <Modal
                styles={customStyles}
                setIsOpen={setShowRewardAdModal}
                modalIsOpen={showRewardAdModal}
                body={<RewardAdBox onSelectBox={handlePrizeSelect} />}
            />
            <div className="position-relative bottom-0 border-top d-flex gap-2 py-2 px-2 text-input-container">
               {/* <Button
                    className="ms-1 d-flex align-items-center py-2"
                    variant="transparent"
                    onClick={speechToText}
                >
                    {
                        !browserSupportsSpeechRecognition ? <i class="fa-solid fa-microphone-slash"></i>
                        : listening ? <i class="fa-solid fa-ear-listen"></i> :  <i class="fa-solid fa-microphone"></i>
                    }
                </Button> */}
                <Button
                    className="chat-menu-icon-btn"
                    variant="transparent"
                    onClick={toggleContainerVisibility}
                    disabled={chatDisabledSubscription}>
                    <i className="fa-solid fa-plus chat-menu-icon"></i>
                </Button>
                <Button
                    className="chat-menu-icon-btn"
                    variant="transparent"
                    onClick={()=>{setImageGeneratorModalPop(true)}}>
                    <i className="fa-solid fa-image chat-menu-icon"></i>
                </Button>
                <div className="chat-inputs-container gap-2">
                    <div className="chat-input-container">
                        <textarea
                            ref={messageTextAreaRef}
                            className="chat-form-control"
                            placeholder={chatDisabledSubscription ? 'Please subscribe' : 'Chat with this Character'}
                            value={currentChar.message}
                            onChange={handleOnChangeTextArea}
                            disabled={chatDisabledSubscription}
                        ></textarea>
                        <Button
                            style={{marginRight: "50px"}}
                            disabled={currentChar.message === "" || !isCharacterReady || chatDisabledSubscription}
                            className="gradient-chat-menu-icon-btn"
                            variant="transparent"
                            onClick={addStarRolePlayToString}>
                            <i className="fa-solid fa-asterisk gradient-chat-menu-icon"></i>
                        </Button>
                        <Button
                            disabled={currentChar.message === "" || !isCharacterReady || chatDisabledSubscription}
                            className="gradient-chat-menu-icon-btn"
                            variant="transparent"
                            onClick={handleSendMessage}>
                            <i className="fa-solid fa-paper-plane gradient-chat-menu-icon"></i>
                        </Button>
                    </div>
                    {/* <div className="chat-input-container">
                        <textarea
                            className="chat-form-control"
                            placeholder={chatDisabledSubscription ? 'Please subscribe' : 'Roleplay Actions'}
                            value={currentChar.roleplayMessage}
                            onChange={handleOnChangeRoleplay}
                            disabled={chatDisabledSubscription}
                        ></textarea>
                        <Button
                            disabled={currentChar.roleplayMessage === "" || !isCharacterReady || chatDisabledSubscription}
                            className="gradient-chat-menu-icon-btn"
                            variant="transparent"
                            onClick={handleSendMessage}>
                            <i className="fa-solid fa-asterisk gradient-chat-menu-icon"></i>
                        </Button>
                    </div> */}
                </div>
            </div>
            <div className={`menu-container ${isContainerVisible ? 'visible' : ''}`}>
            {/*<div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={chatIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Publish Chat</p>
                </div>
                
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={voiceIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Use Voice</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={updateIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Update Params</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={rolePlayIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Role Play Mode</p>
                </div>
               
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={translateIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Switch Language</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={deleteIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Delete Chat</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={aiIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Select AI Model</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={newChapterIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">Save as New Chapter</p>
                </div>
                <div className="menu-icon-container gap-1">
                    <Button
                        className="menu-icon-btn"
                        variant="transparent">
                        <img className="menu-icon-image" src={viewIcon} style={{width: "20px", height: '20px'}}/>
                    </Button>
                    <p className="text-white">View StoryChats</p>
                </div> */}
            </div>
            <Modal
                styles={customStyles}
                modalIsOpen={askPublishChat.showModal}
                setIsOpen={toggleAskPublicModal}
                body={
                    <PublishChatBody
                        toggleAskPublicModal={toggleAskPublicModal}
                        respondAskPublicModal={respondAskPublicModal}
                        isChatPublic={isChatPublic}
                    />
                }
            />
            <Modal
                
                modalIsOpen={membersModal}
                setIsOpen={setMembersModal}
                body={
                    <GroupCreateModal
                        closeModal={() => setMembersModal(false)}
                        handleGroupSave={handleGroupUpdate}
                        currentGroupChat={currentGroupChat}
                    />
                }
                styles={membersModalStyles}
            />
             <ImageGeneratorModalPop
               setImageGeneratorModalPop = {setImageGeneratorModalPop}
               imageGeneratorModalPop = {imageGeneratorModalPop}
               inProgressImage = {inProgressImage}
               setShowRewardAdModal={setShowRewardAdModal}
               characterData={currentChar}
               ws={ws}
               customStyles={customStyles}
               userId={user?.userId}
               imgGenButtonDisabled={imgGenButtonDisabled}
               setImgGenButtonDisabled={setImgGenButtonDisabled}
               chatType={ChatType.group}
               groupId={currentGroupChat?._id}
            />
        </>
    );
}

const MessageCommentContainer = React.memo(({ message, onBackPress }) => {
    return (
        <div className="px-4 py-2">
            <Button className="my-2" onClick={onBackPress}>
                Back
            </Button>
            <MessageComments message={message} allowComments />
        </div>
    );
});

export default React.memo(GroupChatBox);
