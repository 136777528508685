import { NO_IMAGE } from "../../utils/images";

const CoverPhoto = ({ imageUrl, overlayText = '', overlayStyle = '' }) => {
    imageUrl = imageUrl || NO_IMAGE

    return (
        <div
            className="cover-photo w-100 position-relative"
            style={{
                backgroundImage: `url(${imageUrl})`,
            }}
        >
        </div>
    );
};

export default CoverPhoto;
