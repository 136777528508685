import React from 'react';
import ReactModal from 'react-modal';
import './index.css';

const customStyles = {
    content: {
        zIndex: 1000,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        /* marginRight: '-50%', */
        transform: 'translate(-50%, -50%)',
        height: "50vh",
        width: "50vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#272727",
    },
};

ReactModal.setAppElement('#root');

export function Modal({ modalIsOpen, setIsOpen, title = "", body, styles = customStyles, onAfterClose = () => {}, showClose = true, preventEscClose = false }) {
    let subtitle;


    function afterOpenModal() {
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div>
            <ReactModal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={!preventEscClose ? closeModal : () => {}}
                style={styles}
                contentLabel={title}
                onAfterClose={onAfterClose}
            >
                {showClose && (
                    <div className="close-icon-container">
                        <i onClick={closeModal} className="fa-solid fa-xmark"></i>
                    </div>
                )}
                {body}
            </ReactModal>
        </div>
    );
}