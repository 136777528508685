import React from "react";
import ReactStars from "react-stars";
import { getUserImageUrl } from "../../../utils/images";
import DefaultImage from "../../../assets/images/logo/Default.png";




const ReviewCard = ({ review, onEdit }) => (
    <div
        className="review-card d-flex flex-column p-2 align-items-start"
        onClick={onEdit}
        style={{ cursor: "pointer" }}
    >
        <div className="rating-indicator position-absolute mt-1 me-4 end-0 top-0">
            <ReactStars edit={false} count={1} value={1} size={20} />
            <span className="rating-value ms-1">{review?.rating?.toFixed(1) ?? "0.0"}</span>
        </div>
        <div className="d-flex flex-row align-items-top mt-0">
            <img
                    className="image"
                    src={getUserImageUrl(review?.userInfo?.avatar_url)}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = DefaultImage;
                    }}
                    alt={review?.userInfo?.username || "Default Avatar"}
                />
            <div className="d-flex flex-column ms-3">
                <span className="font-weight-bold text-white">{review?.userInfo?.username}</span>
                <p className="category-content text-white-50 small mb-1">
                    {review?.ratingDescription ?? ""}
                </p>
            </div>
        </div>
    </div>
);

export default ReviewCard;
