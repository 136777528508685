import React, { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Avatar from "../../../../Component/Comman/Avatar";
import CoverPhoto from "../../../../Component/Comman/CoverPhoto";
import { selectCurrentUser } from "../../../../Redux/selectors/auth";
import { getUserImage } from "../../../../utils/images";
import { Modal } from "../../../../Component/Modal/Modal";
import Profile from "../../../../assets/images/profile_av.svg";
import "../creatorProfile.css";
import { authAPIInstance } from "../../../../apis/AuthAPI";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "300px",
        maxWidth: "400px",
        backgroundColor: "#171722",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "80vh",
        overflowY: "auto",
    },
    overlay: {
        backgroundColor: "#171722",
        backdropFilter: "blur(3px)",
    },
};

const FollowerItem = ({ follower, activeTab, isTheCurrentUser, handleCancel }) => {
    const [followingContent, setFollowingContent] = useState("Follow");

    const navigate = useNavigate();
    const handleFollow = () => {
        setFollowingContent('...');
        authAPIInstance.follow_user(follower.userId).then(_ => {
            setFollowingContent('Message');
        }).catch(e => {
            alert(e?.response?.data?.data?.message ?? 'Something went wrong.');
        })
    }
    const messageUser = () => navigate(`/chat?type=user&id=${follower.userId}`);
    return (
    // <div className="follower-item d-flex flex-row w-100" onClick={navigate(`/@${follower.username}`)}>
    <div className="follower-item d-flex flex-row w-100"> 
        <div className="d-flex flex-row gap-1 follower-info">
            <div className="d-flex follower-avatar-container" onClick={() => {navigate(`/@${follower.username}`); handleCancel()}}>
                <Avatar
                    name={follower.username}
                    avatarUrl={getUserImage(follower)}
                    className="pointer follower-avatar"
                />
            </div>
            <div className="follower-info">
                <p className="follower-username">{follower.username}</p>
            </div>
        </div>   
        {!isTheCurrentUser && <div className="d-flex follower-follow-button-container">
            <button className="follower-follow-button" onClick={() => {follower.followingStatus === "unset" ? handleFollow() : messageUser()}}>
                {follower.followingStatus === "unset" ? followingContent : "Message"}
            </button>
        </div>}
    </div>
)};

const UserCard = ({
    handleSocialsToggle,
    handleEditToggle,
    containerStyle = {},
    userInfo = {},
    followingStatus = false,
    followers = [],
    followingsCount = 0,
    likesCount = 0,
    starsCount = 0,
    handleFollowClick,
    fetchFollowerProfiles,
}) => {
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser);
    const [isUserProfile, setIsUserProfile] = useState(false);
    const [showStatModal, setShowStatModal] = useState(false);
    const [activeTab, setActiveTab] = useState("Followers");
    const [followerProfiles, setFollowerProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [followStatus, setFollowStatus] = useState(followingStatus);

    const isCurrentUserProfile = useMemo(
        () => user && user?.userId === userInfo?._id,
        [user, userInfo]
    );

    useEffect(() => {
        setIsUserProfile(isCurrentUserProfile);
        setFollowStatus(followingStatus);
    }, [isCurrentUserProfile, followingStatus]);

    useEffect(() => {
        const fetchProfiles = async () => {
            setLoading(true);
            try {
                const profiles = await fetchFollowerProfiles(followers);
                setFollowerProfiles(profiles);
            } catch (error) {
                console.error("Error fetching profiles:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProfiles();
    }, [followers, fetchFollowerProfiles]);

    const messageUser = () => navigate(`/chat?type=user&id=${userInfo._id}`);
    const handleCancel = () => setShowStatModal(false);

    return (
        <div
            className="user-card-container-box poppins-font d-flex text-white"
            style={containerStyle}
        >
            <div>
                <CoverPhoto imageUrl="https://via.placeholder.com/1200x300" />
            </div>
            <div className="profile-card p-3 d-flex justify-content-around align-items-start">
                <div className="d-flex flex-column align-self-start profile-info">
                    <div className="container-wrapper position-relative justify-content-center">
                        <div className="avatar-container position-relative d-flex justify-content-center align-items-center">
                            <Avatar
                                name={userInfo?.username}
                                avatarUrl={getUserImage(userInfo)}
                                className="pointer"
                            />
                        </div>
                    </div>
                    <h2>{userInfo?.username}</h2>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <p className="profile-description text-start">
                            {userInfo?.description}
                        </p>
                    </div>
                </div>

                <div className="d-flex w-100 profile-card-content-container">
                    <div className="d-flex flex-row profile-stats w-100">
                        <div>
                            <h3 onClick={() => setShowStatModal(true)}>{followers.length}</h3>
                            <p>Followers</p>
                        </div>
                        <div>
                            <h3 onClick={() => {setShowStatModal(true); setActiveTab("Following")}}>{followingsCount}</h3>
                            <p>Following</p>
                        </div>
                        <div>
                            <h3>{likesCount} K</h3>
                            <p>Likes</p>
                        </div>
                        {isUserProfile && (
                            <div>
                                <h3>{starsCount} K</h3>
                                <p>Stars</p>
                            </div>
                        )}
                    </div>

                    <div className="profile-card-button-container d-flex flex-column w-100">
                        <div className="d-flex w-100 profile-action-button-container">
                            {!isUserProfile && (
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={handleFollowClick}
                                        className="py-3 mb-3 follow-button text-white border-0 cursor-pointer"
                                    >
                                        <p>
                                            {followStatus ? (
                                                <i className="fa-regular fa-thumbs-up"></i>
                                            ) : (
                                                <i className="fa-solid fa-thumbs-up"></i>
                                            )}
                                            &nbsp;
                                            {followingStatus ? "Unfollow" : "Follow"}
                                        </p>
                                    </Button>
                                </div>
                            )}

                            <div className="d-flex align-items-center justify-content-center pb-3">
                                <Button
                                    style={{ backgroundColor: "rgb(50, 49, 48)" }}
                                    onClick={handleSocialsToggle}
                                    className="d-flex justify-content-center align-items-center p-2 socials-button fs-5 px-4 text-white border-0 cursor-pointer"
                                >
                                    <i className="fas fa-link" /> &nbsp;Social
                                </Button>
                            </div>

                            {isUserProfile && (
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={handleEditToggle}
                                        className="py-3 mb-3 edit-button text-white border-0 cursor-pointer"
                                    >
                                        <i className="fa-solid fa-pen-to-square mx-2" />
                                        <p className="edit-button-label">Edit</p>
                                    </Button>
                                </div>
                            )}
                        </div>

                        {!isUserProfile && (
                            <div className="d-flex justify-content-center message-container">
                                <Button
                                    onClick={messageUser}
                                    className="py-3 mb-3 message-button text-white border-0 cursor-pointer"
                                >
                                    <i className="fa-solid fa-message mx-2" />
                                    <p className="edit-button-label">Message</p>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                setIsOpen={setShowStatModal}
                modalIsOpen={showStatModal}
                showClose={false}
                styles={customStyles}
                className="followers-modal"
                overlayClassName="modal-overlay"
                body={
                    <div className="modal-content w-100 d-flex profile-stat-modal poppins-font">
                        <div className="modal-header">
                            <h2>{activeTab}</h2>
                            <button className="close-button" onClick={handleCancel}>
                                &times;
                            </button>
                        </div>
                        <hr></hr>

                        <div className="tab-container w-100 d-flex">
                            <button
                                className={`tab ${activeTab === "Followers" ? "active" : ""}`}
                                onClick={() => setActiveTab("Followers")}
                            >
                                <div className="button-content">
                                    Followers
                                </div>
                            </button>
                            <button
                                className={`tab ${activeTab === "Following" ? "active" : ""}`}
                                onClick={() => setActiveTab("Following")}
                            >
                                <div className="button-content">
                                    Following
                                </div>
                            </button>
                        </div>

                        <div className="follower-list w-100">
                        {loading ? (
                            <h6 className="loading-placeholder">Loading...</h6>
                            ) : activeTab === "Followers" ? (
                            <div className="d-flex flex-column w-100">
                                <h3>{followerProfiles.length} {activeTab}</h3>
                                <div className="d-flex flex-column gap-1 profile-stats-body">
                                {followerProfiles && followerProfiles.length > 0 ? (
                                    followerProfiles.map((follower, index) => (
                                    <FollowerItem
                                        key={index}
                                        follower={follower}
                                        activeTab={activeTab}
                                        isTheCurrentUser={user.userId == follower.userId ? true : false}
                                        handleCancel={handleCancel}
                                    />
                                    ))
                                ) : (
                                    <h6 className="modal-no-content">No followers</h6>
                                )}
                                </div>
                            </div>
                            ) : (
                            <h6 className="modal-no-content">No following</h6>
                            )}

                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default UserCard;
