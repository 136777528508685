import axios from "axios";
import { config } from "../utils/config";
import { readCookie } from "../utils/cookie";
// import { createFormData } from "../utils/createFormData";
import { httpTokenInterceptor } from "./common";

class StoryChatAPI {
    constructor() {
        this.axios = axios.create({
            baseURL: config[config.environment].baseApiUrl + "/storychat"
        });
        this.axios.interceptors.request.use(httpTokenInterceptor);
    }

    getDefaultStoryChat(characterId, userId) {
        return this.axios.get(`/get_user_default_storychats_for_allchatslist/${characterId}/${userId}`, {
            headers: {Authorization: `Bearer ${readCookie("token")}`},
        });
    }

    getStorychats(characterId) {
        return this.axios.get(`/get_all_storychats_by_character/${characterId}`, {
            headers: {Authorization: `Bearer ${readCookie("token")}`},
        });
    }

    createEmptyStoryChat(characterId, storychatName, storychatDescription, nsfwEnabled, image) {
        const formData = new FormData();
        formData.append('characterId', characterId);
        formData.append('storychatName', storychatName);
        formData.append('storychatDescription', storychatDescription);
        formData.append('nsfwEnabled', nsfwEnabled);
        formData.append('thumbnail_url', image);

        return this.axios.post(
            '/create_empty_story_chat',
            formData,
            {
                headers: {Authorization: `Bearer ${readCookie("token")}`},
            },
        );
    }

    updateStoryChatDetails(storyChatId, storychatName, storychatDescription, nsfwEnabled, thumbnail_url) {
        const formData = new FormData();
        formData.append('storychatId', storyChatId);
        formData.append('storychatName', storychatName);
        formData.append('storychatDescription', storychatDescription);
        formData.append('nsfwEnabled', nsfwEnabled);
        formData.append('thumbnail_url', thumbnail_url);

        return this.axios.post(`/update_storychat_details/`, formData, 
            {
                headers: {Authorization: `Bearer ${readCookie("token")}`},
            }
        );
    }

    deleteStoryChat(storychatId) {
        return this.axios.delete(`/delete_storychat/${storychatId}`, 
            {
                headers: { 
                    Authorization: `Bearer ${readCookie("token")}` 
                },
            }
        );
    }

    getStoryChatHistoryFromStoryChatId(storyChatId, userId, page = 1) {
        return this.axios.get(`/get_all_ai_chat_history_from_storychat_id/${storyChatId}/${userId}?page=${page}`, 
            {
                headers: {Authorization: `Bearer ${readCookie("token")}`},
            }
        );
    }
    
    sendOpenAICharMessage(
        characterId,
        storyChatId,
        personaId,
        message,
        spicyEnabled,
        lang = "en",
        autoTranslate = false,
        rolePlay = true,
        isChatPublic = false) {
          
        console.log("sendOpenAICharMessage personaId: ", personaId);
        return this.axios.post(
            `/send_openai_char_message/`,
            { characterId, storyChatId, personaId, message, spicyEnabled, lang, autoTranslate, rolePlay, isChatPublic },
            {
                headers: {Authorization: `Bearer ${readCookie("token")}`},
            }
        );
    }
}

export const StoryChatApiInstance = new StoryChatAPI();

export default StoryChatAPI;