import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import { Form, Spinner } from 'react-bootstrap';
import { getStorychatImageUrl } from '../../utils/images';
import { StoryChatApiInstance } from '../../apis/StoryChatAPI';
import { Modal } from '../Modal/Modal';
import DeleteStorychatModal from './DeleteStorychatModal';

export function EditStorychatModal({setShowEditStorychat, editStorychat, setShowViewStorychats}) {
    const fileInputRef = useRef(null);
    const [image, setImage] = useState(editStorychat.thumbnailUrl || null);
    const [newImage, setNewImage] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDeleteStorychatModal, setShowDeleteStorychatModal] = useState(false);    

    const [storyName, setStoryName] = useState(editStorychat.storyName);
    const [description, setDescription] = useState(editStorychat.storyDescription);
    const [isNSFW, setIsNSFW] = useState(editStorychat.nsfwEnabled);

    const handleAddThumbnail = () => {
        setUploadingImage(true);
        fileInputRef.current.click();
    }

    const handleSubmitStorychat = async () => {
        setLoading(true);
        const res = await StoryChatApiInstance.updateStoryChatDetails(editStorychat._id, storyName, description, isNSFW, newImage);
        if(res.status === 200) {
            setLoading(false);
            alert('Storychat updated successfully.');
            setShowEditStorychat(false);
        }else{
            console.log('error', res);
        }
    }

    const handleDeleteStorychat = async () => {
        setLoading(true);
        const res = await StoryChatApiInstance.deleteStoryChat(editStorychat._id);
        if(res.status === 200) {
            setLoading(false);
            alert('Storychat deleted successfully.');
            setShowDeleteStorychatModal(false);
            setShowEditStorychat(false);
            setShowViewStorychats(true);
        }else{
            console.log('error', res);
        }
    }

    if(editStorychat.storchatImage){
        console.log('detecting');
    }

    return (
        <div className='storychat-modal poppins-font'>
            <span className='font-bold'>Edit Storychat</span>
            <div>
                <div>
                    <span>Replace Image</span>
                    <div className='create-image-container'>
                        {image ? <img src={newImage ? URL.createObjectURL(newImage) : getStorychatImageUrl(image)} alt='storychat-image' className='create-storychat-modal-thumbnail' /> : <></>}
                        <button className='replace-image-button' onClick={handleAddThumbnail}>
                            <span><i className='fa-solid fa-plus me-2'></i>Replace Image</span>
                            <input 
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        setNewImage(e.target.files[0]);
                                        console.log('new image');
                                    }
                                    setUploadingImage(false);
                                }}
                            />
                        </button>
                    </div>
                </div>
                <div className='create-storychat-modal-input-container'>
                    <span>Title</span>
                    <input 
                        type='text' 
                        placeholder='Add Title'
                        onChange={(e) => setStoryName(e.target.value)}
                        value={storyName} 
                    />
                </div>
                <div className='create-storychat-modal-input-container'>
                    <span>Description</span>
                    <textarea 
                        type='text' 
                        placeholder='Add Description'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </div>
                <div className='create-storychat-modal-switch-option'>
                    <span>Enable NSFW Content</span>
                    <Form.Switch
                        className='create-storychat-modal-switch-option-switch'
                        defaultChecked={ isNSFW }
                        onChange={(e) => setIsNSFW(e.target.checked)}
                    />
                </div>
                <div className='create-storychat-modal-button-container'>
                    <button className="back-button flex-grow-1" onClick={() => setShowEditStorychat(false)} disabled={loading}>
                        <div className="back-button-content">
                            <span>Cancel</span>
                        </div>
                    </button>
                    <button className="next-button violet-gradient-btn flex-grow-1" onClick={handleSubmitStorychat} disabled={loading}>
                        <div className="next-button-content">
                            <span>{loading ? 'Editing...' : 'Edit'}</span>
                            {loading && <Spinner animation="border" variant="light" size="sm" className="ml-2" />}
                        </div>
                    </button>
                </div>
                <button className='back-button w-100 mt-2' disabled={loading} onClick={() => setShowDeleteStorychatModal(true)}>
                    <div className='back-button-content'>
                        <span>Delete Storychat<i className='fa fa-trash-o ms-2'></i></span>
                    </div>
                </button>
            </div>
            <Modal
                setIsOpen={setShowDeleteStorychatModal}
                modalIsOpen={showDeleteStorychatModal}
                body={
                    <DeleteStorychatModal 
                        setShowDeleteStorychatModal={setShowDeleteStorychatModal}
                        handleDeleteStorychat={handleDeleteStorychat}
                        loading={loading}
                    />
                }
            />
        </div>
    );
}
