import React, { useEffect, useRef, useState, useReducer, createContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import { AIApiInstance } from "../../../apis/AIAPI";
import { AnalyticsAPIInstance } from "../../../apis/AnalyticsAPI";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { isRedbitUrl } from "../../../utils/checkRedbitUrl";
import { getUserImageUrl } from "../../../utils/images";
import UserCard from "./components/UserCard";
import "./creatorProfile.css";
import MakeCreatorProfile from "./MakeCreatorProfile";
import ProfileSidebar from "./components/ProfileSidebar";
import ProfileTabs from "./components/ProfileTabs";
import SocialMediaTab from "./components/socialMediaTab";

const sampleSeries = [
    {
      _id: "1",
      title: "Star Wars",
      coverImage: "https://via.placeholder.com/200x300",
      description: "An epic space saga about the Jedi and the Sith.",
      rating: 4.9,
    },
    {
      _id: "2",
      title: "Joker",
      coverImage: "https://via.placeholder.com/200x300",
      description: "A deep dive into the psyche of the infamous villain.",
      rating: 4.8,
    },
    {
      _id: "3",
      title: "Interstellar",
      coverImage: "https://via.placeholder.com/200x300",
      description: "A journey through space and time to save humanity.",
      rating: 4.7,
    },
    {
        _id: "1",
        title: "Star Wars",
        coverImage: "https://via.placeholder.com/200x300",
        description: "An epic space saga about the Jedi and the Sith.",
        rating: 4.9,
        },
        {
        _id: "2",
        title: "Joker",
        coverImage: "https://via.placeholder.com/200x300",
        description: "A deep dive into the psyche of the infamous villain.",
        rating: 4.8,
        },
        {
        _id: "3",
        title: "Interstellar",
        coverImage: "https://via.placeholder.com/200x300",
        description: "A journey through space and time to save humanity.",
        rating: 4.7,
        },
  ];

export default function ViewCreatorProfile({ logout }) {

    const params = useParams();
    const isMyProfile = params?.username ? false : true;
    const user = useSelector(selectCurrentUser)
    const [userInfo, setUserInfo] = useState({});
    const [userData, setUserData] = useState({});
    const [isEditToggled, setIsEditToggled] = useState(false);
    const [isSocialsEnabled, setIsSocialsEnabled] = useState(false);
    const [characters, setCharacters] = useState([]);
    const [favoriteCharacters, setfavoriteCharacters] = useState([]);
    const [series, setSeries] = useState([]);
    const [followers, setfollowers] = useState([]);
    const [followings, setFollowings] = useState([]);
    const [likes, setLikes] = useState([]);
    const [stars, setStars] = useState([]);
    const [error, setError] = useState(false);
    const charactersLoaded =  useRef(false);
    const viewsLoaded =  useRef(false);
    const [isUserProfile, setIsUserProfile] = useState(false);
    const [whatProfile, setWhatProfile] = useState();
    const [followingStatus, setFollowingStatus] = useState(false);

    useEffect(() => {
        authAPIInstance
            .getFollowingStatus(userInfo._id)
            .then((res) => {
                setFollowingStatus(res.data?.data?.status ?? []);
            })
            .catch((e) => {
                console.error(e);
                //setError(true);
            });
    }, [userInfo]);

    useEffect(() => {
        let userId = userInfo?._id;
        if (userId && userId !== typeof 'undefined') {
            AIApiInstance.getCreatedCharacters(isRedbitUrl(), userId)
                .then((res) => {
                    charactersLoaded.current = true;
                    setCharacters(res?.data?.data?.created_characters ?? []);
                })
                .catch(console.error);
            authAPIInstance
                .getFollowers(userId)
                .then((res) => {
                    setfollowers(res.data?.data?.followers ?? []);
                })
                .catch((e) => {
                    console.error(e);
                });

            // i'll just place these setFunctions as placeholders of the real api req
            setFollowings(297);
            setLikes(256);
            setStars(237);
            setSeries(sampleSeries); 
        }
        if (userInfo?.username && !viewsLoaded.current) {
            AnalyticsAPIInstance.getUserProfileViews(userInfo?.username)
                .then((res) => {
                    viewsLoaded.current =  true;
                    setUserInfo((prev) => ({ ...prev, views: res?.data?.data?.views ?? 0 }));
                })
                .catch(console.error);
        }
    }, [userInfo]);

    useEffect(() => {
        if (isMyProfile) {
            setUserInfo({ ...user, _id: user?.userId, views: 0 });
            AIApiInstance.getFavoriteCharacters()
            .then(res => {
                setfavoriteCharacters(res?.data?.data?.characters ?? []);
            }).catch(console.error)
        } else {
            const username = params?.username?.split("@")?.[1];
            if (!username) {
                //setError(true);
                return;
            }
            console.log('usernamegetUserPublicProfile : ', username)
            authAPIInstance
                .getUserPublicProfile(null, username)
                .then((res) => {
                    setUserInfo({...res?.data?.data, views: 0 });
                })
                .catch((e) => {
                    console.error("getUserPublicProfile error: ",e);
                    //setError(true);
                });
            setfavoriteCharacters([]);
        }
    }, [isMyProfile, params]);

    useEffect(() => {
        // whatProfile is basically toggled between user's own profile or other profile, 
        // and simplifying design nuances based on whose profile is being loaded
        if (user && user?.userId === userInfo?._id) {
            // the point of userData is to get an updated socialLinks after editing profile
            authAPIInstance.getCurrentUserProfile()
            .then(res => {
                setUserData(res.data.user);
            }).catch(console.error)
            setIsUserProfile(true);
            setWhatProfile('profile-page-body');
        } else {
            setUserData(userInfo);
            setIsUserProfile(false);
            setWhatProfile('other-profile-page-body');
        }
    }, [userInfo, userInfo.socialLinks]);

    useEffect(() => {
        console.log('rerendering : ViewCreatorProfile');

        //handleEditToggle();
    }, ); //isSocialsEnabled

    async function fetchFollowerProfiles(followers) {
    
        // Check if followers array exists and is not empty
        if (!Array.isArray(followers) || followers.length === 0) {
            console.warn("No followers to process.");
            return [];
        }
    
        // Map through followers and call getUserPublicProfile for each user
        const profilePromises = followers.map(async follower => {
            const { userId, username } = follower;
            try {
                // Fetch the following status
                const followingStatusResponse = await authAPIInstance.getFollowingStatus(userId);
                const followingStatus = followingStatusResponse.data?.data?.status ?? false;
    
                // Fetch the user public profile
                const profileResponse = await authAPIInstance.getUserPublicProfile(userId, username);
    
                // Add followingStatus to the response data
                const profileData = profileResponse.data;
                profileData.followingStatus = followingStatus ? "set" : "unset";
                console.log("follower users: ", profileData)
                return profileData;
            } catch (error) {
                console.error(`Failed to fetch data for userId: ${userId}, username: ${username}`, error);
                return null; // Return null for failed requests
            }
        });
    
        // Wait for all profile promises to resolve
        const profiles = await Promise.all(profilePromises);
    
        const validProfiles = profiles
            .filter(profile => profile !== null) // Remove failed requests
            .map(profile => ({
                userId: profile.data._id,
                avatar: profile.data.avatar_url,
                username: profile.data.username,
                followingStatus: profile.followingStatus
            }));
        return validProfiles;
    }

    const handleEditToggle = () => {
        setIsEditToggled((prev) => !prev); // Flip the boolean value
    };

    const handleSocialsToggle = () => {
        setIsSocialsEnabled((prev) => !prev); // Flip the boolean value
    };

    const handleFollowClick = () => {
        authAPIInstance.follow_user(userInfo?._id).then(_ => {
            setFollowingStatus(prev => !prev);
        }).catch(e => {
            alert(e?.response?.data?.data?.message ?? 'Something went wrong.');
        })
    }

    const handleFollow = (followerId) => {
        authAPIInstance.follow_user(followerId).then(_ => {
            setFollowingStatus(prev => !prev);
        }).catch(e => {
            alert(e?.response?.data?.data?.message ?? 'Something went wrong.');
        })
    }

    const handleFollowerFollow = (followerId) => {
        handleFollow(followerId);
    }

    return (
        <>
            {error ? (
                <div style={{ height: "50vh" }} className="d-flex flex-column align-items-center justify-content-center">
                    <h2>User Not Found</h2>
                </div>
            ) : (
                <div className="view-creator-profile">
                    <div className="d-flex flex-start w-100 profile-container">
                        {isUserProfile && (<div className="d-flex flex-start profile-sidebar position-relative text-white pt-2 z-index-1">
                            <ProfileSidebar
                                level={5}
                                xp={5}
                                logout={logout}
                            />
                        </div>)}
                        {isEditToggled 
                            ?
                            <div className="d-flex flex-column poppins-font w-100 profile-page-body">
                                <MakeCreatorProfile handleEditToggle={handleEditToggle} userData={userData} />
                            </div>
                            :
                            <div className={`d-flex flex-column poppins-font w-100 ${whatProfile}`}>
                                <UserCard handleFollowClick={handleFollowClick} handleSocialsToggle={handleSocialsToggle} handleEditToggle={handleEditToggle} userInfo={userInfo} followers={followers} followingsCount={followings} likesCount={likes} starsCount={stars} followingStatus={followingStatus} fetchFollowerProfiles={fetchFollowerProfiles} />
                                {isSocialsEnabled
                                    ?
                                    <SocialMediaTab userInfo={userData} />
                                    :
                                    <ProfileTabs characters={characters} series={series}/>
                                }
                
                            </div>
                        }
                        
                    </div>
                    
                    
                </div>
            )}
        </>
    );
}
