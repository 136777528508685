import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import TotalEarnings from "./components/TotalEarnings";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import { readCookie } from "../../utils/cookie";
import { useNavigate } from "react-router";
// import { ReferralsTable } from "./ReferralsTable";
import { authAPIInstance } from "../../apis/AuthAPI";
// import { PayoutsTable } from "./PayoutsTable";
import { MerchantApiInstance } from "../../apis/MerchantsAPI";
import Referral from "./components/Referral";

export default function Earnings() {

  const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const token = readCookie("token");
    const [earnings, setEarnings] = useState([]);
    const [payouts, setPayouts] = useState([]);
    const [couponSaleEarnings, setCouponSaleEarnings] = useState([]);
    const [couponSales, setCouponSales] = useState([]);
    const [characterEarnings, setCharacterEarnings] = useState([]);
    const [totalEarnings, setTotalEarnings] = useState(0);
    const [withdrawEarningsAvailable, setWithdrawEarningsAvailable] = useState(0);

    useEffect(() => {
        authAPIInstance
            .getReferralEarnings(token)
            .then((res) => {
                const { data } = res.data;
                if (data.earnings) {
                    let _earnings = [];
                    let _totalEarnings = 0;
                    let _withdrawEarningsAvailable = 0;
                    data.earnings.forEach((earning) => {
                        let current_user_earnings = [];
                        earning.earnings.forEach((_earning) => {
                            _totalEarnings += _earning?.amountEarned;
                            if(!_earning?.payout_id) {
                                _withdrawEarningsAvailable += _earning?.amountEarned;
                            }
                            current_user_earnings.push({
                                username: earning?.referred_user_info?.username,
                                avatar: earning?.referred_user_info?.avatar_url,
                                totalEarnings: _earning?.amountEarned,
                                currency: _earning?.currency,
                                payout_id: _earning?.payout_id,
                                action: _earning?.action,
                                timeStamp: _earning?.timeStamp,
                                withdrawn: false
                            });
                        });
                        if (current_user_earnings.length > 0) {
                            _earnings = _earnings.concat(current_user_earnings);
                        } else {
                            _earnings.push({
                                username: earning?.referred_user_info?.username,
                                avatar: earning?.referred_user_info?.avatar_url,
                                totalEarnings: 0,
                                payout_id: null,
                                action: '-',
                                withdrawn: true,
                                timeStamp: '',
                            });
                        }
                    });
                    setWithdrawEarningsAvailable(_withdrawEarningsAvailable.toFixed(2));
                    setTotalEarnings(_totalEarnings.toFixed(2));
                    setEarnings(_earnings);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [token]);

    useEffect(() => {
        authAPIInstance.getPayouts(token)
        .then(res => {
            setPayouts(res?.data?.data?.payouts ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        authAPIInstance.getCharacterEarnings(token)
        .then(res => {
            setCharacterEarnings(res?.data?.data?.earnings ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        MerchantApiInstance.getCouponEarnings(token)
        .then(res => {
            setCouponSaleEarnings(res?.data?.data?.coupon_sales ?? []);
        })
        .catch(console.error);
    }, [token]);

    useEffect(() => {
        MerchantApiInstance.getCouponSales(token)
        .then(res => {
            setCouponSales(res?.data?.data?.coupon_sales ?? []);
        })
        .catch(console.error);
    }, [token]);

    const handleEditClick = () => {
        navigate("/bank-info");
    };

    const handleWithdraw = () => {
        authAPIInstance.payoutReferralEarnings(token)
        .then(_ => {
            setWithdrawEarningsAvailable(0);
            alert("Payout request successful. Amount will be credited in your account within 14 days.");
        }).catch(e => {
            console.error(e);
            if(e?.response?.status === 409){
                alert("Please add your account details");
            } else alert("Something went wrong.");
        })
    };

    const handleCouponStatusUpdate = (txId, currentStatus) => {
        setCouponSaleEarnings((prev) => {
            const previousCouponsTx = [...prev];
            const foundIndex = prev.findIndex((tx) => {
                return tx._id === txId;
            });
            if (foundIndex === -1) {
                return prev;
            }
            previousCouponsTx[foundIndex] = { ...previousCouponsTx[foundIndex], isCouponRedeem: !currentStatus };
            return [...previousCouponsTx];
        });
    };
  
    return (
        <div className="d-flex flex-column tab-body-content">
            <div className="d-flex flex-column gap-3 earning-bank-section">
                <div className="d-flex total-earnings-section earning-item">
                    {/* <div className="d-flex flex-row gap-2 earning-headings">
                        <span>
                            <i class="fa-solid fa-dollar-sign"></i>
                        </span>
                        Total Earnings
                    </div> */}
                    <TotalEarnings handleWithdraw={handleWithdraw} totalEarnings={totalEarnings} withdrawEarningsAvailable={withdrawEarningsAvailable}/>
                </div>
                <div className="d-flex bank-details-section earning-item">
                    {/* <div className="d-flex flex-row gap-2 earning-headings">
                        <span>
                        <i class="fa-solid fa-building-columns"></i>
                        </span>
                        Bank Details
                    </div> */}
                    <div className="bank-account-container">
                        <div className="top-container upper-section-container">
                            <div className="d-flex w-100 upper-section">
                                <span className="d-flex bank-details-head">
                                    <i class="fa-solid fa-building-columns"></i>
                                    <strong>Bank Details</strong>
                                </span>
                                <div className="d-flex update-bank-container">
                                    <span className="acc-edit-btn w-100" onClick={handleEditClick}>
                                        Update Bank Information
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="top-container bank-acc-details">
                            <span className="acc-name-no">{user?.bankName ? user?.bankName : "Bank name not added"}</span>
                            <span>{user?.bankAccountNumber ? user?.bankAccountNumber : "Account number not added"}</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="d-flex referral-earnings-container flex-column">
                <div className="d-flex flex-row gap-2 earning-headings">
                    <span>
                    <i class="fa-solid fa-people-group"></i>
                    </span>
                    <span>
                        Referral Earnings
                    </span>
                    
                </div>
                <div className="table-header d-flex text-center">
                    <div className="table-row">
                    <div className="table-cell coin-column"><h3>User</h3></div>
                    <div className="table-cell amount-column"><h3>Earnings</h3></div>
                    <div className="table-cell action-column"><h3>Earned By</h3></div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-wrap table-body">
                    {
                    earnings.map((e) => (
                        <Referral earning={e}/>
                    ))
                    }
                </div>
            </div>
        </div>
    );
}
