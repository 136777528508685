import React from "react";
import { FiChevronRight } from "react-icons/fi";
import Avatar from "../../Component/Comman/Avatar";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/selectors/auth";
import aptosLogo from '../../assets/icons/aptosIcon.png';
import gemIcon from '../../assets/icons/gemIcon.png';
import storyPointIcon from '../../assets/icons/storyPointIcon.png';

export default function PointsList({ points }) {

  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);

  const gemPoint = {
      pointRewardName: "Gems",
      totalAmount: user?.gemsCount ?? 0,
      symbol: 'G'
  };
  const orangePoint = {
      pointRewardName: "Orangepoints",
      totalAmount: user?.orangePointsCount ?? 0,
      symbol: 'OP'
  };

  const currencies = [
    {
      pointRewardName: "Gems",
      totalAmount: user?.gemsCount ?? 0,
      symbol: "G",
      imageUrl: gemIcon, // Add image URL for coins like Gems
    },
    {
      pointRewardName: "Story Points",
      totalAmount: user?.orangePointsCount ?? 0,
      symbol: "SP",
      imageUrl: storyPointIcon, // Add image URL for OrangePoints
    },
    {
      pointRewardName: "Aptos",
      totalAmount: 0, // Add the appropriate total amount for Aptos
      symbol: "APT",
      imageUrl: aptosLogo, // Use the `aptosLogo` for Aptos
    },
  ];
 
  const handlePointClick = (storeId) => {
      navigate(`/store/${storeId}`);
  };

  const handleAptosClick = () => {
    navigate(`/aptos-wallet`);
  };

  return (
    <div className="table-container d-flex flex-column text-center">
      <div className="table-header d-flex text-center">
        <div className="table-row">
          <div className="table-cell coin-column"><h3>Coin</h3></div>
          <div className="table-cell amount-column"><h3>Amount</h3></div>
          <div className="table-cell action-column"><h3>Action</h3></div>
        </div>
      </div>
      <div className="table-body">
        {currencies.map((point, index) => (
          <div key={index} className="table-row">
            {/* Coin Section */}
            <div className="table-cell coin-column">
              <div className="coin-info">
                <img src={point.imageUrl} alt={point.pointRewardName} style={{ width: "32px", borderRadius: "50%" }} />
                <span>{point.pointRewardName}</span>
              </div>
            </div>
            {/* Amount Section */}
            <div className="table-cell amount-column">
              <div className="amount-info">
                <span>{point.totalAmount} {point.symbol}</span>
                <br />
                <span>$ {point.totalAmount}</span>
              </div>
            </div>
            {/* Action Section */}
            <div className="table-cell action-column">
              <div className="action-buttons">
                <div className="d-flex action-button-container">
                    <button
                    className="convert-button"
                    onClick={() => console.log(`Convert ${point.pointRewardName}`)}
                    >
                    Convert
                    </button>
                </div>
                <div className="d-flex action-button-container">
                    <button
                    className="trade-button"
                    onClick={() => console.log(`Trade ${point.pointRewardName}`)}
                    >
                    Trade
                    </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const AptosBtn = ({ handleAptosClick = () => {} }) => {
    return (
        <div
            style={{ width: "400px", cursor: "pointer" }}
            className="referral-earning-card d-flex flex-row gap-5 p-3 justify-content-between"
            onClick={handleAptosClick}
        >
            <div className="d-flex flex-row gap-2">
                <img className="aptosLogo" src={aptosLogo} alt="" style={{ width: "50px" }}/>
                <div className="d-flex flex-row align-items-center">
                    <span style={{ fontSize: "1.05rem" }}>Aptos (TestNet Only)</span>
                </div>
            </div>
        </div>
    );
};

const Point = ({point, handlePointClick = () => {}}) => {
    return (
        <div key={point._id} onClick={() => handlePointClick(point?.pointTokenID?.merchantStoreID)}>
            <Card className="p-3 mb-2 clickable-card clickable-list-item">
                <div className="d-flex align-items-center pointer">
                    <Avatar name={point?.pointRewardName} avatarUrl={point?.pointImageUrl} className="xl pointer" />
                    <div className="flex-fill ms-3 text-truncate">
                        <h6 className="justify-content-between mb-0 d-flex clickable-list-text">{point?.pointRewardName}</h6>

                        <div className="d-flex align-items-center mt-2">
                            <small className="msg-time pointer clickable-list-text">{point?.totalAmount}</small>
                        </div>
                    </div>
                    <div>
                        <FiChevronRight size={24} className="clickable-list-text" />
                    </div>
                </div>
            </Card>
        </div>
    );
};

const PointV2 = ({ point, handlePointClick = () => {} }) => {
    return (
        <div
            style={{ width: "400px" }}
            onClick={() => handlePointClick(point?.pointTokenID?.merchantStoreID)}
            className="referral-earning-card d-flex flex-row p-3 justify-content-between"
        >
            <div className="d-flex flex-row gap-2">
                <Avatar name={point?.pointRewardName} avatarUrl={point?.pointImageUrl} className="referral-avatar" />
                <div className="d-flex flex-column gap-2 align-items-start">
                    <span style={{ fontSize: "1.05rem" }}>{point?.pointRewardName}</span>
                    <span className="opacity-75">{point?.symbol ?? point?.pointRewardName?.slice(2)}</span>
                </div>
            </div>
            <div className="d-flex flex-column align-items-end gap-2">
                <span style={{ fontSize: "1.05rem" }}>
                    {point?.totalAmount} {point?.symbol ?? point?.pointRewardName?.slice(2)}
                </span>
                <span className="opacity-75">$ {point?.totalAmount}</span>
            </div>
        </div>
    );
};
