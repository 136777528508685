import React, { useState } from "react";
import platformPic from "../../../assets/images/platform.png";
import '../index.css';
import { getAiCharacterImageUrlV2, getUserImage } from "../../../utils/images";
import defaultImage from "../../../assets/images/splash.png";

export default function TopLeaderboard({ 
    characters,
    creators,
    goToLeaderboard,
    handleNavigateToAiCharacter,
    handleNavigateToCreator
}) {
    const [displayList, setDisplayList] = useState("Characters");
    const category = ["Characters", "Creators"];

    return (
        <div className="top-leaderboard-container w-100">
            <div className="d-flex flex-row justify-content-between">
                <h4 className="glorify-font">Leaderboards</h4>
                <button className="see-all-button" onClick={goToLeaderboard}>View All</button>
            </div>
            <div className="top-leaderboard-category-button-container">
                {category.map((item, index) => (
                    <button key={index} className={displayList === item ? "top-leaderboard-category-button-selected" : "top-leaderboard-category-button"} onClick={() => setDisplayList(item)}>{item}</button>
                ))}
            </div>
            {characters !== undefined && displayList === "Characters" && characters.length > 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row">
                        
                        <div className="char-card char-second" >
                            <CharacterCardLeaderboard character={characters.length > 1 ? characters[1] : characters[0]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                        </div>
                        <div className="char-card char-first">
                            <CharacterCardLeaderboard character={ characters[0]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                        </div>
                        <div className="char-card char-third">
                            <CharacterCardLeaderboard character={characters.length > 2 ? characters[2] : characters[0]} handleNavigateToAiCharacter={handleNavigateToAiCharacter}/>
                        </div>
                    </div>
                    <div className="platform-pic-container">
                        <img src={platformPic} alt="platform" className="platform-pic" />
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-row">
                        <div className="char-card char-second" >
                            <CreatorCardLeaderboard creator={creators.length > 1 ? creators[1] : creators[0]} handleNavigateToCreator={handleNavigateToCreator}/>
                        </div>
                        <div className="char-card char-first">
                            <CreatorCardLeaderboard creator={creators[0]} handleNavigateToCreator={handleNavigateToCreator}/>
                        </div>
                        <div className="char-card char-third">
                            <CreatorCardLeaderboard creator={creators.length > 2 ? creators[2] : creators[0]} handleNavigateToCreator={handleNavigateToCreator}/>
                        </div>
                    </div>
                    <div className="platform-pic-container">
                        <img src={platformPic} alt="platform" className="platform-pic" />
                    </div>
                </div>
            )}
        </div>
    );
}

export const CharacterCardLeaderboard = ({ character, handleNavigateToAiCharacter }) => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" onClick={() => handleNavigateToAiCharacter(character?.aiCharacter?._id)}>
            <div className="character-card-leaderboard-avatar-container">
                <img src={getAiCharacterImageUrlV2(character?.aiCharacter)} alt="platform" className="character-card-leaderboard-avatar" onError={(e) => {
                    e.target.src = defaultImage;
                }}/>
            </div>
            <span className="text-center char-name-text">{character?.aiCharacter?.characterName.length > 10 ? character?.aiCharacter?.characterName.substring(0, 10) + "..." : character?.aiCharacter?.characterName}</span>
            <p className="text-center char-name-text">{character?.publishedChatsCount}<i className="fa-solid fa-heart" style={{ fontSize: "14px", color: "#FF0000", marginLeft: '10px' }} /></p>
        </div>
    );
}

export const CreatorCardLeaderboard = ({ creator, handleNavigateToCreator }) => {
    console.log(creator);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" onClick={() => handleNavigateToCreator(creator?.createdBy?.username)}>
            <div className="character-card-leaderboard-avatar-container">
                <img src={getUserImage(creator?.createdBy)} alt="platform" className="character-card-leaderboard-avatar" onError={(e) => {
                    e.target.src = defaultImage;
                }} />
            </div>
            <span className="text-center char-name-text">{creator?.createdBy?.username.length > 10 ? creator?.createdBy?.username.substring(0, 10) + "..." : creator?.createdBy?.username}</span>
            <p className="text-center char-name-text">{creator?.characterCount + creator?.followerCount}<i className="fa-solid fa-heart" style={{ fontSize: "14px", color: "#FF0000", marginLeft: '10px' }} /></p>
        </div>
    );
}