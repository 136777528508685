import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import { getAiCharacterImageUrl, getUserImageUrl, getUserImage, getAiCharacterImageUrlV3 } from "../../../utils/images";
import DefaultImage from "../../../assets/images/logo/Default.png";
import ShareCharacterModal from "./ShareCharacterModalV2";

export default function Header({ creator, character, views, favorites, isFavorite, onFavoriteClick, onShareClick, onAvatarClick, showEdit, onEditClick }) {
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const handleShareClick = () => {
        setShareModalOpen(true);
    };

    const handleModalClose = () => {
        setShareModalOpen(false);
    };

    return (
        <>
            <div className="rounded position-relative" style={{ height: "400px", backgroundColor: "var(--color-200)" }}>
                {/* Image Section */}
                <img
                    src={getAiCharacterImageUrlV3(character, 'cover')}
                    alt="Character"
                    className="w-100 rounded"
                    style={{
                        height: "300px",
                        objectFit: "cover",
                    }}
                    onError={(e) => {
                        e.target.src = DefaultImage;
                    }}
                />

                {/* Content Section */}
                <div className="d-flex justify-content-between align-items-center p-4 rounded-bottom">
                    {/* Left Group */}
                    <div className="d-flex align-items-center gap-3">
                        {/* Creator Button */}
                        <button
                            className="header-btn d-flex align-items-center btn text-white px-2 py-2"
                            onClick={onAvatarClick}
                            style={{ background: "var(--color-300)" }}
                        >
                            <img
                                src={getUserImage(creator)}
                                alt="Creator"
                                className="rounded-circle me-3"
                                style={{ width: "30px", height: "30px", objectFit: "cover" }}
                                onError={(e) => {
                                    e.target.src = DefaultImage;
                                }}
                            />
                            <div id="header-card-content1" style={{ lineHeight: "1.0", gap: "4px" }}>
                                <span className="d-block text-white fw-bold small">Creator</span>
                                <span className="d-block text-white small">
                                    {creator?.username || "Unknown Creator"}
                                </span>
                            </div>
                        </button>

                        {/* Share & Earn Button */}
                        <button type="submit" onClick={handleShareClick} className="header-btn">
                            <div className="header-btn-content" style={{ backgroundColor: "var(--color-300)" }}>
                                <span className="fw-bold">Share & Earn</span>
                            </div>
                        </button>
                        {showEdit && (
                            <button onClick={onEditClick} className="header-edit-btn">
                                <i className="fa-solid fa-pencil"></i>
                            </button>
                        )}
                    </div>

                    {/* Right Group */}
                    <div className="d-flex align-items-center gap-3">
                        {/* Stars Count */}
                        <div className="text-center text-white text-warning" style={{ width: "100px" }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <span className="text-white me-2">⭐</span>
                                {favorites}
                            </div>
                            <div className="text-white fw-bold">Stars</div>
                        </div>

                        {/* Series Count */}
                        <div className="text-center text-white text-warning" style={{ width: "100px" }}>
                            <div>{character?.seriesCount || 0}</div>
                            <div className="text-white fw-bold small">Series</div>
                        </div>

                        {/* Favorite Button */}
                        <button type="submit" className="header-btn" onClick={onFavoriteClick}>
                            <div className="header-btn-content" style={{ backgroundColor: "var(--color-300)" }}>
                                <i className={isFavorite ? "fa-solid fa-star text-warning" : "fa-regular fa-star text-white"}></i>
                                <span className="fw-bold ms-2">Star</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {/* ShareCharacterModal */}
            <ShareCharacterModal showModal={isShareModalOpen} setShowModal={handleModalClose} centered>
            </ShareCharacterModal>
        </>
    );
}
