import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AIChatTab from "../../Component/App/Chat/AIChatTab";
import ClientCharTab from "../../Component/App/Chat/ClientCharTab";
import UserChatTab from "../../Component/App/Chat/UserChatTab";
import Avatar from "../../Component/Comman/Avatar";
import { setAllChats } from "../../Redux/Reducers/ChatReducer";
import { setCurrentPersona, setAllMyPersonas } from "../../Redux/Reducers/PersonaReducer";
import { authAPIInstance } from "../../apis/AuthAPI";
import { ChatApiInstance } from "../../apis/ChatAPI";
import { StoryPersonaApiInstance } from "../../apis/StoryPersonaAPI";
import { StoryChatApiInstance } from "../../apis/StoryChatAPI";
import { NewSocketService } from "../../apis/SocketApis/NewSocketService";
import { socketCheckerInterval } from "../../apis/SocketApis/SocketChecker";
import { getUserImageUrl } from "../../utils/images";
import EVENTS from "../../utils/socketEvents";
import ChatBoxSwitcher from "./ChatBoxSwitcher";
import { ChatSidebar } from "../../Component/App/Chat/ChatSidebar";
import { Modal } from "../../Component/Modal/Modal";
import { AllPersonasModal } from "../../Component/Persona/AllPersonasModal";
import { CreateNewPersona } from "../../Component/Persona/CreateNewPersona";
import { EditPersonaModal } from "../../Component/Persona/EditPersonaModal";
import { CreateStorychatModal } from "../../Component/Storychat/CreateStorychatModal";
import { ViewAllStorychats } from "../../Component/Storychat/ViewAllStorychats";
import { EditStorychatModal } from "../../Component/Storychat/EditStorychatModal";
import { createSelector } from "@reduxjs/toolkit";

const selectAllMyPersonas = createSelector(
    (state) => state.persona.allMyPersonas,
    (allMyPersonas) => allMyPersonas
);

dayjs.extend(relativeTime);

const chatTabs = [
    { tabTitle: "Chars", eventKey: "ai", href: "ai-chats" },
    { tabTitle: "Users", eventKey: "user", href: "user-chats" },
    /* { tabTitle: "Clients", eventKey: "client", href: "client-chats" } */
];

function isSmallScreen() {
    if (typeof window !== 'undefined') {
        return window.innerWidth < 768;
    }
    return false;
}

const customStyles = {
    
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginLeft: isSmallScreen() ? '' : '200px',
        marginRight: isSmallScreen() ? '' : '200px',
        zIndex: 19,
        transform: "translate(-50%, -50%)",
        width: isSmallScreen() ? '90%' : '60%',
        height: "90%",
        maxHeight: "80vh",
        backgroundColor: "#272727",
    }
};

function Chat({ user }) {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {sendMessage, lastMessage, readyState, getWebSocket} = NewSocketService("Chat.js");
    const [userInfo, setUserInfo] = useState({});
    const [showAllPersonasModal, setShowAllPersonasModal] = useState(false);
    const [showEditPersonaModal, setShowEditPersonaModal] = useState(false);
    const [showCreateNewPersonaModal, setShowCreateNewPersonaModal] = useState(false);
    const [imageGeneratorModalPop, setImageGeneratorModalPop] = useState(false);
    const [showMySeries, setShowMySeries] = useState(false);
    const [showCreateStorychat, setShowCreateStorychat] = useState(false);
    const [showEditStorychat, setShowEditStorychat] = useState(false);
    const [editStorychat, setEditStorychat] = useState(null);
    const [showViewStorychats, setShowViewStorychats] = useState(false);
    const allMyPersonas = useSelector(selectAllMyPersonas);

    let ws = getWebSocket();
        if (ws) {
            socketCheckerInterval(getWebSocket(), dispatch, "Chat.js");
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data?.type === EVENTS.SERVER.CUSTOM_AI.GET) {
                    if (data?.data?.totalMiners > 0) {
                        setStatus("online");
                    } else {
                        setStatus("offline");
                    }
                }
            }
        }
        
    const [status, setStatus] = useState("offline");
    const [loading, setLoading] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [chatsDrawerOpen, setChatsDrawerOpen] = useState(false);

    const [currentGroupChat, setCurrentGroupChat] = useState();
    const [currentStorychat, setCurrentStorychat] = useState(null);
    const [currentStorychatInfo, setCurrentStorychatInfo] = useState(null);

    // const isMerchant = searchParams.get("isMerchant") === "true" ? true : false;

    const selectedId = searchParams.get("id");
    const selectedType = searchParams.get("type");
    const isGroup = searchParams.get("isGroup");
    const selectedUserId = searchParams.get("userId");

    const setSelectedId = (chat, type) => {
        console.log('setSelectedId', chat, type)
        if (type === "ai") {
            //clean up here
            setSearchParams({ type: "ai", id: chat._id });
            setCurrentStorychat(null);
            setCurrentStorychatInfo(null);
        } else if (type === "user") {
            setSearchParams({ type: "user", id: chat.recipient_id });
            setActiveChat(chat);
        } else if (type === "client") {
            setSearchParams({ type: "client", id: chat._id, userId: chat?.clientUser?._id  });
            //setActiveChat(chat);
        } else if (type === "group") {
            setSearchParams({ type: "ai", isGroup: true, id: chat._id });
            setCurrentGroupChat(chat);
        }
    };

    useEffect(() => {
        if (selectedType === "user") {
            fetchUserChats();
        }

        getAllPersonas();
    }, [selectedType]);

    // useEffect(() => {
    //     StoryChatApiInstance.getDefaultStoryChat(currentCharacter, user.userId).then((res) => {
    //         setCurrentStorychat(res.data.data);
    //         console.log('current storychat', res.data.data.storyChat._id);
    //     });
    // }, [currentCharacter]);

    const getAllPersonas = async () => {
        const res = await StoryPersonaApiInstance.getUserPersonas();

        const uniquePersonas = res.data.data.allMyPersonas.filter(
            (persona, index, self) => 
                index === self.findIndex(p => p._id === persona._id)
        );

        dispatch(
            setAllMyPersonas({
                data: uniquePersonas,
            })
        );

        dispatch(
            setCurrentPersona({
                data: uniquePersonas[0]
            })
        );
    }

    const fetchUserChats = useCallback(async () => {
        setLoading(true);

        const res = await ChatApiInstance.getAllChatList();

        if (res?.data?.data) {
            const chatsData = res.data.data;

            const sorted = chatsData.sort((a, b) => b.timeStamp - a.timeStamp);
            // temporary way to fetch merchant data for user chat
            const newData = [];

            for (const chat of sorted) {
                if(chat?.recipient_id) {
                    newData.push(chat);
                }
            }

            console.log("selectedId: ", selectedId);
            if (selectedId && newData.findIndex((chat) => chat.recipient_id === selectedId) === -1) {
                // for new chat with store
                const response = await authAPIInstance.getUserPublicProfile(selectedId, null);
                //TODO: if response is null, fetch from user api (in cases where owner of chat is just a user)
                
                const otherUser = response?.data?.data;
                if(otherUser){
                    const newChatData = {
                        chatId: null,
                        chatName: otherUser?.username,
                        isRead: false,
                        messageContent: "",
                        recipient_avatar: otherUser?.avatar_url ?? "",
                        recipient_id: selectedId,
                        timeStamp: Date.now() / 1000,
                        userUrlAddress: otherUser?.username
                    };

                    dispatch(
                        setAllChats({
                            data: [newChatData, ...newData]
                        })
                    );

                    setActiveChat(newChatData);
                }
            } else {
                // sets the first chat as default if there is no selectedId
                dispatchDefaultChat(newData, dispatch);
            }
        }

        setLoading(false);
    }, [dispatch]);

    const dispatchDefaultChat = (newData, dispatch) => {
        dispatch(
            setAllChats({
                data: newData
            })
        );

        if (newData.length > 0) {
            setSelectedId(newData[0], "user");
            setActiveChat(newData[0]);
        }
    };


    const toggleDrawer = () => {
        setChatsDrawerOpen(prev => !prev);
    };

    return (
        <div className="card-chat-container">
            <div
                id="tabboxes"
                className={`card card-chat border-end border-0 w380 pb-1 rounded-0 d-flex ${chatsDrawerOpen ? "open" : ""}`}
            >
                <Tab.Container
                    activeKey={selectedType}
                    defaultActiveKey={"user"}
                    onSelect={(key) => setSearchParams({ type: key })}
                >
                    <Row>
                        <Col sm={12}>
                            <div className="px-3 py-3 ">
                                <div className="input-group justify-content-between align-items-center">
                                    <h1 className="h4 mb-0">Chats</h1>
                                    <a className=" py-2 d-block d-lg-none chatlist-toggle" href="#!" onClick={toggleDrawer}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                    <div>
                                        <Avatar
                                            name={user.username}
                                            avatarUrl={
                                                user.avatar_url !== "" &&
                                                (user.avatar_url.includes("http")
                                                    ? user.avatar_url
                                                    : getUserImageUrl(user.avatar_url))
                                            }
                                            className="br-50"
                                        />
                                    </div>
                                </div>
                                {/* <div className="mt-3 d-flex">
                                    <input type="text" className="form-control w-100" placeholder="Search..." />
                                </div> */}
                                <Nav className="nav nav-pills justify-content-between text-center mt-3" role="tablist">
                                    {chatTabs.map((tb) => (
                                        <Nav.Link
                                            key={tb.eventKey}
                                            className="flex-fill rounded border-0 nav-link"
                                            eventKey={tb.eventKey}
                                            href={`#${tb.href}`}
                                        >
                                            {tb.tabTitle}
                                        </Nav.Link>
                                    ))}
                                </Nav>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content className="tab-content border-top">
                                <Tab.Pane className="" eventKey="ai" id="chat-groups" role="tabpanel">
                                    <AIChatTab
                                        selectedId={selectedId}
                                        setSelectedId={setSelectedId}
                                        selectedType={selectedType}
                                        status={status}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                        currentGroupChat={currentGroupChat}
                                        isGroup={isGroup}
                                    />
                                </Tab.Pane>
                                <Tab.Pane className="" eventKey="user" id="chat-recent" role="tabpanel">
                                    <UserChatTab
                                        loading={loading}
                                        setSelectedId={setSelectedId}
                                        selectedId={selectedId}
                                        selectedType={selectedType}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                    />
                                </Tab.Pane>
                                {/* <Tab.Pane className="" eventKey="client" id="chat-groups" role="tabpanel">
                                    <ClientCharTab
                                        selectedId={selectedId}
                                        setSelectedId={setSelectedId}
                                        selectedType={selectedType}
                                        status={status}
                                        toggleDrawer={toggleDrawer}
                                        chatsDrawerOpen={chatsDrawerOpen}
                                        selectedUserId={selectedUserId}
                                    />
                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
            <div className="card card-chat-body border-0 ">
                {selectedId ? (
                    <>
                        <div id="chat-box" className={`${chatsDrawerOpen ? "overlay" : ""}`} onClick={toggleDrawer} />
                        <ChatBoxSwitcher selectedType={selectedType} isGroup={isGroup}
                          user={user} selectedId={selectedId} toggleDrawer={toggleDrawer}
                          status={status} selectedUserId={selectedUserId}
                          currentGroupChat={currentGroupChat} setCurrentGroupChat={setCurrentGroupChat}
                          activeChat={activeChat}
                          loading={loading}
                          imageGeneratorModalPop={imageGeneratorModalPop}
                          setImageGeneratorModalPop={setImageGeneratorModalPop}
                          setCurrentStorychat={setCurrentStorychat}
                          currentStorychat={currentStorychat}
                          currentStorychatInfo={currentStorychatInfo}
                          setCurrentStorychatInfo={setCurrentStorychatInfo}
                        />
                    </>
                ) : (
                    <div></div>
                )}
            </div>
            <ChatSidebar 
                setShowAllPersonasModal={setShowAllPersonasModal} 
                setShowCreateNewPersonaModal={setShowCreateNewPersonaModal} 
                setShowEditPersonaModal={setShowEditPersonaModal}
                setImageGeneratorModalPop={setImageGeneratorModalPop}
                setShowMySeries={setShowMySeries}
                setShowViewStorychats={setShowViewStorychats}
                setShowCreateStorychat={setShowCreateStorychat}
                isGroup={isGroup}
            />
            <Modal
                setIsOpen={setShowAllPersonasModal}
                modalIsOpen={showAllPersonasModal}
                styles={customStyles}
                body={<AllPersonasModal />}
            />
            <Modal
                setIsOpen={setShowCreateNewPersonaModal}
                modalIsOpen={showCreateNewPersonaModal}
                styles={customStyles}
                body={<CreateNewPersona setShowCreateNewPersonaModal={setShowCreateNewPersonaModal} />}
            />
            <Modal
                setIsOpen={setShowEditPersonaModal}
                modalIsOpen={showEditPersonaModal}
                styles={customStyles}
                body={<EditPersonaModal setShowEditPersonaModal={setShowEditPersonaModal} />}
            />
            <Modal
                setIsOpen={setShowMySeries}
                modalIsOpen={showMySeries}
                body={<div><span>Coming Soon!</span></div>}
            />
            <Modal
                setIsOpen={setShowCreateStorychat}
                modalIsOpen={showCreateStorychat}
                styles={customStyles}
                body={<CreateStorychatModal setShowCreateStorychat={setShowCreateStorychat} currentCharacter={selectedId} setCurrentStorychat={setCurrentStorychat} />}
            />
            <Modal
                setIsOpen={setShowEditStorychat}
                modalIsOpen={showEditStorychat}
                styles={customStyles}
                body={<EditStorychatModal setShowEditStorychat={setShowEditStorychat} editStorychat={editStorychat} setShowViewStorychats={setShowViewStorychats} />}
            />
            <Modal
                setIsOpen={setShowViewStorychats}
                modalIsOpen={showViewStorychats}
                styles={customStyles}
                body={
                    <ViewAllStorychats 
                        setShowViewStorychats={setShowViewStorychats} 
                        setShowCreateStorychat={setShowCreateStorychat} 
                        userId={user.userId}
                        setCurrentStorychat={setCurrentStorychat}
                        setShowEditStorychat={setShowEditStorychat}
                        setEditStorychat={setEditStorychat}
                        currentStorychat={currentStorychat}
                        selectedId={selectedId}
                    />}
            />
        </div>
    );
}
export default Chat;
