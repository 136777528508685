import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getAiCharacterImageUrl, getAiCharacterImageUrlV2 } from "../../../utils/images";
import DefaultImage from "../../../assets/images/logo/Default.png";
import PhotoModal from "./CharacterImagesModal";
import { AIApiInstance } from "../../../apis/AIAPI";
import { useLocation, useNavigate, useParams } from "react-router";

export default function CharacterInfo({ character }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [subscription, setSubscription] = useState({ price: 0, subscription_status: false });

    let photos = [''];

    if (character?.s3AvatarUrls?.length && character?.s3AvatarUrls[0] !== '') {
        photos = character.s3AvatarUrls;
    } else if (character?.avatarUrls?.length && character?.avatarUrls[0] !== '') {
        photos = character.avatarUrls;
    } else if (character?.s3CharacterAvatar && character?.s3CharacterAvatar !== '') {
        photos = [character.s3CharacterAvatar];
    } else if (character?.avatarUrl && character?.avatarUrl !== '') {
        photos = [character.avatarUrl];
    } else {
        photos = Array(12).fill(DefaultImage);
    }
    
    const maxThumbnails = 6;
    const category = character?.category?.trim() || "None";
    const hashtags = character?.hashtags || ["None"];
    const description = character?.description || "No description available.";

    const [imageStates, setImageStates] = useState(() =>
        photos.map(() => ({ isLoaded: false, isError: false }))
    );

    const handleImageLoad = (index) => {
        setImageStates((prevState) =>
            prevState.map((state, i) => (i === index ? { ...state, isLoaded: true } : state))
        );
    };

    const handleImageError = (index) => {
        setImageStates((prevState) =>
            prevState.map((state, i) => (i === index ? { ...state, isError: true } : state))
        );
    };

    // Automatic Traversal
    useEffect(() => {
        const interval = setInterval(() => {
            setSelectedPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
        }, 5000);

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [photos.length]);

    // Calculate the visible thumbnails
    const getVisibleThumbnails = () => {
        const startIndex = Math.floor(selectedPhotoIndex / maxThumbnails) * maxThumbnails;
        const endIndex = Math.min(startIndex + maxThumbnails, photos.length);
        return photos.slice(startIndex, endIndex);
    };

    const visibleThumbnails = getVisibleThumbnails();

    const handleUserSubscription = () => {
        setShowSubscribeModal(false);
        setLoading(true);

        AIApiInstance.createSubscription(character._id)
            .then(() => {
                alert("Subscription created successfully");
                navigate(`/chat?type=ai&id=${id}`);
            })
            .catch((e) => {
                alert(e?.response?.data?.message ?? "Something went wrong.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleMessageClick = () => {
        if (!character) {
            console.log("Redirecting to:", `/try_ai_chat/${character?._id}`);
            navigate(`/try_ai_chat/${character?._id}`);
            return;
        }

        if (character?.subscription?.price > 0 && !character?.subscription?.subscription_status) {
            const confirm = window.confirm("Do you want to subscribe to this character?");
            if (confirm) {
                if (character?.currency !== 'GEMS') {
                    alert('This currency is not supported now.');
                    return;
                }
                if (character?.user?.gemsCount >= character?.subscription?.price) {
                    alert("Show subscription modal here"); // Placeholder for modal logic
                } else {
                    alert("GEMS balance is not enough, please get more gems to subscribe.");
                }
            }
        } else {
            navigate(`/chat?type=ai&id=${character?._id}`);
        }
    };

    return (
        <div className="character-info mt-2 p-4 rounded">
            <div className="row">
                {/* Left Column - Image */}
                <div className="col-lg-4">
                    <div className="character-image-container">
                        <img
                            src={getAiCharacterImageUrl(photos[selectedPhotoIndex])}
                            alt="Character"
                            onLoad={() => handleImageLoad(selectedPhotoIndex)}
                            onError={() => handleImageError(selectedPhotoIndex)}
                            className="character-image"
                        />
                    </div>
                </div>

                {/* Right Column - Info */}
                <div className="col-lg-8">
                    <div className="d-flex justify-content-left">
                        <span className="category-title fw-light text-white">Info</span>
                    </div>
                    <hr className="mt-0 border-secondary" />
                    <h3 className="category-title text-white fw-bold mb-4">{character?.characterName || "Character Name"}</h3>

                    <div className="row">
                        <div className="col-lg-6">
                            <h5 className="category-title text-white">Category</h5>
                            <div className="d-flex flex-wrap gap-2">
                                <span className="badge px-3 py-2">{category}</span>
                            </div>
                            <h5 className="category-title text-white mt-3">Hashtags</h5>
                            <div className="d-flex flex-wrap gap-2">
                                {hashtags.map((tag, index) => (
                                    <span key={index} className="badge px-3 py-2">{tag}</span>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5 className="category-title text-white">Description</h5>
                            <p className="category-content text-white-50 small">
                                {isDescriptionExpanded ? description : `${description.slice(0, 600)}...`}
                                {description.length > 600 && (
                                    <button
                                        className="read-more-btn"
                                        onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                                    >
                                        {isDescriptionExpanded ? "Read less" : "Read more"}
                                    </button>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Photos and Start Chat */}
            <div className="row mt-0">
                <div className="col-lg-4">
                    <div className="d-flex justify-content-between align-items-center mb-2" style={{ width: "100%" }}>
                        <h5 className="category-title text-white mb-0 text-left">Photos</h5>
                        {photos.length > maxThumbnails && (
                            <button
                                className="btn btn-link text-white-50 small"
                                onClick={() => setShowModal(true)}
                            >
                                See More
                            </button>
                        )}
                    </div>
                    <div className="d-flex gap-1 flex-nowrap">
                        {visibleThumbnails.map((photo, index) => {
                            const actualIndex =
                                Math.floor(selectedPhotoIndex / maxThumbnails) * maxThumbnails + index;
                            return (
                                <img
                                    key={actualIndex}
                                    src={getAiCharacterImageUrl(photo)}
                                    alt={`Photo ${actualIndex + 1}`}
                                    className={`thumbnail-image rounded ${selectedPhotoIndex === actualIndex ? "selected" : ""}`}
                                    onClick={() => setSelectedPhotoIndex(actualIndex)}
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="col-lg-8">
                    <button
                        className="mt-5 header-btn text-center w-100 px-1 py-3 fw-bold"
                        onClick={handleMessageClick}
                        disabled={loading}
                    >
                        {subscription?.price > 0 && !subscription?.subscription_status
                            ? `${subscription?.price} ${character?.currency ?? "$"}`
                            : "Start Chat"}
                        {loading && <i className="spinner-border spinner-border-sm mx-2"></i>}
                    </button>
                </div>
            </div>

            {/* Modal for Photos */}
            <PhotoModal
            showModal={showModal}
            setShowModal={setShowModal}
            photos={photos}
            />
        </div>
    );
}
