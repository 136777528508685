import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import menu2 from "../src/Component/Data/Menu/Menu2.json";
import AuthRedirect from "./Component/AuthRedirect/AuthRedirect";
import Sidebar from "./Component/Comman/Sidebar/Sidebar";
import { menu } from "./Component/Data/Menu/Menu";
import ProtectedPage from "./Component/ProtectedPage/ProtectedPage";
import { AuthActions } from "./Redux/Actions";
import { checkDailyCheckinReward } from "./Redux/Actions/MerchantActions";
import AuthIndex from "./Screen/AuthIndex";
import MainIndex from "./Screen/MainIndex";
import { AnalyticsAPIInstance } from "./apis/AnalyticsAPI";
import { config } from "./utils/config";
import { readCookie, removeCookie } from "./utils/cookie";
import { getFingerPrint } from "./utils/fingerPrint";
import { getUserIpInfo } from "./utils/getUserCountry";
import { sendPageEvent } from "./utils/googleAnalytics";
import { getTokenValidity } from "./utils/jwt";

ReactGA.initialize(config[config.environment].googleAnalyticsId);

const unProtectedRoutes = [
'/sign-in', "/sign-up", "/forgot-password", "/reset_password", "/verification", "/404page", "/market-list"
];

function App() {
    const [menuData, setMenuData] = useState([...menu.menu]);
    //const baseUrl = process.env.PUBLIC_URL; //configurl
    const navigate = useNavigate();
    const path = useLocation();
    const dispatch = useDispatch();
    const profileChecked = useRef(null);
    const pageViewProcessing = useRef(null);

    const checkProfile = useCallback(async () => {
        const token = readCookie("token");
        const token_validity = getTokenValidity(token);
        if (token_validity) {
            dispatch(AuthActions.getUserProfile());
            setTimeout(() => {
                dispatch(AuthActions.setAuthLoading(false));
                checkDailyCheckinReward(token);
            }, 2000);
        } else {
            removeCookie("token");
            dispatch(AuthActions.setAuthLoading(false));
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        
        setTimeout( function() {
            sendPageEvent(path.pathname);
            //sending too many requests to server
            //createPageView(path.pathname);
        }, 2000);

    }, [path]);


    const createPageView = async (_path) => {
        console.log("test", _path)
        if (!pageViewProcessing.current) {
            pageViewProcessing.current = true;
            const userInfo = await getUserIpInfo();
            const fp = await getFingerPrint();
            AnalyticsAPIInstance.createPageView({ url: _path, ip: userInfo.ip, country: userInfo.country, fp }).then((_) => {
                
                //pageViewProcessing.current = false;
                setTimeout( function() { 
                    pageViewProcessing.current = false;
                    console.log("view saved after 2 seconds");
                }, 2000);
            });
        }
    };

    useEffect(() => {
        //set the loading spinner while waiting to check auth. 
        if (!profileChecked.current) {
            dispatch(AuthActions.setAuthLoading(true));
            profileChecked.current = true;
            checkProfile();
        } 
    }, [checkProfile]);

    const location = useLocation();

    const isSignInPage = location.pathname ===  "/sign-in";
    const isSignUpPage = location.pathname ===  "/sign-up";

    if (
        unProtectedRoutes.indexOf(path?.pathname) !== -1 || path.pathname.includes("try_ai_chat")
    ) {
        return (
            <AuthRedirect>
                <div id={isSignInPage || isSignUpPage ? "" : "cryptoon-layout"} className="theme-orange">
                    <AuthIndex />
                </div>
            </AuthRedirect>
        );
    }

    const GotoChangeMenu = (val) => {
        if (val === "UI Components") {
            navigate(  "/ui-alerts");
            setMenuData([...menu2.menu2]);
        } else if (val === "documentation") {
            navigate(  "/ui-alerts");
            setMenuData([...menu2.menu2]);
        } else {
            navigate(  "/");
            setMenuData([...menu.menu]);
        }
    };

    return (
        <ProtectedPage>
            <div id="cryptoon-layout" className="theme-orange">
                <Sidebar
                    activekey={path?.pathname}
                    menuData={menuData}
                    GotoChangeMenu={(val) => {
                        GotoChangeMenu(val);
                    }}
                    dispatch={dispatch}
                />
                <MainIndex
                    queries={path?.search}
                    activekey={path?.pathname}
                    GotoChangeMenu={(val) => {
                        GotoChangeMenu(val);
                    }}
                />
            </div>
        </ProtectedPage>
    );
}

export default React.memo(App);
