import React from "react";
import { Button } from "react-bootstrap";

export default function TotalEarnings({ totalEarnings = 0, withdrawEarningsAvailable = 0, handleWithdraw = () => {} }) {
    return (
        <div className="totalEarnings-container">
            <div className="total-earnings-upper-subsection d-flex flex-column">
                <div className="totalEarnings-container-row">
                    <span className="header">Total Earnings</span>
                    <strong><span className="bal-figure">${totalEarnings}</span></strong>
                </div>
                <div className="totalEarnings-container-row">
                    <span className="header">Withdraw available</span>
                    <span className="bal-figure">
                        <strong>${withdrawEarningsAvailable}</strong>
                    </span>
                </div>
            </div>
            <Button onClick={handleWithdraw} disabled={withdrawEarningsAvailable <= 0} className="withdraw-btn">
                Withdraw
            </Button>
        </div>
    );
}
