import { Formik } from "formik";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CreatorProfileSchema } from "../schema/CreatorProfileSchema";
import { getUserImageUrl } from "../../../utils/images";
import { ErrorMsg } from "../../../Component/Comman/Typography";
import { IoTrash } from "react-icons/io5";
import { BiSolidImageAdd } from "react-icons/bi";
import { theme } from "../../../utils/config";
import "../index.css";
import { Modal } from "../../../Component/Modal/Modal";
import CreateService from "./components/CreateServiceForm";
import { selectCurrentUser } from "../../../Redux/selectors/auth";
import imageCompression from 'browser-image-compression';
import "./components/socials.css";
import { authAPIInstance } from "../../../apis/AuthAPI";
import { CustomToast, ToastStatus } from "../../../Component/Comman/CustomToast";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "25%",
        backgroundColor: "rgba(0,0,0,0.6)",
        borderColor: "transparent",
        borderRadius: "12px",
        height: "80vh",
        overflowY: "auto",
        zIndex: "1000"
    },
    overlay: {
        backgroundColor: "rgba(4, 4, 35, .5)",
        backdropFilter: "blur(3px)"
    }
};

const CreatorProfileForm = ({ userData, handleEditToggle, buttonContent, onSubmit, handleDeleteCoupon, isSubmitting = false, isDeleting = false }) => {
    const formikCtx = useRef();
    const userImageInput = useRef(null);
    const user = useSelector(selectCurrentUser)
    const { t } = useTranslation("add_coupon");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showSocialMediaModal, setShowSocialMediaModal] = useState(false);
    const [userImage, setUserImage] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [newLinkLabel, setNewLinkLabel] = useState('');
    const [newLinkValue, setNewLinkValue] = useState('');
    const [toast, setToast] = useState({
        show: false,
        message: "",
        status: ""
    });
    // const [isSubmitting, setSubmitting] = useState({});
    // const [newLink, setNewLink] = useState({ link: '', label: '' });

    useEffect(() => {
        if (user?.avatar_url) {
            setUserImage(user?.avatar_url);
        }
    }, [user?.avatar_url]);

    const memoizedUserInfo = useMemo(() => ({
        creatorName: userData?.username ?? '',
        creatorDescription: userData?.description ?? '',
        socialMediaLinks: userData.socialLinks || [],
    }), [userData, formikCtx]);

    const handleSubmitCoupon = async () => {
        const isImageRemoved = user?.avatar_url && !userImage;
        let imageFile = null;
    
        if (userImage?.file) {
            // If the user uploaded a file, use it directly
            imageFile = userImage.file;
        } else if (user?.avatar_url && !isImageRemoved) {
            // Convert the URL to a Blob
            const response = await fetch(getUserImageUrl(user?.avatar_url));
            imageFile = await response.blob();
        }
    
        const currentSocials = formikCtx.current.values.socialMediaLinks || [];
        const newSocialLinks = newLinkValue && newLinkLabel
            ? { label: newLinkLabel, link: newLinkValue }
            : null;
    
        // Add the new link if it exists and is valid
        const updatedSocialLinks = newSocialLinks
            ? [...currentSocials, newSocialLinks]
            : currentSocials;
    
        // Filter out empty links (where either label or link is falsy)
        const sanitizedSocialLinks = updatedSocialLinks.filter(
            (link) => link.label?.trim() && link.link?.trim()
        );
    
        // Remove duplicates by label and link
        const uniqueSocialLinks = sanitizedSocialLinks.filter(
            (link, index, self) =>
                index ===
                self.findIndex(
                    (l) => l.label === link.label && l.link === link.link
                )
        );
    
        formikCtx.current.setFieldValue("socialLinks", uniqueSocialLinks);
    
        await onSubmit({
            body: {
                username: formikCtx.current.values.creatorName,
                description: formikCtx.current.values.creatorDescription,
                socialLinks: JSON.stringify(uniqueSocialLinks),
                imageRemoved: isImageRemoved,
            },
            user_photo: imageFile,
        });
    };
    

    const handleImagePicker = () => {
        userImageInput?.current?.click();
    };

    const removeImage = () => {
        setUserImage(null);
    };


    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                setUploadLoading(true);
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 800,
                    useWebWorker: true,
                    preserveExif: true,
                };

                const compressedFile = await imageCompression(file, options);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setUserImage({ file: compressedFile, url: reader.result });
                    setUploadLoading(false);
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error('Image compression error:', error);
            }
        }
    };

    const getImageUrl = () => {
        if(typeof userImage === "object"){
            return userImage.url
        }
        return getUserImageUrl(userImage);
    };

    const addLink = () => {
        // setSubmitting(true)
        const isAdded = formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: [
                ...formikCtx.current.values.socialMediaLinks,
                { link: newLinkValue, label: newLinkLabel }
            ]
        });
        console.log("is default")
        if (isAdded) {
            console.log("isadded")
            setToast({
                show: true,
                message: "Social link added",
                status: ToastStatus.SUCCESS
            });
        } else {
            console.log("is error")
            setToast({
                show: true,
                message: "Error found when adding social link!",
                status: ToastStatus.ERROR
            });
        }
        // setSubmitting(false)
        
        setShowSocialMediaModal(false);
    };
    

    const handleLinkChange = (value, i, key) => {
        const newLinks = [...formikCtx.current.values.socialMediaLinks];
        newLinks[i][key] = value;
        formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: newLinks
        });
    }

    const removeLink = (i) => {
        const newLinks = [...formikCtx.current.values.socialMediaLinks];
        newLinks.splice(i, 1);
        formikCtx.current.setValues({
            ...formikCtx.current.values,
            socialMediaLinks: newLinks
        });
    }

    const handleRemoveImage = () => {
        setUserImage(null);
    }

    const handleAddSocialsClick = () => {
        setShowSocialMediaModal(true);
    };

    const handleCancel = () => {
        setShowSocialMediaModal(false);
        setIsOpen(false);
    }

    return (
        <div className="d-flex w-60 flex-column creator-profile-form align-items-stretch w-100">
            <Formik
                innerRef={formikCtx}
                initialValues={memoizedUserInfo}
                validationSchema={CreatorProfileSchema}
                onSubmit={handleSubmitCoupon}
                enableReinitialize={true}
            >
                {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => (
                    <div className="row gy-3">
                        <div className="">
                            
                            <div className="card card-body gap-3 py-1">
                                <Form.Label className="profile-picture-label">{t("Profile picture")}</Form.Label>
                                <div className="d-flex justify-content-center my-3">
                                
                                    {userImage ? (
                                        <div className="d-flex w-100 img-upload-container with-image">
                                            
                                            <Button
                                                variant="dark"
                                                // className="position-absolute top-0 start-0 ms-4 my-2 rounded px-2 py-1"
                                                className="position-absolute ms-4 my-2 rounded px-2 py-1 profile-remove-img-button"
                                                onClick={removeImage}
                                            >
                                                <IoTrash onClick={handleRemoveImage} size={16} color="white" />
                                            </Button>
                                            <div
                                            
                                            className={`d-flex flex-column gap-4 align-items-center w-100 profile-photo-input with-image`}
                                            style={{
                                                backgroundImage: `url(${getImageUrl()})`, // Dynamically set the background
                                                backgroundPosition: "center", // Center the image
                                                backgroundRepeat: "no-repeat", // Prevent repetition
                                            }}
                                            >
                                            </div>
                                            <div
                                                onClick={handleImagePicker}
                                                className="d-flex img-upload-icon rounded br-50 pointer">
                                                    
                                                <h1>+</h1>
                                                
                                            </div>
                                            <b>
                                                Add Profile Photo
                                                {uploadLoading && <Spinner size="sm" className="me-2 ms-2" animation="border" variant="white" />}
                                            </b>

                                        </div>
                                        
                                    ) : (
                                        <div onClick={handleImagePicker} className="d-flex flex-column gap-4 align-items-center w-100 profile-photo-input no-image">
                                            <div className="creator_image_upload_container rounded br-50">
                                                <span color={theme.color}>
                                                    <BiSolidImageAdd size={28} />
                                                </span>
                                            </div>
                                            <b>
                                                Add Profile Photo
                                                {uploadLoading && <Spinner size="sm" className="me-2 ms-2" animation="border" variant="white" />}
                                            </b>
                                        </div>
                                    )}
                                    <input
                                        id="storeImageInput"
                                        type="file"
                                        ref={userImageInput}
                                        onChange={handleImageUpload}
                                        accept="image/*"
                                    />
                                </div>
                                <Form.Label className="cover-photo-label">{t("Cover picture")}</Form.Label>
                                <div className="d-flex flex-column gap-4 align-items-center w-100 cover-photo-input mb-4">
                                    <div className=" cover-photo-input-container d-flex flex-column">
                                        <div className="">
                                            <h1>+</h1>
                                        </div>
                                        
                                        <b>
                                            Background Cover Photo
                                            {uploadLoading && <Spinner size="sm" className="me-2 ms-2" animation="border" variant="white" />}
                                        </b>
                                    </div>
                                </div>


                                <Form.Group>
                                    <Form.Label className="required">{t("Username")}</Form.Label>
                                    <Form.Control
                                        value={values.creatorName}
                                        onChange={handleChange("creatorName")}
                                        type="text"
                                        placeholder={t("Enter Name")}
                                    />
                                    {touched.creatorName && errors.creatorName && <ErrorMsg msg={errors.creatorName} />}
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="required">{t("About me")}</Form.Label>
                                    <textarea
                                        value={values.creatorDescription}
                                        onChange={handleChange("creatorDescription")}
                                        placeholder={t("Enter Your Description")}
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                    ></textarea>
                                    {touched.creatorDescription && errors.creatorDescription && (
                                        <ErrorMsg msg={errors.creatorDescription} />
                                    )}
                                </Form.Group>

                                <div className="add-social-button-container d-flex">
                                    <Button className="p-3 align-items-center justify-content add-social-button" onClick={handleAddSocialsClick}>
                                        <i class="fa fa-plus pe-3" style={{fontSize: "1.5em"}} aria-hidden="true"></i>
                                        <span>Add New Social Media Links</span>
                                    </Button>
                                </div>
                                
                                

                                {buttonContent == null ? (
                                    <div className="d-flex mb-2 gap-2 flex-row action-button-container">
                                        {handleDeleteCoupon && (
                                            <Button
                                                w="full"
                                                onClick={handleDeleteCoupon}
                                                style={{ width: "100px" }}
                                                variant="danger"
                                                className="text-white"
                                                disabled={isDeleting || isSubmitting}
                                            >
                                                {isDeleting && (
                                                    <Spinner size="sm" className="me-2" animation="border" variant="white" />
                                                )}
                                                {t("Delete")}
                                            </Button>
                                        )}
                                        <Button onClick={handleEditToggle} className="py-3 back" style={{width: "50%"}}>Back</Button>
                                        <div className="save-button-container d-flex w-50">
                                            <Button
                                                className="action-btn py-3 save"
                                                w="full"
                                                onClick={handleSubmitCoupon}
                                                style={{ width: "auto" }}
                                                disabled={isDeleting || isSubmitting || uploadLoading}
                                            >
                                                {isSubmitting && (
                                                    <Spinner size="sm" className="me-2" animation="border" variant="white" />
                                                )}
                                                {t("Save")}
                                            </Button>
                                        </div>
                                        
                                    </div>
                                ) : (
                                    buttonContent(handleSubmit)
                                )}
                            </div>
                            
                            
                            <Modal
                                setIsOpen={setShowSocialMediaModal}
                                modalIsOpen={showSocialMediaModal}
                                showClose={false}
                                styles={customStyles}
                                body={
                                    <div className="poppins-font d-flex flex-column gap-2 socials-modal-body schedueledMessages-container p-2 pb-0">
                                        <h3>Choose Social</h3>
                                        <div className="d-flex flex-column filled-socials-container">
                                            <h6 className="mt-3">Added Social Links</h6>
                                            <div className="d-flex flex-column gap-2 social-links-item-container">
                                                <div className="d-flex flex-column mt-3 gap-2 create-schedule-form schedule-messages-container">
                                                    <div className="d-flex flex-row socials-body socials-header">
                                                        <div className="d-flex flex-row gap-2 socials-content">
                                                            <h6>Label</h6>
                                                            <h6>Link</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                {values.socialMediaLinks && values.socialMediaLinks.length > 0 ? values.socialMediaLinks.map((linkObj, i) => (
                                                    <div className="d-flex flex-column gap-2 create-schedule-form schedule-messages-container" key={i}>
                                                        <div className="d-flex flex-row socials-body">
                                                            <div className="d-flex flex-row gap-1">
                                                                
                                                                <Form.Control
                                                                    onChange={(e) => handleLinkChange(e.target.value, i, 'label')}
                                                                    value={linkObj.label}
                                                                    type="text"
                                                                    placeholder={t("Enter Label...")}
                                                                />
                                                                
                                                                <Form.Control
                                                                    onChange={(e) => handleLinkChange(e.target.value, i, 'link')}
                                                                    value={linkObj.link}
                                                                    type="text"
                                                                    placeholder={t("Enter Your Link...")}
                                                                />
                                                            </div>
                                                            <Button onClick={() => removeLink(i)} className="mt-3 trash-icon-container">
                                                                <i className="fa fa-trash trash-icon-button" aria-hidden="true"></i>
                                                            </Button>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                ))
                                                :
                                                <h7>No added social links to show</h7>
                                                }

                                                {touched.socialMediaLinks && errors.socialMediaLinks && (
                                                    <ErrorMsg msg={errors.socialMediaLinks} />
                                                )}
                                            </div>
                                        </div>

                                        {/* Form for adding a new social link */}
                                        <div className="d-flex flex-column socials-modal-bottom-section w-100 mt-3">
                                            <div className="d-flex flex-column mt-4 add-social-links-item-container">
                                                <h6 className="mt-3">Add a Social Link</h6>
                                                <div className="d-flex flex-column create-schedule-form schedule-messages-container">
                                                    <Form.Label className="required m-0 mt-3">Label</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => setNewLinkLabel(e.target.value)}
                                                        value={newLinkLabel}
                                                        type="text"
                                                        placeholder={t("Enter Label...")}
                                                    />
                                                    <Form.Label className="required mt-3 social-link-link-label">{t("Your Social Links")}</Form.Label>
                                                    <Form.Control
                                                        onChange={(e) => setNewLinkValue(e.target.value)}
                                                        value={newLinkValue}
                                                        type="text"
                                                        placeholder={t("Enter Your Link...")}
                                                    />
                                                

                                                    {touched.socialMediaLinks && errors.socialMediaLinks && (
                                                        <ErrorMsg msg={errors.socialMediaLinks} />
                                                    )}
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row justify-content-center gap-3 py-3 social-modal-button-container">
                                                <Button onClick={handleCancel} className="cancel-button py-3" style={{width: "50%"}}>Cancel</Button>
                                                <Button onClick={() => {addLink()}} className="py-3 save-button" style={{width: "50%"}}>Save</Button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                }>
                                
                            </Modal>
                        </div>
                    </div>
                )}
            </Formik>
            <CustomToast
                status={toast.status}
                show={toast.show}
                message={toast.message}
                onClose={() => setToast((prev) => ({ ...prev, show: false }))}
            />
        </div>
    );
};

export default CreatorProfileForm;