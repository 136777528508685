import React, { useState } from "react";
import { Button } from "react-bootstrap";

const ShareCharacterModal = ({ showModal, setShowModal }) => {
    const currentURL = window.location.href;
    const [copiedUrl, setCopiedUrl] = useState(false);
    const [copiedEmbed, setCopiedEmbed] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentURL);
        setCopiedUrl(true);
        setTimeout(() => setCopiedUrl(false), 3000); // Reset copy status after 3 seconds
    };

    const copyEmbedToClipboard = () => {
        navigator.clipboard.writeText(generateEmbedCode());
        setCopiedEmbed(true);
        setTimeout(() => setCopiedEmbed(false), 3000); // Reset copy status after 3 seconds
    };

    const generateEmbedCode = () => {
        return `<iframe src="${currentURL}" width="600" height="400" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains("modal-overlay")) {
            setShowModal(false);
        }
    };

    return (
        showModal && (
            <div className="modal-overlay" onClick={handleOverlayClick}>
                <div className="modal-content">
                    {/* Close button */}
                    <button className="close-button" onClick={() => setShowModal(false)}>
                        &times;
                    </button>

                    {/* Header */}
                    <h6>Share Character</h6>
                    <hr className="separator mt-1" />

                    {/* Body */}
                    <div className="share-container">
                        {/* URL Copy Section */}
                        <div className="url-section d-flex align-items-center mt-1">
                            <input
                                type="text"
                                value={currentURL}
                                readOnly
                                className="form-control me-2"
                            />
                            <Button
                                onClick={copyToClipboard}
                                className="copy-button"
                            >
                                Copy
                            </Button>
                        </div>

                        {/* Copy Status Message */}
                        {(copiedUrl || copiedEmbed) && (
                            <div className="copy-status mt-3 text-success">
                                {copiedUrl && "Link copied to clipboard!"}
                                {copiedEmbed && "Embed code copied to clipboard!"}
                            </div>
                        )}
                    </div>

                    {/* Footer */}
                    <div className="button-container d-flex justify-content-between mt-4">
                        <Button
                            className="done-button "
                            onClick={() => setShowModal(false)}
                        >
                            Done
                        </Button>
                        <Button
                            className="submit-button"
                            onClick={() => alert('Share clicked!')} // Placeholder for the Share button action
                        >
                            Share
                        </Button>
                    </div>
                </div>
            </div>
        )
    );
};

export default ShareCharacterModal;
