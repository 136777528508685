import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { topDataAPIInstance } from "../../../apis/TopDataAPI";
import DefaultImage from "../../../assets/images/logo/Default.png";
import { getAiCharacterImageUrl } from "../../../utils/images";

const RelatedCharacters = ({ category }) => {
    const { id } = useParams();
    const [relatedCharacters, setRelatedCharacters] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        fetchRelatedCharacters();
    }, [category]);

    const fetchRelatedCharacters = useCallback(async () => {
        try {
            const response = await topDataAPIInstance.getCharactersByCategory(category);
            console.log("API Response for Related Characters:", response?.data?.data?.data); // Debugging log
    
            const characters = response?.data?.data?.data || [];
            const filtered = characters.filter(char => char.aiCharacter._id !== id);
    
            // Debugging: Check if characters have valid `_id`
            filtered.forEach((char, index) => {
                if (!char.aiCharacter._id) {
                    console.error(`Character at index ${index} is missing _id:`, char);
                }
            });
    
            setRelatedCharacters(filtered);
        } catch (err) {
            console.error("Error fetching related characters:", err);
        }
    }, []);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // Determine if there are more than 6 actual characters
    const hasMore = relatedCharacters.length > 6;

    // Ensure at least 6 slots, adding placeholders if necessary
    const displayedCharacters = relatedCharacters.length < 6
        ? [...relatedCharacters, ...new Array(6 - relatedCharacters.length).fill({
            _id: "placeholder",
            avatarUrl: "",
            name: "Unknown Character",
            rating: "N/A",
            creator: "Unknown Creator",
        })]
        : relatedCharacters;

    return (
        <div className="related-characters-section mt-2">
            <Header isExpanded={isExpanded} onToggleExpand={handleToggleExpand} hasMore={hasMore} />
            <div className="card-display">
                {displayedCharacters
                    .slice(0, isExpanded ? displayedCharacters.length : 6)
                    .map((character, index) => (
                        <CharacterCard 
                            key={`related-character-${index}`} 
                            character={character} 
                            isPlaceholder={character._id === "placeholder"} 
                        />
                    ))}
            </div>
        </div>
    );
};

// Header component for title and expand/collapse button
const Header = ({ isExpanded, onToggleExpand, hasMore }) => (
    <div className="d-flex justify-content-between align-items-center mb-2 bg-black">
        <h3 className="category-title text-white">Related Characters</h3>
        {hasMore && (
            <button className="btn btn-link text-white-50 small me-2" onClick={onToggleExpand}>
                {isExpanded ? "See Less" : "See More"}
            </button>
        )}
    </div>
);

// CharacterCard component for displaying character portraits or placeholders
const CharacterCard = ({ character, isPlaceholder }) => {
    const navigate = useNavigate();

    const characterName = character?.aiCharacter?.characterName || "Unknown Character";
    let imageUrl = character?.aiCharacter?.s3AvatarUrls || getAiCharacterImageUrl(character?.aiCharacter?.avatarUrls?.[character?.aiCharacter?.avatarUrls.length - 1]);

    if (!imageUrl) {
        imageUrl = DefaultImage;
    }

    const rating = character?.averageRating ?? "N/A";
    const creator = character?.aiCharacter?.createdBy?.username || "Unknown";

    const handleCardClick = () => {
        const characterId = character?.aiCharacter?._id; // Try both
    
        if (!isPlaceholder && characterId) {
            const url = `/character/${characterId}`;
            console.log("Redirecting to:", url);
            window.open(url, "_blank");
        } else {
            console.error("Character ID is undefined:", character);
        }
    };
    

    return (
        <div className="character-card" onClick={handleCardClick} style={{ cursor: isPlaceholder ? "default" : "pointer" }}>
            {isPlaceholder ? (
                <div className="character-placeholder">
                    <div className="placeholder-image"></div>
                    <div className="character-info">
                        <div className="character-name">Unknown Character</div>
                        <div className="character-rating">Rating: N/A</div>
                        <div className="character-creator">Creator: Unknown</div>
                    </div>
                </div>
            ) : (
                <div className="character-link">
                    <div className="related-character-image-container">
                        <img
                            src={imageUrl}
                            alt={characterName}
                            className="character-image"
                            onError={(e) => e.target.src = DefaultImage}
                        />
                    </div>
                    <div className="character-info">
                        <div className="name-rating-container">
                            <div className="character-name fw-bold">{characterName.length > 15 ? characterName.substring(0, 15) + "..." : characterName}</div>
                            <div className="star-rating-section">
                                <span className="me-2">⭐</span>
                                <div className="character-rating">{rating}</div>
                            </div>
                        </div>
                        <div className="character-creator">{creator}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RelatedCharacters;
